import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const groupsRepository = RepositoryFactory.get("groups");
import { currentCommunityName } from "@/Core/config/communityDetails.js";
// import { ClientRepositoryFactory } from "@/Client/Kotak/kotakapicall/ClientRepositoryFactory";
// const EmailSMSRepository = ClientRepositoryFactory.get("emailsms");
const promotionsRepository = RepositoryFactory.get("promotions");
import jwt from "jsonwebtoken";
import { marketUpdateEmailHTML } from "@/Client/Kotak/emailer/marketUpdateEmailer.js";
import EmailSMSMixin from "@/Client/Kotak/mixins/EmailSMSMixin";

export default {
  mixins: [EmailSMSMixin],
  data() {
    return {
      token: {},
    };
  },
  created() {
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
  },
  methods: {
    viewGroup() {
      if (this.$route.path == "/community-channels") {
        this.$store.dispatch("storeComponentKey");
        this.$router
          .replace({
            name: "page-GroupsPage",
            params: { groupId: this.$store.state.groupId },
            query: { reload: true },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: "page-GroupsPage",
            params: { groupId: this.$store.state.groupId },
          })
          .catch(() => {});
      }
    },
    async successPost(
      post_UniqueId,
      text,
      groupIDs,
      groupUUIDs,
      groupNames,
      postLink,
      postBlog = "",
      post
    ) {
      const postID = post_UniqueId;
      const caption = text;
      const groupIDList = groupIDs;
      const groupUUIDList = groupUUIDs[0];
      const groupNameList = groupNames;
      const link = postLink;
      const blogText = postBlog;
      // const condition = false;
      // if (condition) {
      this.$swal({
        title: "How would you like to promote",
        html: `
                <br>
                  <div class="form-check">
                      <input class="form-check-input"
                      onchange="
                      var ischecked1=document.getElementById('checkbox1').checked;
                      var ischecked3=document.getElementById('checkbox3').checked;
                      var signature=document.getElementById('signature');
                      if(ischecked1){
                        signature.style.display='block';
                      }
                      else if(!ischecked1 && !ischecked3){
                        signature.style.display='none';
                      }"
                      
                      type="checkbox" value="" id="checkbox1">
                      <label class="form-check-label mr-4" for="checkbox1" >
                          SMS
                      </label>
                      <input class="form-check-input" type="checkbox" value="" id="checkbox2">
                      <label class="form-check-label mr-4" for="checkbox2">
                          Email
                      </label>
                      <input class="form-check-input" 
                      
                      onchange="
                      var ischecked1=document.getElementById('checkbox1').checked;
                      var ischecked3=document.getElementById('checkbox3').checked;
                      var signature=document.getElementById('signature');
                      if(ischecked3){
                        signature.style.display='block';
                      }
                      else if(!ischecked1 && !ischecked3){
                        signature.style.display='none';
                      }"
                      
                      type="checkbox" value="" id="checkbox3">
                      <label class="form-check-label" for="checkbox3">
                          Whatsapp
                      </label>
                  </div>
                   <br/>
                   <div id="signature" style="display:none">
                   
                    <div class="form-check">
                        <input class="form-check-input" checked name="regards" type="radio" value="radio1" id="radio1">
                        <label class="form-check-label" for="radio1">
                            Default Signature
                        </label>
                        <p>
                          Regards,<br/>
                          Upasna Bhardwaj <br/>
                          Kotak Treasury
                        </p>
                    </div>
                    <br/>
                    <div class="form-check">
                        <input class="form-check-input" name="regards" type="radio" value="radio2" id="radio2">
                        <label class="form-check-label" for="radio2">
                          My Signature
                        </label>
                        <p>
                            Regards,<br/>
                            ${this.token.username}<br/>
                            Kotak Treasury
                        </p>
                    </div> 
                    <br/>
                    <div class="form-check">
                        <input class="form-check-input" name="regards" type="radio" value="radio3" id="radio2">
                        <label class="form-check-label" for="radio3">
                          Kotak Signature
                        </label>
                        <p>
                            Regards,<br/>
                            Kotak Markets Commentary<br/>
                            Kotak Treasury
                        </p>
                    </div>
                  </div>
                `,
        focusConfirm: false,
        confirmButtonText: "Promote",
        cancelButtonText: "Close",
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return {
            sms: document.getElementById("checkbox1").checked,
            email: document.getElementById("checkbox2").checked,
            whatsapp: document.getElementById("checkbox3").checked,
            isDefaultSign: document.querySelector(
              'input[name="regards"]:checked'
            ).value,
          };
        },
      }).then((result) => {
        if (result.value.sms || result.value.email || result.value.whatsapp) {
          // if (document.getElementById("checkbox1").checked) {
          //   console.log("sms");
          //   this.sendSMS(`${text} ${this.newPostLink}`);
          // }
          // if (document.getElementById("checkbox2").checked) {
          //   console.log("email");
          //   this.sendEmail(text, this.newPostLink);
          // }
          // if (document.getElementById("checkbox3").checked) {
          //   console.log("whatsapp");
          // }
          const isSMS = result.value.sms;
          const isEmail = result.value.email;
          const isWhatsapp = result.value.whatsapp;
          const isDefaultSign = result.value.isDefaultSign;
          if (isSMS || isEmail || isWhatsapp) {
            this.getUsersInGroupForComm(
              groupIDList,
              isSMS,
              isEmail,
              isWhatsapp,
              isDefaultSign,
              caption,
              link,
              groupNameList,
              postID,
              groupUUIDList,
              blogText,
              post
            );
          }
        } else {
          this.viewGroup();
        }
      });
      // } else {
      //   this.$swal({
      //     icon: "success",
      //     title: "Yay! Your post is now Live.",
      //     focusConfirm: false,
      //     confirmButtonText: "See Post",
      //     showConfirmButton: true,
      //     showCancelButton: false,
      //     allowOutsideClick: true
      //   }).then(result => {
      //     if (result.value) {
      //       this.viewGroup();
      //     }
      //   });
      // }
    },
    async addSentPromotionLog(promotion) {
      try {
        let payload = {
          communityName: currentCommunityName.communityName,
          postId: promotion.postId,
          groupId: promotion.groupId,
          userId: promotion.uuids,
          isSMS: promotion.isSMS,
          isEmail: promotion.isEmail,
          isWhatsapp: promotion.isWhatsapp,
          name: this.token.username,
        };
        let { data } = await promotionsRepository.addSentPromotion(payload);
        if (data.ResponseCode == 100) {
          this.viewGroup();
        }
      } catch (e) {
        // console.log(e);
      }
    },
    async getUsersInGroupForComm(
      groupIds,
      isSMS,
      isEmail,
      isWhatsapp,
      isDefaultSign,
      text,
      postLink,
      groupNames,
      postID,
      groupUUID,
      postBlog = "",
      post
    ) {
      const payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupIds: groupIds,
        userId: this.token.userID,
      };

      const { data } = await groupsRepository.getUsersFromGroupIDs(payload);

      this.userListAlert(
        data,
        isSMS,
        isEmail,
        isWhatsapp,
        isDefaultSign,
        text,
        postLink,
        groupNames,
        postID,
        groupUUID,
        postBlog,
        post
      );
    },
    userListAlert(
      data,
      isSMS,
      isEmail,
      isWhatsapp,
      isDefaultSign,
      text,
      postLink,
      groupNames,
      postID,
      groupUUID,
      postBlog = "",
      post
    ) {
      const mobilenumbers = data.map((x) => x.usersDetails_Mobile);
      const emailIDs = data.map((x) => x.usersDetails_Email);
      const groups = groupNames.length > 0 ? groupNames.join(", ") : "";
      const uuidList = data.map((x) => x.usersDetails_UniqueId);
      const whatsappData = data.map((x) => ({
        name: x.usersDetails_UserName,
        mobile: x.usersDetails_Mobile,
      }));
      const infoTitle = () => {
        return `${isSMS ? " SMS " : ""} ${isEmail ? " Email " : ""} ${
          isWhatsapp ? " Whatsapp " : ""
        }`;
      };

      let infoText = `${
        groupNames.length > 1
          ? `<p class="text-center">
            ${groupNames.length > 1 ? "Channels" : "Channel"}: ${groups}</p>`
          : ""
      }
            <p>Total Users: ${mobilenumbers.length}`;
      // let tableData = "";
      // let emailHeader = `<th class="text-center mb-2 ">EMAIL ID</th>`;
      // let smsHeader = `<th class="text-center mb-2 ">MOBILE</th>`;
      // let header = `<table class="w-full">
      //         <tr>
      //           <th class="text-center mb-2 cust-border-left">SERIAL NO</th>
      //           ${isSMS ? smsHeader : ""}
      //           ${isEmail ? emailHeader : ""}
      //         </tr>`;

      // const smsBody = index => {
      //   return `<td class="pd-10 break-normal"> ${
      //     mobilenumbers[index] ? mobilenumbers[index] : "N.A"
      //   }</td>`;
      // };

      // const emailBody = index => {
      //   return `<td class="pd-10  break-normal"> ${
      //     emailIDs[index] ? emailIDs[index] : "N.A"
      //   }</td>`;
      // };

      // let footer = `</table>`;

      // for (let index = 0; index < data.length; index++) {
      //   tableData += `<tr>
      //           <td class="pd-10 cust-border-left">${index + 1}</td>
      //           ${isSMS ? smsBody(index) : ""}
      //           ${isEmail ? emailBody(index) : ""}
      //         </tr>`;
      // }
      // console.log(post,"jhvjghg");
      this.$swal({
        icon: "info",
        title: `${infoTitle()} will be sent to`,
        html: `${infoText}`,
        focusConfirm: false,
        confirmButtonText: "Send",
        cancelButtonText: "Cancel",
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          console.log("kjhkhj");
          let signature = ``;
          if (isDefaultSign == "radio1") {
            signature = `Upasna Bhardwaj`;
          } else if (isDefaultSign == "radio2") {
            signature = this.token.username;
          } else {
            signature = `Kotak Markets Commentary`;
          }
          if (isSMS) {
            const SMS_TEMPLATE_1 = (subject, link) =>
              `Dear Reader,\nDaily Kotak snippets on Domestic and Global market developments.\n${subject}\n${link}`;
            this.sendSMS(
              SMS_TEMPLATE_1(text, postLink),
              mobilenumbers,
              signature
            );
          }
          if (isEmail) {
            this.sendEmail(
              marketUpdateEmailHTML(postLink, postBlog),
              text,
              emailIDs
            );
          }
          if (isWhatsapp) {
            // const WHATSAPP_MESSAGE_1 = (subject, link) => {
            //   return `Dear Reader\nDaily Kotak snippets on Domestic and Global market developments\nSubject - ${subject}\nLink – ${link}\nThanks & Regards,\nKotak Treasury`;
            // };

            const WHATSAPP_MESSAGE_1 = (name, subject, link, post) => {
              return `Dear ${name},\n${post.post_whatsapp_body1}\n${post.post_whatsapp_body2}\nHighlights –\n${post.post_highlights1}\n${post.post_highlights2}\n\nRead More- ${link}`;
            };

            // const WHATSAPP_MESSAGE_2 = (name, subject, link,post) => {
            //   return `Dear ${name},\n${post.post_whatsapp_body1}\nHighlights -\n1. ${post.post_highlights1}\n2. ${post.post_highlights2}\n\nRead More - ${link}`;
            // };

            const WHATSAPP_MESSAGE_3 = (name, subject, link) => {
              return `Dear ${name},\n\nDaily Kotak snippets on Domestic and Global market developments\n\nSub - ${subject}\nRead More - ${link}`;
            };

            let messages = [];

            whatsappData.forEach((element) => {
              console.log(post, "gffgfg");
              if (
                post.post_whatsapp_body1 &&
                post.post_whatsapp_body2 &&
                post.post_highlights1 &&
                post.post_highlights2
              ) {
                console.log(post.post_highlights2, "kjhhjg");
                messages.push({
                  message:
                    WHATSAPP_MESSAGE_1(element.name, text, postLink, post) +
                    `\nRegards,\n${signature}\nKotak Treasury`,
                  mobile: element.mobile,
                });
              }
              // if(post.post_whatsapp_body1 && !post.post_whatsapp_body2){
              // messages.push({
              //   message:
              //     WHATSAPP_MESSAGE_2(element.name, text, postLink,post) +
              //     `\nRegards,\n${signature}\nKotak Treasury`,
              //   mobile: element.mobile,
              // });}
              if (!post.post_highlights1) {
                console.log(post, "poo");
                messages.push({
                  message:
                    WHATSAPP_MESSAGE_3(element.name, text, postLink, post) +
                    `\n\nRegards,\n${signature}\nKotak Treasury`,
                  mobile: element.mobile,
                });
              }
            });
            console.log(messages);
            this.sendWhatsapp(messages);
          }
          this.addSentPromotionLog({
            postId: postID,
            groupId: groupUUID,
            uuids: uuidList,
            isSMS: isSMS ? 1 : 0,
            isEmail: isEmail ? 1 : 0,
            isWhatsapp: isWhatsapp ? 1 : 0,
          });
        }
      });
    },
    postAddedAlert() {
      this.$swal({
        icon: "success",
        title: "Yay! Your post is now Live.",
        focusConfirm: false,
        confirmButtonText: "See Post",
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: true,
      }).then((result) => {
        if (result.value) {
          this.viewGroup();
        }
      });
    },
  },
};
