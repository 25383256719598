<template>
  <div class="text-center">
    <h2>How would you like to promote</h2>
    <div class="form-check flex justify-between w-1/2 mx-auto mt-8">
      <div class="">
        <input
          class="form-check-input"
          type="radio"
          name="promote"
          value="sms"
          v-model="toggleCheckbox"
        />
        <label class="form-check-label" for="toggleCheckbox"> SMS </label>
      </div>
      <div class="">
        <input
          class="form-check-input"
          type="radio"
          value="email"
          name="promote"
          v-model="toggleCheckbox"
        />
        <label class="form-check-label" for="toggleCheckbox"> Email </label>
      </div>

      <div class="">
        <input
          class="form-check-input"
          type="radio"
          name="promote"
          value="whatsapp"
          v-model="toggleCheckbox"
        />
        <label class="form-check-label" for="toggleCheckbox"> Whatsapp </label>
      </div>
    </div>
    <br />
    <div v-if="toggleCheckbox">
      <div class="form-check">
        <input
          class="form-check-input"
          checked
          name="regards"
          type="radio"
          value="radio1"
          id="radio1"
        />
        <label class="form-check-label" for="radio1"> Default Signature </label>
        <p>
          Regards,
          <br />
          Upasna Bhardwaj <br />
          Kotak Treasury
        </p>
      </div>
      <br />
      <div class="form-check">
        <input
          class="form-check-input"
          name="regards"
          type="radio"
          value="radio2"
          id="radio2"
        />
        <label class="form-check-label" for="radio2"> My Signature </label>
        <p>
          Regards,
          <br />
          this.token.username
          <br />
          Kotak Treasury
        </p>
      </div>
      <br />
      <div class="form-check">
        <input
          class="form-check-input"
          name="regards"
          type="radio"
          value="radio3"
          id="radio2"
        />
        <label class="form-check-label" for="radio3"> Kotak Signature </label>
        <p>
          Regards,
          <br />
          Kotak Markets Commentary
          <br />
          Kotak Treasury
        </p>
      </div>
    </div>
    <div class="flex justify-center gap-4 mt-4">
      <vs-button size="small" class="px-10 py-3">Promote</vs-button>
      <vs-button size="small" class="px-10 py-3">Cancel</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggleCheckbox: "",
    };
  },
};
</script>

<style >
.modal-dialog {
  margin: 10rem auto;
}
</style>

