const BASE_URL = `${process.env.VUE_APP_BASE_URL_COR_API}`;

var images = [
  `emailers/img/kotak-billboard.jpg`,
  `emailers/img/kotak-billboard-1.jpg`,
  `emailers/img/kotak-billboard-2.jpg`
];

export function getRandomImage() {
  var randomNumber = Math.floor(Math.random() * images.length);
  return `${BASE_URL}${images[randomNumber]}`;
}
