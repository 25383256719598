<!-- =========================================================================================
  File Name: ModifyGroup.vue
  Description: Add Edit Group - Sidebar component
  ----------------------------------------------------------------------------------------
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="modify-grpparent-div">
      <div class="w-full" v-show="!usersList">
        <div class="flex px-4 sidebar-topfixpart bg-primary">
          <feather-icon
            icon="ChevronLeftIcon"
            @click.stop="isSidebarActiveLocal = false"
            class="cursor-pointer"
          ></feather-icon>
          <vs-button class="h2-class pd-10 cust-remove-boxsh"
            >CHANNELS</vs-button
          >
        </div>
        <div id="demo-basic-card">
          <div v-show="!usersList">
            <div class="">
              <div class="vx-col w-full">
                <div class>
                  <div
                    class="img-uploadBox mt-16 img-uploadvrop-h mg-left-right"
                  >
                    <div class="croper-pos-rel">
                      <div
                        :style="{
                          backgroundImage: 'url(' + newGroupImage + ')',
                        }"
                        class=""
                      ></div>

                      <Cropper
                        v-show="newGroupImage != null"
                        class="img-uploadBox-cropper"
                        :src="newGroupImage"
                        ref="cropper"
                        :imageRestriction="'none'"
                        :stencilProps="stencilPropsModifyGroup"
                      />
                      <div class="modify-grp-editbtn">
                        <vs-button
                          v-if="newGroupImage != null"
                          icon-pack="feather"
                          class="modify-edit-icon"
                          onclick="document.getElementById('group-icon-Upload').click();"
                          type="transparent"
                          radius
                          icon="icon-edit-2"
                          >Change Picture</vs-button
                        >
                      </div>
                    </div>
                    <div class v-if="newGroupImage == null">
                      <div class="w-full">
                        <div
                          class="modify-up-childPd"
                          onclick="document.getElementById('group-icon-Upload').click();"
                        >
                          <div class>
                            <div class="row">
                              <feather-icon
                                class="icon feather icon-camera img-upload-centicon w-full"
                                icon="icon-camera"
                              ></feather-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      id="group-icon-Upload"
                      @change="uploadGroupImage"
                      type="file"
                      accept="image/*"
                      class="hidden"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="upload-img-botm-parent">
              <div class="tit-desc-md">
                <div class="vx-col w-full col-pd-0">
                  <vs-input
                    placeholder="Channel Name"
                    class="w-full input_border"
                    v-model="groupName"
                  />
                </div>
              </div>
              <div class="tit-desc-md">
                <div class="vx-col w-full col-pd-0">
                  <vs-input
                    placeholder="Channel Description"
                    class="w-full input_border"
                    v-model="groupDescription"
                  />
                </div>
              </div>
              <div class="vx-row ml-0" v-if="!groupEdit">
                <div class="vx-col w-full ml-0 mt-4 flex">
                  <div class="grp_typ_text mb-2 mr-3">
                    <span class="spce-grp font-medium">Channel type</span>
                  </div>

                  <div class="ModifyGroup_switch_field">
                    <input
                      type="radio"
                      id="radio-one"
                      value="1"
                      v-model="groupType"
                      checked
                    />
                    <label for="radio-one" class="font-medium">Private</label>
                    <!-- <input
                      type="radio"
                      id="radio-two"
                      value="2"
                      v-model="groupType"
                    />
                    <label for="radio-two" class="font-medium">Public</label> -->
                    <input
                      type="radio"
                      id="radio-three"
                      value="3"
                      v-model="groupType"
                      v-show="check('admin')"
                    />
                    <label
                      for="radio-three"
                      class="font-medium"
                      v-show="check('admin')"
                      >System</label
                    >
                  </div>
                </div>
              </div>
              <div class="vx-row ml-0">
                <div class="vx-col w-full ml-0 mt-4 flex">
                  <div class="grp_typ_text mb-2 mr-3">
                    <span class="spce-grp font-medium">Posting Rights</span>
                  </div>

                  <div class="ModifyGroup_switch_field">
                    <input
                      type="radio"
                      id="radio-p-one"
                      value="0"
                      v-model="postingRight"
                      checked
                    />
                    <label for="radio-p-one" class="font-medium"
                      >Admin Only</label
                    >
                    <input
                      type="radio"
                      id="radio-p-two"
                      value="1"
                      v-model="postingRight"
                    />
                    <label for="radio-p-two" class="font-medium">Anyone</label>
                  </div>
                </div>
              </div>
            </div>
            <div class>
              <div class="bottom-fix-btn">
                <vs-button
                  v-if="!groupEdit && (groupType == 1 || groupType == 3)"
                  :disabled="!createGroupValidation"
                  type="filled"
                  @click="getGroupByName()"
                  class="w-full border-rem h2-class"
                  >ADD PARTICIPANTS</vs-button
                >
                <vs-button
                  v-if="!groupEdit && groupType == 2"
                  :disabled="!createGroupValidation"
                  type="filled"
                  @click="createGroup"
                  class="w-full border-rem h2-class"
                  >CREATE</vs-button
                >
              </div>
              <div class="bottom-fix-btn">
                <vs-button
                  v-if="groupEdit"
                  type="filled"
                  @click="saveEditGroupDetails"
                  class="w-full border-rem h2-class"
                  >SAVE</vs-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-16" v-show="usersList">
        <div class="flex px-4 sidebar-topfixpart bg-primary">
          <feather-icon
            v-if="addMembersPage"
            icon="ChevronLeftIcon"
            @click="isSidebarActiveLocal = false"
            class="cursor-pointer"
          ></feather-icon>
          <feather-icon
            v-if="!addMembersPage"
            icon="ChevronLeftIcon"
            @click="usersList = !usersList"
            class="cursor-pointer"
          ></feather-icon>
          <vs-button class="h2-class pd-10 cust-remove-boxsh"
            >CHANNELS</vs-button
          >
        </div>
        <div
          class="con-img m-2"
          v-for="userImage in selectedUserImages"
          :key="userImage.id"
          style="display: inline-flex; position: inherit"
          @click="removeUserFromGroup(userImage)"
        >
          <!-- <img
            :src="imageBaseUrl + userImage.image"
            v-if="userImage.image != null"
            alt="content-img"
            class="imguserwidth-35"
          />
          <img
            :src="imageBaseUrl + 'uploads/defaultImages/default_user.jpg'"
            v-if="userImage.image == null"
            alt="content-img"
            class="imguserwidth-35"
          />-->
          <!-- <div class="minus_icon">
            <feather-icon
              class="icon feather modify_group_minusbtn cross-bg-color feather-24 ml-4 mt-3 text-danger"
              icon="XCircleIcon"
            ></feather-icon>
          </div>-->
          <vs-chip closable class="grp-userselect" close-icon="close">
            <vs-avatar
              v-if="userImage.image"
              :src="imageBaseUrl + userImage.image"
            />

            <vs-avatar :text="userImage.name" color="primary" else />
            {{ userImage.name }}
          </vs-chip>
        </div>

        <MemberSearch
          :searchTerm="memberSearch"
          :dataArray="usersListForGroup"
          :keys="searchKey"
          @searchResult="filterUsers"
        >
          <template slot="searchBar">
            <div
              class="mb-4 invite-disc-search mob-view-serh ml-3 mr-3 flex items-cente"
              style="border-radius: 0px !important"
            >
              <vs-input
                icon-pack="feather"
                icon="icon-search"
                icon-after
                vs-placeholder="Nombre"
                class="w-full"
                v-model="memberSearch"
              />
              <!-- <vs-input icon-pack="feather" icon="icon-search" icon-after vs-placeholder="Nombre" class="view-memb-serachbar" v-model="memberSearch" /> -->
            </div>
          </template>
        </MemberSearch>

        <ul class="ul-user-mem-scroll cust-pd-5">
          <li class="friend-suggestion flex px-2 items-center mb-5">
            <div class="ml-3 w-full">
              <p class="user-text-md my-0">Select All</p>
              <div class="ml-2 w-full"></div>
            </div>
            <label class="pl-13px mb-0 flex justify-end">
              <input
                type="checkbox"
                v-model="selectAll"
                @change="selectAllUsers()"
                :checked="selectAll"
              />
              <span class="checkmark"></span>
            </label>
          </li>
          <li
            v-for="userForGroup in userList"
            :key="userForGroup.usersDetailsId"
            :class="
              !userForGroup.isSelected ? 'friend-suggestion mb-0' : 'hidden'
            "
            @click="
              userForGroup.isSelected = !userForGroup.isSelected;
              addUserToGroup(userForGroup);
            "
          >
            <div
              class="flex px-2 items-center cursor-pointer mb-2"
              v-if="!userForGroup.isSelected"
            >
              <div class="media-left">
                <UserAvatar
                  :image="userForGroup.usersDetails_UserImage"
                  :text="userForGroup.usersDetails_UserName"
                  :size="'35px'"
                  @imageNotFound="userForGroup.usersDetails_UserImage = null"
                />
              </div>

              <div class="row media-body ml-3 w-full flex flex-col">
                <p class="user-text-md mb-1">
                  {{ userForGroup.usersDetails_UserName }}
                </p>
                <span class="textmute-usermes h4-class">
                  {{ userForGroup.usersDetails_TagLine }}
                </span>
              </div>
              <label class="">
                <input
                  type="checkbox"
                  v-model="userForGroup.isSelected"
                  @change="
                    userForGroup.isSelected = !userForGroup.isSelected;
                    addUserToGroup(userForGroup);
                  "
                  checked="checked"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <!-- <div class="ml-auto cursor-pointer">
            <vs-checkbox v-model="userForGroup.isSelected" @click="addUserToGroup(userForGroup)"></vs-checkbox>
            </div>-->
          </li>
          <infinite-loading
            ref="infiniteLoadingMembersForGroup"
            @infinite="infiniteHandlerMembersForGroup"
          >
            <div slot="no-more">
              <!-- <p>All Members Displayed</p> -->
            </div>
          </infinite-loading>
        </ul>
        <div
          class="flex justify-center"
          v-if="searchResults.length == 0 && memberSearch != ''"
        >
          Members not found
        </div>
        <div class="bottom-fix-btn">
          <vs-button
            v-if="groupEdit"
            type="filled"
            @click="saveEditGroup"
            class="bg-primary w-full border-rem h2-class"
            >ADD PARTICIPANTS</vs-button
          >
        </div>
        <div class="bottom-fix-btn">
          <vs-button
            v-if="!groupEdit"
            type="filled"
            :disabled="disableCreateGroup"
            @click="createGroup"
            class="bg-primary w-full border-rem h2-class"
            >CREATE</vs-button
          >
        </div>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
//import axios from "axios";
import jwt from "jsonwebtoken";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const userRepository = RepositoryFactory.get("user");
const groupsRepository = RepositoryFactory.get("groups");
const notificationRepository = RepositoryFactory.get("notifications");
import { Cropper } from "vue-advanced-cropper";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import "@/Core/Views/Commoncss/componentCss/allSidebar.css";
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
import MemberSearch from "@/Core/Views/CommonPlugins/member-search/MemberSearch";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      else {
        let token = localStorage.getItem("token");
        this.token = jwt.decode(token);
        let managerToken = localStorage.getItem("managertoken");
        if (managerToken != null) {
          this.managerToken = jwt.decode(managerToken);
        } else {
          this.managerToken = null;
        }

        let {
          groupId,
          groupName,
          newGroupImage,
          groupType,
          groupDescription,
          postingRights,
          usersListForEditGroup,
          groupEdit,
          usersList,
          addMembers,
        } = JSON.parse(JSON.stringify(this.data));

        if (Object.entries(this.data).length > 0) {
          this.usersList = false;
          this.editGroupId = groupId;
          this.groupName = groupName;
          this.newGroupImage = newGroupImage;
          this.groupType = groupType;
          this.groupDescription = groupDescription;
          this.postingRight = postingRights;
          this.usersListForEditGroup = usersListForEditGroup;
          this.groupEdit = groupEdit;
          this.usersList = usersList;
          this.addMembersPage = addMembers;

          this.showGroupUsersList();
        } else {
          this.$refs.infiniteLoadingMembersForGroup.stateChanger.reset();
          this.clearGroupData();
          // this.showGroupUsersList();
        }
      }
    },
  },
  data() {
    return {
      memberSearch: "",
      searchResults: [],
      token: {},
      managerToken: null,
      usersList2: false,
      date: null,
      dataId: null,
      dataName: "",
      dataCategory: null,
      dataImg: null,
      dataOrder_status: "pending",
      dataPrice: 0,
      card_1: {},
      newUserImage: null,
      newUserCoverImage: null,
      name: null,
      phone: null,
      gender: null,
      dob: null,
      tagline: null,
      additionalDetail1: null,
      formFields: [],
      groupName: "",
      groupDescription: "",
      uploadGroupIcon: null,
      newGroupImage: null,
      groupType: 1,
      groupStatus: 3,
      groupEdit: false,
      editGroupId: null,
      usersListForGroup: [],
      usersListForEditGroup: [],
      usersInGroup: [],
      uuidInGroup: [],
      selectedUserImages: [],
      usersList: false,
      stencilPropsModifyGroup: {
        aspectRatio: 1 / 1,
      },
      groupUserLimit: 30,
      groupUserOffset: 0,
      groupUserPage: 0,
      disableCreateGroup: false,
      category_choices: [
        { text: "Audio", value: "audio" },
        { text: "Computers", value: "computers" },
        { text: "Fitness", value: "fitness" },
        { text: "Appliance", value: "appliance" },
      ],

      order_status_choices: [
        { text: "Pending", value: "pending" },
        { text: "Canceled", value: "canceled" },
        { text: "Delivered", value: "delivered" },
        { text: "On Hold", value: "on_hold" },
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      addMembersPage: false,
      communityManagers: [],
      postingRight: 0,
      selectAll: false,
      searchKey: ["usersDetails_UserName"],
      initialRole: "admin",
    };
  },
  inheritAttrs: false,
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.ModifyGroupReset();
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    createGroupValidation() {
      return this.groupName != "";
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataName &&
        this.dataCategory &&
        this.dataPrice > 0
      );
    },
    imageBaseUrl() {
      return this.baseUrlMagitt;
    },
    userList() {
      if (this.memberSearch.length > 1) {
        return this.searchResults;
      } else {
        return this.usersListForGroup;
      }
    },
  },
  methods: {
    check(role) {
      if (role === "admin") {
        if (this.initialRole == "admin" || this.initialRole == "superadmin") {
          return true;
        }
      } else if (role === "superadmin") {
        if (this.initialRole == "superadmin") {
          return true;
        }
      }
    },
    selectAllUsers() {
      if (this.selectAll) {
        this.usersListForGroup.forEach((element) => {
          if (!element.isSelected) {
            element.isSelected = true;
            this.addUserToGroup(element);
          }
        });
      } else {
        this.usersListForGroup.forEach((element) => {
          if (element.isSelected) {
            element.isSelected = false;
            this.addUserToGroup(element);
          }
        });
      }
    },
    ModifyGroupReset() {
      this.memberSearch = "";
      this.managerToken = null;
      this.usersList2 = false;
      this.date = null;
      this.dataId = null;
      this.dataName = "";
      this.dataCategory = null;
      this.dataImg = null;
      this.dataOrder_status = "pending";
      this.dataPrice = 0;
      this.card_1 = {};
      this.newUserImage = null;
      this.newUserCoverImage = null;
      this.name = null;
      this.phone = null;
      this.gender = null;
      this.dob = null;
      this.tagline = null;
      this.additionalDetail1 = null;
      this.formFields = [];
      this.groupName = "";
      this.groupDescription = "";
      this.uploadGroupIcon = null;
      this.newGroupImage = null;
      this.groupType = 1;
      this.groupStatus = 3;
      this.groupEdit = false;
      this.editGroupId = null;
      this.usersListForGroup = [];
      this.usersListForEditGroup = [];
      this.usersInGroup = [];
      this.uuidInGroup = [];
      this.selectedUserImages = [];
      this.usersList = false;
      this.stencilPropsModifyGroup = {
        aspectRatio: 1 / 1,
      };
      this.groupUserLimit = 30;
      this.groupUserOffset = 0;
      this.groupUserPage = 0;
      this.disableCreateGroup = false;
      this.category_choices = [
        { text: "Audio", value: "audio" },
        { text: "Computers", value: "computers" },
        { text: "Fitness", value: "fitness" },
        { text: "Appliance", value: "appliance" },
      ];

      this.order_status_choices = [
        { text: "Pending", value: "pending" },
        { text: "Canceled", value: "canceled" },
        { text: "Delivered", value: "delivered" },
        { text: "On Hold", value: "on_hold" },
      ];
      this.settings = {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      };
      this.$refs.infiniteLoadingMembersForGroup.stateChanger.reset();
      this.selectAll = false;
    },
    addParticipants() {
      this.usersList = !this.usersList;
      if (!this.groupEdit) {
        this.$refs.infiniteLoadingMembersForGroup.stateChanger.reset();
      }
    },
    async searchAllMembers() {
      if (this.memberSearch.length > 1) {
        let obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          userData: {
            searchTerm: this.memberSearch,
          },
          paging: {
            limit: 12,
            offset: 0,
          },
        };

        const { data } = await userRepository.searchAllUsers(obj);
        if (data.length) {
          data.forEach((element) => {
            element.isSelected = false;
            this.usersListForGroup.push(element);
          });

          // if (this.usersListForEditGroup.length > 0) {
          //   this.usersListForEditGroup.forEach(element => {
          //     this.usersListForGroup[
          //       this.usersListForGroup.findIndex(
          //         x => (x.usersDetailsId = element.usersDetailsId)
          //       )
          //     ].isSelected = true;
          //     element.isSelected = true;
          //     this.addUserToGroup(element);
          //     console.log(element.usersDetailsId, element);
          //   });
          // }
        }
        // if (data.length) {
        //   this.users = data;
        // }
      } else if (this.memberSearch.length == 0) {
        this.showGroupUsersList();
      }
    },
    initValues() {
      if (this.data.id) return;
      this.dataId = null;
      this.dataName = "";
      this.dataCategory = null;
      this.dataOrder_status = "pending";
      this.dataPrice = 0;
      this.dataImg = null;
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async showGroupUsersList() {
      if (!this.usersLoaded) {
        let obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          userData: {
            userID: this.token.uuid,
          },
          paging: {
            limit: this.groupUserLimit,
            offset: this.groupUserOffset,
          },
        };

        const { data } = await userRepository.getAllUsers(obj);

        return new Promise((resolve, reject) => {
          if (data) {
            if (data.length > 0) {
              resolve(true);
            } else {
              resolve(false);
            }
            data.forEach((element) => {
              element.isSelected = false;
              if (
                element.usersDetails_BlockStatus != 1 &&
                element.usersDetails_Status != 0 &&
                element.usersDetails_Status != 2
              ) {
                this.usersListForGroup.push(element);
              }
            });

            if (this.usersListForEditGroup.length > 0) {
              this.usersListForEditGroup.forEach((element) => {
                if (
                  this.usersListForGroup.findIndex(
                    (x) => x.usersDetailsId == element.usersDetailsId
                  ) != -1
                ) {
                  this.usersListForGroup.splice(
                    this.usersListForGroup.findIndex(
                      (x) => x.usersDetailsId == element.usersDetailsId
                    ),
                    1
                  );
                  // this.usersListForGroup[
                  //   this.usersListForGroup.findIndex(
                  //     x => x.usersDetailsId == element.usersDetailsId
                  //   )
                  // ].isSelected = true;
                  // console.log(
                  //   this.usersListForGroup[
                  //     this.usersListForGroup.findIndex(
                  //       x => x.usersDetailsId == element.usersDetailsId
                  //     )
                  //   ].isSelected,
                  //   "showGroupUsersList"
                  // );
                  // //element.isSelected = true;
                  // this.addUserToGroup(
                  //   this.usersListForGroup[
                  //     this.usersListForGroup.findIndex(
                  //       x => x.usersDetailsId == element.usersDetailsId
                  //     )
                  //   ]
                  // );
                }
                // console.log(element.usersDetailsId, element, "showGroupUsersList");
                // console.log(this.usersListForGroup, "showGroupUsersList");
              });
            }
          } else {
            reject(false);
          }
        });
      }
    },
    addUserToGroup(userData) {
      if (userData.isSelected) {
        this.usersInGroup.push(userData.usersDetailsId);
        this.uuidInGroup.push(userData.usersDetails_UniqueId);
        const selectedIndex = this.selectedUserImages.findIndex(
          (x) => x.id == userData.usersDetailsId
        );
        if (selectedIndex == -1) {
          this.selectedUserImages.push({
            id: userData.usersDetailsId,
            uuid: userData.usersDetails_UniqueId,
            image: userData.usersDetails_UserImage,
            name: userData.usersDetails_UserName,
          });
        }
      } else {
        this.usersInGroup.splice(
          this.usersInGroup.findIndex(
            (x) => x.usersDetailsId == userData.usersDetailsId
          ),
          1
        );
        this.uuidInGroup.splice(
          this.uuidInGroup.findIndex(
            (x) => x.usersDetails_UniqueId == userData.usersDetails_UniqueId
          ),
          1
        );
        this.selectedUserImages.splice(
          this.selectedUserImages.indexOf({
            id: userData.usersDetailsId,
            image: userData.usersDetails_UserImage,
          }),
          1
        );
      }
      // this.usersListForGroup.splice(this.usersListForGroup.indexOf("foo"), 1);
    },
    removeUserFromGroup(userData) {
      this.usersInGroup.splice(
        this.usersInGroup.findIndex((x) => x == userData.id),
        1
      );
      this.uuidInGroup.splice(
        this.uuidInGroup.findIndex((x) => x == userData.uuid),
        1
      );
      this.usersListForGroup[
        this.usersListForGroup.findIndex(
          (x) => x.usersDetailsId === userData.id
        )
      ].isSelected = false;
      this.selectedUserImages.splice(
        this.selectedUserImages.findIndex((x) => x.id == userData.id),

        1
      );
    },
    async createGroup() {
      // this.$vs.loading();
      this.disableCreateGroup = true;
      if (this.newGroupImage != null) {
        this.crop();
      }
      if (this.groupType == 1 || this.groupType == 3) {
        this.groupStatus = 1;
      } else {
        this.groupStatus = 3;
      }

      let obj = {
        groupData: {
          userId: this.token.userID,
          groupName: this.groupName,
          groupDescription: this.groupDescription,
          groupIcon: this.newGroupImage,
          typeId: this.groupType,
          status: this.groupStatus,
          ownerName: this.token.username,
          allTimeGroup: this.postingRight,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      this.createNewGroup(obj);
    },

    async createNewGroup(obj) {
      const data = await groupsRepository.addGroup(obj);

      if (data) {
        this.channelCreatedAlert();
        let payload = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const ownerData = await groupsRepository.getCommunityOwner(payload);
        this.usersInGroup.push(
          Number(ownerData.data[0].communityManager_UserId)
        );

        if (data.data.insertId && this.usersInGroup.length > 0) {
          if (this.token.userID != 1) {
            await this.inviteUserToGroup(data.data.insertId);
          }
          this.$store.state.groupId = data.data.insertId;
          const ownerData = await groupsRepository.getCommunityOwner(payload);
          let communutyOmnerRequestData = {
            groupId: data.data.insertId,
            userId: ownerData.data[0].communityManager_UserId,
          };
          this.makeGroupAdmin(communutyOmnerRequestData);
        } else {
          if (this.groupType == 2) {
            if (this.check("admin") || this.check("superadmin")) {
              this.$store.state.groupId = data.data.insertId;
              this.changeGroupStatus(data.data.insertId);
            } else {
              this.getCommunityManagers(data.data.insertId, this.groupName);
            }
          } else {
            this.$vs.loading.close();
            this.$store.state.groupId = data.data.insertId;
            this.channelCreatedAlert();
            this.disableCreateGroup = false;
            // this.$parent.GroupList.push({
            //   groupId: data.insertId,
            //   group_type_id: self.groupType,
            //   group_status: self.groupStatus,
            //   group_name: self.groupName,
            //   group_icon: self.newGroupImage,
            //   group_description: self.groupDescription
            // });
            this.isSidebarActiveLocal = false;
            this.clearGroupData();
            this.$emit("groupCreated");
          }
        }
      }
    },
    async makeGroupAdmin(params) {
      // let self = this;
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          groupId: params.groupId,
          userId: params.userId,
        },
      };
      const { data } = await groupsRepository.makeGroupAdmin(payload);
      console.log("datadatadatadatadatadatadatadata", data);
      if (data.ResponseCode == 100) {
        this.channelCreatedAlert();
        this.active_sidebar = false;
        this.makeGroupAdminNotification(uuid);
        this.memberList[
          this.memberList.findIndex((x) => x.usersDetailsId == userId)
        ].group_admin_id = data.adminId;
        if (this.currentGroupPostingType == 0) {
          this.changePostingRights(userId, 1);
        }
      }
    },
    channelCreatedAlert() {
      this.$swal({
        icon: "success",
        title: "Channel Created!",
        focusConfirm: false,
        confirmButtonText: "See Channel",
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: true,
      }).then((result) => {
        if (result.value) {
          this.viewGroup();
          window.location.reload();
        }
      });
    },
    viewGroup() {
      if (this.$route.path == "/community-channels") {
        this.$store.dispatch(
          "storeComponentKey",
          this.$store.state.componentKey++
        );
        this.$router
          .replace({
            name: "page-GroupsPage",
            params: { groupId: this.$store.state.groupId },
            query: { reload: true },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: "page-GroupsPage",
            params: { groupId: this.$store.state.groupId },
          })
          .catch(() => {});
      }
    },
    async inviteUserToGroup(insertId) {
      let obj = {
        invitationData: {
          groupId: insertId,
          inviterUserId: this.token.userID,
          ownerName: this.token.username,
          status: this.postingRight,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        userData: this.usersInGroup,
      };
      // console.log(this.usersInGroup,"users list");
      const { data } = await groupsRepository.inviteUserToGroup(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        this.channelCreatedAlert();
        // this.$parent.GroupList.push({
        //   groupId: insertId,
        //   group_type_id: this.groupType,
        //   group_status: this.groupStatus,
        //   group_name: this.groupName,
        //   group_icon: this.newGroupImage,
        //   group_description: this.groupDescription
        // });
        this.insertInvitationNotification(
          insertId,
          this.uuidInGroup,
          this.groupName
        );
        this.groupName = "";
        this.groupDescription = "";
        this.groupType = null;
        this.usersList = false;
        this.disableCreateGroup = false;
        this.$vs.notify({
          color: "success",
          title: "Channel Created",
        });
      }
    },
    async insertInvitationNotification(groupId, userId, groupName) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: `has added you to ${groupName}`,
            notificationTitle: "Group",
            notificationEntityType: "Group Invitation",
            notificationEntityId: groupId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const { data } = await notificationRepository.addNotification(payload);
        if (data) {
          this.isSidebarActiveLocal = false;
          this.clearGroupData();
        }
      }
    },
    async changeGroupStatus(groupId) {
      this.$vs.loading();
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        ownerName: this.token.username,
        groupId: groupId,
        status: 1,
      };
      const { data } = await groupsRepository.changeGroupStatus(obj);

      if (data) {
        this.$vs.loading.close();
        this.channelCreatedAlert();
        this.groupName = "";
        this.groupDescription = "";
        this.groupType = null;
        this.usersList = false;
        this.disableCreateGroup = false;
        this.isSidebarActiveLocal = false;
        this.$vs.notify({
          color: "success",
          title: "Channel Created",
        });
        this.$emit("groupCreated");
      }
    },
    async approvePublicGroup(groupId) {
      this.$vs.loading();
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        name: this.token.username,
        uuid: this.token.uuid,
        groupId: groupId,
      };
      const { data } = await groupsRepository.insertAllMembersInGroup(obj);

      if (data) {
        this.$vs.loading.close();
        this.channelCreatedAlert();
        this.insertInvitationNotification(
          groupId,
          data.users.map((x) => x.usersDetails_UniqueId),
          this.groupName
        );
        this.groupName = "";
        this.groupDescription = "";
        this.groupType = null;
        this.usersList = false;
        this.disableCreateGroup = false;
        this.isSidebarActiveLocal = false;
        this.$vs.notify({
          color: "success",
          title: "Channel Created",
        });
        this.$emit("groupCreated");
      }
    },
    async getCommunityManagers(groupdata, groupname) {
      const payload = {
        communityName: currentCommunityName.communityName,
      };
      const { data } = await userRepository.getCommunityManagers(payload);

      if (data) {
        this.communityManagers = data.map((x) => x.usersDetails_UniqueId);
        this.insertPublicGroupNotification(
          groupdata,
          this.communityManagers,
          groupname
        );
      }
    },
    async insertPublicGroupNotification(groupId, userId, groupName) {
      let payload = {
        notificationData: {
          initiaterId: this.token.uuid,
          initiaterName: this.token.username,
          initiaterImage: this.token.userImage,
          recepientId: userId,
          recepientType: 1,
          notificationMessage: `wants to create a public channel - ${groupName}`,
          notificationTitle: "Group",
          notificationEntityType: "Public Group Request",
          notificationEntityId: groupId,
          notificationReadStatus: 0,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await notificationRepository.addNotification(payload);
      if (data) {
        this.$vs.loading.close();
        this.$swal("", "Group Request Sent!", "success");
        this.disableCreateGroup = false;
        this.isSidebarActiveLocal = false;
        this.clearGroupData();
      }
    },
    uploadGroupImage(ev) {
      const supportedTypes = ["image/jpeg", "image/png"];
      const fileSize = 25 * 1024 * 1024;
      if (
        ev.target.files.length &&
        !supportedTypes.includes(ev.target.files[0].type)
      ) {
        this.unsupportedFormatAlert();
        return;
      } else if (ev.target.files.length && ev.target.files[0].size > fileSize) {
        this.largeFileSizeAlert();
        return;
      } else if (ev.target.files.length > 0) {
        const file = ev.target.files[0];
        if (
          !supportedTypes.includes(ev.target.files[0].type) ||
          ev.target.files[0].size > fileSize
        )
          return;
        const reader = new FileReader();
        reader.onload = (e) =>
          this.$emit("load", (this.newGroupImage = e.target.result));
        reader.readAsDataURL(file);
        // this.newGroupImage = reader.result;
      }
    },
    clearGroupData() {
      this.groupName = "";
      this.groupDescription = "";
      this.uploadGroupIcon = null;
      this.newGroupImage = null;
      this.groupType = null;
      this.groupEdit = false;
      this.usersList = false;
      (this.usersListForEditGroup = []), (this.usersListForGroup = []);
      this.selectedUserImages = [];
      this.groupUserLimit = 30;
      this.groupUserOffset = 0;
      this.groupUserPage = 0;
    },
    async editGroup(groupId) {
      this.groupEdit = true;
      this.editGroupId = groupId;
      this.clearGroupData();
      // this.active = true;
      let obj = {
        groupData: {
          id: groupId,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };

      const { data } = await groupsRepository.getGroup(obj);

      if (data) {
        this.setEditGroupData(data[0]).then(() => {
          this.getUsersInGroup(groupId, this.token.userID);
        });
      }
    },
    async setEditGroupData(groupData) {
      this.groupName = groupData.group_name;
      this.newGroupImage = this.imageBaseUrl + groupData.group_icon;
      this.groupType = groupData.group_type_id;
      this.groupDescription = groupData.group_description;
    },
    async getUsersInGroup(groupId, userId) {
      this.usersList = false;
      // this.active = true;
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupId: groupId,
        userId: userId,
      };

      const { data } = await groupsRepository.getUsersInGroup(obj);

      if (data) {
        this.active = true;
        this.sidebarData.usersListForEditGroup = data;
      }
    },
    async saveEditGroup() {
      var self = this;
      if (self.newGroupImage != null) {
        self.crop();
      }
      // let obj = {
      //   groupData: {
      //     groupName: self.groupName,
      //     groupDescription: self.groupDescription,
      //     groupIcon: this.newGroupImage,
      //     typeId: self.groupType,
      //     status: 1,
      //     ownerName: self.token.username,
      //     allTimeGroup: 0,
      //     id: self.editGroupId
      //   },
      //   communityData: {
      //     communityName: currentCommunityName.communityName,
      //     communityID: "b2146af0-15c3-11ea-b55d-bdde97cb4377",
      //     communitiesType: 1,
      //     communitiesSubType: 0
      //   }
      // };

      // const { data } = await groupsRepository.editGroup(obj);
      // console.log(data);

      // if (data) {
      this.usersInGroup = this.usersInGroup.filter(
        (val) => !this.usersListForEditGroup.includes(val)
      );

      let obj = {
        invitationData: {
          groupId: this.editGroupId,
          inviterUserId: this.token.userID,
          ownerName: this.token.username,
          status: this.postingRight,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
          communityID: "b2146af0-15c3-11ea-b55d-bdde97cb4377",
          communitiesType: 1,
          communitiesSubType: 0,
        },
        userData: this.usersInGroup,
      };

      const { data } = await groupsRepository.inviteUserToGroup(obj);

      if (data.ResponseCode == 100) {
        this.$emit("membersAdded", this.uuidInGroup);
        this.isSidebarActiveLocal = false;
        this.groupName = null;
        this.groupDescription = null;
        this.groupType = null;
        this.usersList = false;
      }
      // }
    },
    async saveEditGroupDetails() {
      this.$vs.loading();
      var self = this;
      if (self.newGroupImage != null) {
        self.crop();
      }
      let obj = {
        groupData: {
          groupName: self.groupName,
          groupDescription: self.groupDescription,
          groupIcon: this.newGroupImage,
          typeId: self.groupType,
          status: 1,
          ownerName: self.token.username,
          allTimeGroup: self.postingRight,
          id: self.editGroupId,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
          communityID: "b2146af0-15c3-11ea-b55d-bdde97cb4377",
          communitiesType: 1,
          communitiesSubType: 0,
        },
      };

      const { data } = await groupsRepository.editGroup(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        this.changePostingRightsOfUsers().then(() => {
          this.$parent.groupsLoaded = false;
          this.$parent.active = false;
          this.$emit("groupEdited");
          this.groupName = null;
          this.groupDescription = null;
          this.groupType = null;
          this.usersList = false;
          this.isSidebarActiveLocal = false;
        });
      } else {
        this.$vs.loading.close();
      }
    },
    async changePostingRightsOfUsers() {
      const userIds = this.usersListForEditGroup
        .filter((x) => x.groupOwner == null && x.group_admin_id == null)
        .map((x) => x.usersDetailsId);

      this.changePostingRights(userIds, this.postingRight);
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.newGroupImage = canvas.toDataURL();
      return this.newGroupImage;
    },
    infiniteHandlerMembersForGroup($state) {
      this.showGroupUsersList().then((data) => {
        if (data) {
          this.groupUserPage += 1;
          this.groupUserOffset += this.groupUserLimit;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    async changePostingRights(userId, status) {
      alert("sdFSDFDS");
      let self = this;
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          groupID: self.$store.state.groupId,
          userID: userId,
          status: status,
        },
      };
      await groupsRepository.changePostingRights(payload);
    },
    filterUsers(result) {
      this.searchResults = result;
    },
    async getGroupByName() {
      const regex = /^[a-zA-Z0-9 ]*$/;
      const result = this.groupName.trim().match(regex);
      console.log("result", this.groupName);
      if (result == null) {
        return this.$swal("", "Please Enter Valid Channel Name", "error");
      }
      if (this.groupName.trim() == "") {
        return this.$swal("", "Please Enter Valid Channel Name", "error");
      }
      if (this.groupDescription.trim() == "") {
        return this.$swal("", "Please Enter Valid Description", "error");
      }

      this.$vs.loading();
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        name: this.groupName,
      };
      const { data } = await groupsRepository.getGroupByName(payload);

      if (data.length > 0) {
        this.$vs.loading.close();
        this.groupName = "";
        this.$swal("", "A Channel with this name already exists!", "error");
      } else {
        this.$vs.loading.close();
        this.addParticipants();
      }
    },
    unsupportedFormatAlert() {
      this.$swal({
        icon: "error",
        title: "Unsupported File Format",
      });
    },
    largeFileSizeAlert() {
      this.$swal({
        icon: "error",
        title: "File Size too large",
      });
    },
  },
  mounted() {},
  components: {
    Cropper,
    UserAvatar,
    MemberSearch,
  },

  created() {},
};
</script>
<style>
.con-vs-avatar-primary {
  background: rgba(var(--vs-primary), 1) !important;
}
.pl-13px {
  padding-left: 3.2rem;
}
</style>
