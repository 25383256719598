<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <div class="toolbar">
          <button class="menubar__button" @click="commands.undo">
            <feather-icon
              svgClasses="h-4 w-4"
              icon="RotateCcwIcon"
            ></feather-icon>
          </button>

          <button class="menubar__button" @click="commands.redo">
            <feather-icon
              svgClasses="h-4 w-4"
              icon="RotateCwIcon"
            ></feather-icon>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <feather-icon svgClasses="h-4 w-4" icon="BoldIcon"></feather-icon>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <feather-icon svgClasses="h-4 w-4" icon="ItalicIcon"></feather-icon>
          </button>

          <!-- <button
            class="menubar__button"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
          >
            <icon name="strike" />
          </button> -->

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <feather-icon
              svgClasses="h-4 w-4"
              icon="UnderlineIcon"
            ></feather-icon>
          </button>
          <button
            class="menubar__button"
            @click="showImagePrompt(commands.image)"
          >
            <feather-icon svgClasses="h-4 w-4" icon="ImageIcon"></feather-icon>
          </button>
          <!-- <button
            class="menubar__button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <icon name="paragraph" />
          </button> -->

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >
            H1
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            H2
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            H3
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <feather-icon svgClasses="h-4 w-4" icon="MenuIcon"></feather-icon>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <feather-icon svgClasses="h-4 w-4" icon="ListIcon"></feather-icon>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.blockquote() }"
            @click="commands.blockquote"
          >
            <feather-icon
              svgClasses="h-4 w-4"
              icon="MessageCircleIcon"
            ></feather-icon>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.code_block() }"
            @click="commands.code_block"
          >
            <feather-icon svgClasses="h-4 w-4" icon="CodeIcon"></feather-icon>
          </button>

          <button
            class="menubar__button"
            @click="
              commands.createTable({
                rowsCount: 3,
                colsCount: 3,
                withHeaderRow: false,
              })
            "
          >
            <feather-icon
              svgClasses="h-4 w-4"
              icon="ColumnsIcon"
            ></feather-icon>
          </button>

          <span v-if="isActive.table()">
            <button class="menubar__button" @click="commands.deleteTable">
              <img :src="deleteTableIcon" class="h-4 w-4" />
            </button>
            <button class="menubar__button" @click="commands.addColumnBefore">
              <img :src="addColBeforeIcon" class="h-4 w-4" />
            </button>
            <button class="menubar__button" @click="commands.addColumnAfter">
              <img :src="addColAfterIcon" class="h-4 w-4" />
            </button>
            <button class="menubar__button" @click="commands.deleteColumn">
              <img :src="deleteColIcon" class="h-4 w-4" />
            </button>
            <button class="menubar__button" @click="commands.addRowBefore">
              <img :src="addRowBeforeIcon" class="h-4 w-4" />
            </button>
            <button class="menubar__button" @click="commands.addRowAfter">
              <img :src="addRowAfterIcon" class="h-4 w-4" />
            </button>
            <button class="menubar__button" @click="commands.deleteRow">
              <img :src="deleteRowIcon" class="h-4 w-4" />
            </button>
            <button class="menubar__button" @click="commands.toggleCellMerge">
              <img :src="combineCellsIcon" class="h-4 w-4" />
            </button>
          </span>
        </div>
      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import "./blog.scss";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Strike,
  Underline,
  History,
  Image,
} from "tiptap-extensions";

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Table({
            resizable: true,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new Image(),
        ],
        content: ``,
      }),
      deleteTableIcon: require("./icons/delete_table.svg"),
      addColBeforeIcon: require("./icons/add_col_before.svg"),
      addColAfterIcon: require("./icons/add_col_after.svg"),
      deleteColIcon: require("./icons/delete_col.svg"),
      addRowBeforeIcon: require("./icons/add_row_before.svg"),
      addRowAfterIcon: require("./icons/add_row_after.svg"),
      deleteRowIcon: require("./icons/delete_row.svg"),
      combineCellsIcon: require("./icons/combine_cells.svg"),
    };
  },
  computed: {
    html() {
      if (!this.editor) return "";
      return this.editor.getHTML();
    },
  },
  watch: {
    html(val) {
      this.$emit("html-change", val);
    },
  },
  methods: {
    showImagePrompt(command) {
      const src = prompt("Enter the url of your image here");

      if (src !== null) {
        command({ src });
      }
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
.editor {
  background: #f4f5f6;
  border-radius: 6px;
  padding: 10px;
}
.toolbar {
  margin-bottom: 10px;
}
.menubar__button {
  padding: 6px;
  margin: 2px;
  border: 1px solid #f4f5f6 !important;
  border-radius: 3px;
  cursor: pointer;
  background: #fff;
}
.is-active,
.menubar__button:hover {
  background: #b7b8b8 !important;
}
</style>
