<template>
  <div id="profile-page " class="chat-body-sec">
    <!-- <b-modal
      id="modal-1"
      title="How would you like to promote"
      hide-header
      hide-footer
    >
      <Promotepopup />
    </b-modal> -->
    <add-posts
      :isSidebarActive="addNewPostDataSidebar"
      @closeSidebar="togglePostDataSidebar"
      :data="addPostSidebarData"
      v-if="renderGroupsPageAddPost"
    />
    <modify-group
      :isSidebarActive="openGroupsSidebar"
      @closeSidebar="toggleGroupsDataSidebar"
      @groupCreated="getNewGroup"
      @membersAdded="refreshMembers"
      @groupEdited="refreshGroup"
      :data="groupsSidebarData"
    />
    <ViewPostSidebar
      @closeSidebar="showPopup(false)"
      :show="blurpostopen"
      :groupPost="viewPostSidebarData"
    ></ViewPostSidebar>
    <group-members-sidebar
      :isSidebarActive="openGroupMembersSidebar"
      @closeSidebar="toggleGroupMembersSidebar"
      :data="memberList"
      :isGroupAdmin="isGroupAdmin"
      :isGroupOwner="isGroupOwner"
    />
    <channel-owner-leave-sidebar
      :isSidebarActive="openChannelOwnerLeaveSidebar"
      @closeSidebar="toggleChannelOwnerLeaveSidebar"
      :data="OwnerMemberList"
      :currentGroupId="currentGroupId"
      @ownerLeaveNotification="ownerLeaveNotifications"
    />
    <div class="vx-row" v-show="!blurpostopen">
      <div
        v-if="showFirstSection"
        id="chat-app"
        class="vx-col lg:w-2/12 borderl w-full cust-height Grppage-left-w smp_width no-padd-0 mt-18 card-bg"
      >
        <div
          class="social_page_sidebar_chatheight_fixed smp_chat_width_fixed smp_min_height"
          parent="#chat-app"
          :click-not-close="clickNotClose"
          id="chat-list-sidebar"
        >
          <!-- <user-profile
            :active="activeProfileSidebar"
            :userId="userProfileId"
            class="user-profile-container"
            :isLoggedInUser="isLoggedInUserProfileView"
            @closeProfileSidebar="closeProfileSidebar"
          ></user-profile>-->
          <div class="card-bg">
            <div class v-if="showGroups">
              <div class="chat-top-titleparent">
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="md:inline-flex lg:hidden cursor-pointer back-margin-10 mobview-lefticon-pd"
                  @click="OpenHomePage()"
                />
                <span class="h2-class chat-Top-lbl">Channels</span>
                <div class="chat-topRight-btn">
                  <vs-button
                    v-if="check('admin')"
                    size="small"
                    type="border"
                    icon-pack="feather"
                    icon="icon-plus"
                    class="mob-view-membsBtn text-sm"
                    @click="addEditGroup"
                    >Create</vs-button
                  >
                </div>
              </div>
              <!--   @click="setGroupDetails(group, index)" -->
              <ul class="chat__contacts bordered-items Group_page_chats-scroll">
                <li
                  class="smp_cursor-pointer bord active"
                  v-for="group in groups"
                  :key="group.groupId"
                >
                  <!-- <chat-contact
                    :contact="{
                      id: group.groupId,
                      name: group.group_name,
                      uniquid: group.group_UniqueId,
                      photoURL: group.group_icon,
                      last_message: group.group_description,
                      JOINREQUESTCOUNT: group.JOINREQUESTCOUNT,
                      isGroupAdmin: group.group_admin_id != null ? true : false,
                      requestSent: group.requestsent,
                      isPrivateGroup: group.isPrivateGroup,
                      isPublicGroup: group.isPublicGroup,
                      grouptypeid: group.group_type_id,
                      group_all_time_group: group.group_all_time_group,
                    }"
                  ></chat-contact> -->
                  <div
                    class="chat__contact flex items-center px-2"
                    v-bind:class="{ activate: isGroupSelected }"
                  >
                    <div class="contact__avatar mr-1">
                      <!-- :text="avatarname"
                          :image="photo" -->
                      <vs-avatar
                        class="bg-dark"
                        size="45px"
                        color="rgb(200, 21, 129)"
                        @imageNotFound="group.group_icon = null"
                      />
                    </div>
                    <div
                      class="contact__container w-full items-center justify-between overflow-hidden"
                    >
                      <div class="contact__info flex flex-col truncate w-full">
                        <div class="flex cust-h-20 justify-between">
                          <h5
                            class="h5-class"
                            @click="setGroupDetails(group, index)"
                          >
                            {{ group.group_name }}
                          </h5>
                          <div
                            color
                            v-if="group.isPrivateGroup || group.isPrivateGroup"
                            class="upublic-custchips font-semibold"
                          >
                            <vs-button
                              class="bg-primary dashboard_font_size_text Grp-join-btn"
                              size="small"
                              type="filled"
                              @click="setGroupDetails(group, index)"
                              >Join</vs-button
                            >
                          </div>

                          <div class="flex justify-center items-center">
                            <div>
                              <vs-button
                                radius
                                color="primary"
                                type="flat"
                                icon-pack="feather"
                                icon="icon-award"
                              ></vs-button>
                            </div>
                            <div
                              class="mt-2 cursor-pointer lg:block md:block hidden"
                            >
                              <vs-dropdown vs-trigger-click>
                                <a class="flex items-center">
                                  <i class="material-icons mr-2">expand_more</i>
                                </a>

                                <vs-dropdown-menu class="eddit">
                                  <vs-dropdown-item
                                    @click="
                                      handleeditchannel(group.group_UniqueId)
                                    "
                                    >Delete</vs-dropdown-item
                                  >
                                  <vs-dropdown-item
                                    @click="handleeditchannels(group)"
                                    >Edit</vs-dropdown-item
                                  >
                                </vs-dropdown-menu>
                              </vs-dropdown>
                              <vs-popup
                                class="holamundo lg:mt-12"
                                title="Edit Channel"
                                :active.sync="editPopUp"
                              >
                                <div class="">
                                  <!-- <div class="">
                                      <vs-upload
                     
                    />
                  </div> -->
                                  <div class="vx-col w-full">
                                    <div>
                                      <div
                                        class="img-uploadBox img-uploadvrop-h mg-left-right"
                                      >
                                        <div class>
                                          <div
                                            class="relative"
                                            v-if="photourl != ''"
                                          >
                                            <img
                                              class="setheight w-full"
                                              :src="magittUrl + photourl"
                                              alt=""
                                            />
                                            <div
                                              class="row top-0 absolute right-0"
                                              @click="photourl = ''"
                                            >
                                              <feather-icon
                                                icon="XIcon"
                                                svgClasses="h-6 w-6"
                                                class=""
                                              />
                                            </div>
                                          </div>
                                          <div
                                            class="relative"
                                            v-if="imagepreview != null"
                                          >
                                            <div class="w-full">
                                              <img
                                                class="w-full"
                                                :src="imagepreview"
                                                alt="channel imgage"
                                              />
                                            </div>
                                            <div
                                              class="row top-0 absolute right-0"
                                              @click="
                                                (imagepreview = null),
                                                  (imagefile = null)
                                              "
                                            >
                                              <feather-icon
                                                icon="XIcon"
                                                svgClasses="h-6 w-6"
                                                class=""
                                              />
                                            </div>
                                          </div>

                                          <div
                                            class="w-full relative"
                                            v-if="
                                              imagepreview == null &&
                                              photourl == ''
                                            "
                                          >
                                            <div class="modify-up-childPd">
                                              <div class>
                                                <div class="row">
                                                  <feather-icon
                                                    class="icon feather icon-camera img-upload-centicon"
                                                    icon="icon-camera"
                                                  >
                                                  </feather-icon>
                                                </div>
                                                <input
                                                  @change="uploadPostImages1"
                                                  class="setinput"
                                                  type="file"
                                                  accept="image/*"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <!-- <vs-button
                          icon-pack="feather"
                          class="modify-edit-icon mt-2"
                          onclick="document.getElementById('edit-group-image').click();"
                          type="transparent"
                          radius
                          icon="icon-edit-2"
                          >Change Picture</vs-button
                        > -->
                                      </div>
                                      <div>
                                        <!-- <input
                      id="edit-group-imagessssss"
                      type="file"
                      accept="image/*"
                      class="hidden"
                      @change="uploadPostImages"
                    /> -->
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mt-4 mb-2 px-2">
                                    <vs-input
                                      label="Channel Name"
                                      placeholder="Channel Name"
                                      v-model="groupName"
                                      class="w-full"
                                    />
                                  </div>
                                  <div class="mt-4 mb-2 px-2">
                                    <vs-input
                                      label="Channel Description"
                                      placeholder="Channel Description"
                                      v-model="groupDescription"
                                      class="w-full"
                                    />
                                  </div>
                                  <!-- <div class="w-full ml-0 px-2 mt-4 flex"> -->
                                  <!-- <div class="grp_typ_text mb-2 mr-3">
                      <span class="spce-grp font-medium">Channel type</span>
                    </div> -->

                                  <!-- <div class="ModifyGroup_switch_field">
                      <input
                        type="radio"
                        id="radio-one"
                        value="1"
                        v-model="groupType"
                        checked
                      />
                      <label for="radio-one" class="font-medium">Private</label>

                      <input
                        type="radio"
                        id="radio-three"
                        value="3"
                        v-model="groupType"
                      />
                      <label for="radio-three" class="font-medium"
                        >System</label
                      >
                    </div> -->
                                  <!-- </div> -->
                                  <!-- <div class="w-full ml-0 px-2 mt-4 flex">
                    <div class="grp_typ_text mb-2 mr-3">
                      <span class="spce-grp font-medium">Posting Rights</span>
                    </div>

                    <div class="ModifyGroup_switch_field">
                      <input
                        type="radio"
                        id="radio-p-one"
                        value="0"
                        v-model="postingRight"
                        checked
                      />
                      <label for="radio-p-one" class="font-medium"
                        >Admin Only</label
                      >
                      <input
                        type="radio"
                        id="radio-p-two"
                        value="1"
                        v-model="postingRight"
                      />
                      <label for="radio-p-two" class="font-medium"
                        >Anyone</label
                      >
                    </div>
                  </div> -->

                                  <!-- @click="editgroup" -->
                                  <div class="mt-20">
                                    <vs-button
                                      type="filled"
                                      class="w-full border-rem h2-class"
                                      >SAVE</vs-button
                                    >
                                  </div>
                                </div>
                              </vs-popup>
                            </div>
                            <div class="lg:hidden md:hidden block">
                              <vs-dropdown vs-trigger-click>
                                <vs-button
                                  radius
                                  color="primary"
                                  type="flat"
                                  icon-pack="feather"
                                  icon="icon-more-vertical"
                                ></vs-button>

                                <vs-dropdown-menu class="sm-w-130px">
                                  <vs-dropdown-item
                                    @click="memberSideBar(group)"
                                  >
                                    View Members
                                  </vs-dropdown-item>
                                  <vs-dropdown-item
                                    @click="
                                      handleeditchannel(group.group_UniqueId)
                                    "
                                    >Delete</vs-dropdown-item
                                  >
                                  <vs-dropdown-item
                                    @click="handleeditchannels(group)"
                                    >Edit</vs-dropdown-item
                                  >
                                </vs-dropdown-menu>
                              </vs-dropdown>
                            </div>
                          </div>
                        </div>
                        <span
                          class="pending-usermes h4-class"
                          color="primary"
                          v-if="group.JOINREQUESTCOUNT"
                          >{{ group.JOINREQUESTCOUNT }} Pending Request</span
                        >
                        <span
                          class="pending-usermes h4-class"
                          color="warning"
                          v-else-if="group.requestsent != null"
                          >Request Sent</span
                        >
                        <!-- v-html="group.group_description" -->
                        <span
                          @click="setGroupDetails(group, index)"
                          class="textmute-usermes cust-h-20 h4-class"
                          v-else
                          >{{ group.group_description }}</span
                        >
                      </div>
                      <!-- <p
                        class="chat_text-green chat_user_time"
                        v-if="ticontainerForCurrentUser"
                      >
                        typing...
                      </p>
                      <div
                        class="chat__contact__meta flex self-start flex-col items-end w-1/6"
                      >
                        <p class="textmute-user-time chat_user_time">
                          {{ group.group_modified_date }}
                        </p>
                        <vs-chip
                          class="number"
                          color="primary"
                          v-if="unseenMsg"
                          >{{ unseenMsg }}</vs-chip
                        >
                      </div> -->
                    </div>
                  </div>
                </li>
              </ul>
              <!-- <vs-pagination
                :color="colorx"
                v-show="groupPageCount > 1"
                class="grouppage-leftcard-pg"
                :total="groupPageCount"
                v-model="groupPage"
              ></vs-pagination> -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="showSecondSection" class="Grppage-top-titleparent w-full">
        <div class="flex">
          <feather-icon
            icon="ChevronLeftIcon"
            @click.stop="
              showFirstSection = true;
              showSecondSection = false;
            "
            class="smp_cursor-pointer member_title_backarrow active"
          ></feather-icon>

          <div class="grpusewr-content">
            <div v-show="groupSection" class="w-full">
              <img
                v-if="currentGroupIcon != null"
                :src="magittUrl + currentGroupIcon"
                class="imgRadius-sm mobview-dp-pos"
                @click.stop="Groupsidebar = !Groupsidebar"
                alt="img"
              />
              <img
                v-if="currentGroupIcon == null"
                :src="magittUrl + 'uploads/defaultImages/default_group.png'"
                class="imgRadius-sm mobview-dp-pos"
                @click.stop="Groupsidebar = !Groupsidebar"
                alt="img"
              />
              <span
                class="h2-class grp-user-hidetext mobview-grplable"
                @click.stop="Groupsidebar = !Groupsidebar"
                >{{ currentGroupName }}</span
              >

              <div class="chat-topRight-btn">
                <vs-button
                  size="small"
                  color="light"
                  icon-pack="feather"
                  icon="icon-edit-2"
                  class="ml-3"
                  type="border"
                  style="display: none"
                  @click="editGroup(currentGroupId, false, false)"
                  v-show="
                    (isGroupAdmin || (check('admin') && !currentGroupType)) &&
                    !isUniverseGroup
                  "
                  >Edit</vs-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="showSecondSection"
        class="vx-col w-full borderl lg:w-8/12 Grppage-midl-w cust-height no-padd-0 card-bg"
      >
        <div class="bg-white w-full shadow-md lg:p-4 md:p-4 px-8 py-4">
          <!-- <div class="flex gap-2 items-center">
            <vs-checkbox class=""></vs-checkbox>
            <h3 class="font-semibold text-lg text-dark">Select All</h3>
          </div> -->
          <div class="lg:flex md:flex hidden justify-between">
            <div class="flex gap-2 px-4 py-2 items-center">
              <vs-checkbox class=""></vs-checkbox>
              <h3 class="font-semibold text-lg text-dark mb-0">Select All</h3>
            </div>
            <div v-if="loginUserRole">
              <vs-button size="small" class="px-10 py-3" @click="deleteMultiple"
                >Delete</vs-button
              >
            </div>
          </div>
          <div class="lg:hidden md:hidden flex justify-between">
            <div class="flex gap-2 px-4 py-2 items-center">
              <vs-checkbox class=""></vs-checkbox>
              <h3 class="font-semibold text-lg text-dark mb-0 mt-50">
                Select All
              </h3>
            </div>
            <div v-if="loginUserRole">
              <vs-button size="small" class="px-10 py-3" @click="deleteMultiple"
                >Delete</vs-button
              >
            </div>
          </div>
        </div>
        <div class="bg-fa w-full h-2"></div>
        <div class="Grpchat-postscroll lg:px-0 md:px-0 px-5">
          <!-- <vs-tabs alignment="fixed" class="bg-white smp_tab_border_bottom"> -->
          <!-- <vs-tab label="FEED" class="social_tabs csmp_omment_box">-->
          <!-- <vs-tab label="FEED" class="smp_omment_box"> -->
          <div v-if="!isPrivateGroup">
            <!-- <div class="channel-grpjoin pd-10">
              <div class="flex">
            <h2 class="w-assign">This is a Private Channel</h2>
            <vs-button color="primary" size="small" type="filled">Request to Join</vs-button>
            </div>
            </div>
          <div> -->
            <vx-card
              class="mt-base smp_shadow_grp smp_card_card margin_top_groupspage"
              v-for="(groupPost, index) of posts"
              :key="index"
            >
              <div
                class="flex gap-2 px-4 py-2 items-center"
                v-if="loginUserRole"
              >
                <vs-checkbox
                  class=""
                  :vs-value="groupPost.post_UniqueId"
                  v-model="groupPostDelete"
                ></vs-checkbox>
                <h3 class="font-semibold text-lg text-dark mb-0">Select</h3>
              </div>
              <div class="post-header px-4 flex">
                <div
                  class="lg:flex items-center smp_text_pddd smp_top_10 w-full justify-between"
                >
                  <div class="flex">
                    <div>
                      <UserAvatar
                        :image="groupPost.usersDetails_UserImage"
                        :text="groupPost.usersDetails_UserName"
                        :size="'45px'"
                        :userID="groupPost.usersDetailsId"
                        @imageNotFound="groupPost.usersDetails_UserImage = null"
                      />
                    </div>
                    <div class="ml-4 mt-2">
                      <h6
                        class="h-5-clsbold cursor-pointer fontWeight-600 h4-class"
                        @click="showProfile(groupPost.usersDetailsId)"
                      >
                        {{ groupPost.usersDetails_UserName }}
                      </h6>
                      <span class="textmute-usermes h4-class">
                        {{ groupPost.post_group_mapping_created_datetimestamp }}
                      </span>
                    </div>
                  </div>
                  <div class="flex items-right gap-2 float-r" id="parentx">
                    <!-- <vs-button
                    size="small"
                    class="mr-2"
                    @click="viewSinglePostPage(groupPost)"
                    >CLIENT'S VIEW</vs-button
                     v-if="$acl.check('admin')"
                    >-->

                    <vs-button
                      size="small"
                      color="dark"
                      class="mr-2"
                      @click="viewSinglePostPage(groupPost)"
                      >READER'S VIEW</vs-button
                    >
                    <vs-button
                      color="success"
                      size="small"
                      @click="promotePost(groupPost)"
                      v-if="check('admin')"
                      >PROMOTE</vs-button
                    >
                    <!-- <vs-button color="success" size="small" v-b-modal.modal-1
                      >PROMOTE</vs-button
                    > -->

                    <vs-button
                      size="small"
                      class="rounded-full p-1"
                      v-if="groupPost.is_promoted == 'true'"
                      ><img src="@/Client/Kotak/img/spot.png" class="w-6 h-6"
                    /></vs-button>

                    <vs-dropdown vs-trigger-click v-if="check('admin')">
                      <a class="flex items-center">
                        <i class="material-icons mr-2">expand_more</i>
                      </a>

                      <vs-dropdown-menu>
                        <vs-dropdown-item @click="deletePost(groupPost)"
                          >Delete</vs-dropdown-item
                        >
                        <vs-dropdown-item
                          @click="getPost(groupPost)"
                          v-show="
                            groupPost.audio_url == null ||
                            groupPost.PollQuestion == null
                          "
                          >Edit</vs-dropdown-item
                        >
                      </vs-dropdown-menu>
                    </vs-dropdown>
                    <vs-popup
                      class="holamundo"
                      title="EDIT POST"
                      :active.sync="popupActivo"
                    >
                      <div class="centerx labelx">
                        <!-- <vs-input class="w-full" label="Name" placeholder="" /> -->
                        <div>
                          <label>Name</label>
                          <BlogEditor @html-change="setTextareatitle" />
                        </div>
                        <div>
                          <label>Description</label>
                          <BlogEditor @html-change="setTextareabody" />
                        </div>
                        <vs-select
                          placeholder=""
                          single
                          class="selectExample w-full mt-4"
                          label="Category"
                          v-model="select3"
                        >
                          <vs-select-item
                            :key="index"
                            :value="item.name"
                            :text="item.name"
                            v-for="(item, index) in category[0]"
                          />
                        </vs-select>
                        <vs-select
                          placeholder=""
                          single
                          class="selectExample w-full mt-4"
                          label="Author"
                          v-model="select2"
                        >
                          <vs-select-item
                            :key="index"
                            :value="item.firstName + ' ' + item.lastName"
                            :text="item.firstName + ' ' + item.lastName"
                            v-for="(item, index) in author[0]"
                          />
                        </vs-select>

                        <label>Feature Image</label>

                        <div v-if="imagepreview != null" class="relative">
                          <img
                            class="mediaPost-Image mediaPost-mh relative"
                            :src="imagepreview"
                            alt="user-userupload"
                          />
                          <div
                            class="absolute top-0 right-0 h-5 cursor-poin ter"
                            @click="imagepreview = null"
                          >
                            <feather-icon
                              icon="XIcon"
                              svgClasses="h-6 w-6"
                              class=""
                            />
                          </div>
                        </div>

                        <div
                          v-if="
                            !newFeaimg &&
                            imagepreview == null &&
                            featuredimage != null
                          "
                          class="relative"
                        >
                          <div
                            class="absolute top-2 right-2 h-5 cursor-poin ter"
                            @click="featuredimage = null"
                          >
                            <feather-icon
                              icon="XIcon"
                              svgClasses="h-6 w-6"
                              class=""
                            />
                          </div>
                          <img
                            class="mediaPost-Image mediaPost-mh"
                            :src="magittUrl + featuredimage"
                            @error="
                              featuredimage =
                                magittUrl +
                                'uploads/defaultImages/default_post.png'
                            "
                            alt="user-uploads"
                          />
                        </div>

                        <div class="relative" v-if="newfeaturedimage != null">
                          <div
                            class="absolute top-2 right-2 h-5 cursor-poin ter"
                            @click="newfeaturedimage = null"
                          >
                            <feather-icon
                              icon="XIcon"
                              svgClasses="h-6 w-6"
                              class=""
                            />
                          </div>
                          <img
                            class="mediaPost-Image mediaPost-mh"
                            :src="newfeaturedimage"
                            @error="
                              featuredimage =
                                magittUrl +
                                'uploads/defaultImages/default_post.png'
                            "
                            alt="user-upload"
                          />
                        </div>
                        <div
                          class="relative"
                          v-if="blogimage != null || newblogimage != null"
                        >
                          <div
                            class="absolute top-2 right-2 h-5 cursor-poin ter"
                            @click="blogimage = null"
                          >
                            <feather-icon
                              icon="XIcon"
                              svgClasses="h-6 w-6"
                              class=""
                            />
                          </div>
                          <label>Image</label>
                          <img
                            v-if="!newimg"
                            class="mediaPost-Image mediaPost-mh"
                            :src="magittUrl + blogimage"
                            @error="
                              blogimage =
                                magittUrl +
                                'uploads/defaultImages/default_post.png'
                            "
                            alt="user-upload"
                          />
                          <div class="relative" v-if="newblogimage != null">
                            <div
                              class="absolute top-2 right-2 h-5 cursor-poin ter"
                              @click="newblogimage = null"
                            >
                              <feather-icon
                                icon="XIcon"
                                svgClasses="h-6 w-6"
                                class=""
                              />
                            </div>
                            <img
                              class="mediaPost-Image mediaPost-mh"
                              :src="newblogimage"
                              @error="
                                blogimage =
                                  magittUrl +
                                  'uploads/defaultImages/default_post.png'
                              "
                              alt="user-upload"
                            />
                          </div>
                        </div>
                        <div class="modify-grp-editbtn">
                          <vs-button
                            icon-pack="feather"
                            class="modify-edit-icon"
                            onclick="document.getElementById('image-post-upload').click();"
                            type="transparent"
                            radius
                            icon="icon-edit-2"
                            >Change Picture</vs-button
                          >
                        </div>
                        <div class="vx-col w-full">
                          <input
                            multiple
                            id="image-post-upload"
                            @change="uploadPostImages"
                            type="file"
                            accept="image/*"
                            class="hidden"
                          />
                        </div>
                        <div
                          class
                          v-if="blogVideo != null || newblogVideo != null"
                        >
                          <label>Video</label>
                          <video
                            v-if="!newvideo"
                            ref="player"
                            controls
                            class="media-video-player mediaPost-mh w-30-rem"
                          >
                            <source :src="magittUrl + blogVideo" />
                          </video>
                          <video
                            v-else
                            ref="player"
                            controls
                            class="media-video-player mediaPost-mh w-30-rem"
                          >
                            <source :src="newblogVideo" />
                          </video>
                        </div>
                        <div class v-if="documentFile != null">
                          <DownloadDocuments :path="documentFile" />
                        </div>
                        <!-- <Cropper
                    class="w-full-grp img-uploadBox-cropper"
                    :src="featuredimage"
                    ref="blogcropper"
                    :imageRestriction="'none'"
                    :stencilProps="stencilPropsImagePost"
                  /> -->
                        <!-- <vs-upload
                          action="https://jsonplaceholder.typicode.com/posts/"
                          text="Drop file to upload"
                          @on-success="successUpload"
                        /> -->
                        <UploadDocuments
                          :onSuccess="loadDocumentData"
                          :file="documentFile"
                          @imagepost="uploadPostImage"
                          @videopost="uploadPostVideo"
                          @uploadFeatureImage="setFeatureImage"
                        />

                        <!-- <vs-select
                          placeholder=""
                          multiple
                          class="selectExample w-full mt-4"
                          label="Choose Channel"
                          v-model="select4"
                        >
                          <vs-select-item
                            :key="index"
                            :value="item.value"
                            :text="item.text"
                            v-for="(item, index) in options1"
                          />
                        </vs-select> -->
                        <!-- <div class="grid grid-cols-3 gap-2 mt-4">
                          <div class="cursor-pointer">
                            <div class="rounded w-full bg-light p-1">
                              <div class="h-24">
                                <div class="flex self-center justify-center">
                                  <img
                                    class="h-16"
                                    alt="sss"
                                    src="@/Core/assets/images/pages/post/PDF_file_icon.svg"
                                  />
                                </div>
                                <p
                                  class="text-center fsize12 font-medium text-primary"
                                >
                                  Image Post
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="cursor-pointer">
                            <div class="rounded w-full bg-light p-1">
                              <div class="h-24">
                                <div class="flex self-center justify-center">
                                  <img
                                    class="h-16"
                                    alt="sss"
                                    src="@/Core/assets/images/pages/post/PDF_file_icon.svg"
                                  />
                                </div>
                                <p
                                  class="text-center fsize12 font-medium text-primary"
                                >
                                  Video Post
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="cursor-pointer">
                            <div class="rounded w-full bg-light p-1">
                              <div class="h-24">
                                <div class="flex self-center justify-center">
                                  <img
                                    src="@/Core/assets/images/pages/post/PDF_file_icon.svg"
                                    class="h-16"
                                    alt="sss"
                                  />
                                </div>
                                <p
                                  class="text-center fsize12 font-medium text-primary"
                                >
                                  Upload PDF
                                </p>
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <vs-button
                          color="primary"
                          type="filled"
                          class="mt-4 w-full"
                          @click="editBlogPost()"
                          >Save</vs-button
                        >
                      </div>
                    </vs-popup>
                  </div>
                </div>
              </div>
              <div class="px-10">
                <PollView
                  v-show="groupPost.PollQuestionId"
                  :votedIds="groupPost.VotedIds"
                  :PostId="groupPost.post_group_mapping_post_id"
                  :PollId="groupPost.PollQuestionId"
                  :Ids="groupPost.AnswersIds"
                  :Question="groupPost.PollQuestion"
                  :Answers="groupPost.PollAnswers"
                  :AnswersVotes="groupPost.AnswersVotes"
                />
                <p
                  v-show="groupPost.text != null"
                  v-html="groupPost.text"
                  class="h3-class"
                ></p>
                <p
                  v-if="groupPost.imageText"
                  v-html="groupPost.imageText"
                  class="Grp-post-content"
                ></p>
                <p
                  v-if="groupPost.videoText"
                  v-html="groupPost.videoText"
                  class="Grp-post-content"
                ></p>
                <p
                  v-if="groupPost.audioText"
                  v-html="groupPost.audioText"
                  class="Grp-post-content"
                ></p>
                <p
                  v-if="groupPost.blogText"
                  v-html="groupPost.blogText"
                  class="Grp-post-content"
                ></p>
              </div>
              <div
                class="post-media-container"
                @click="
                  viewSinglePost(groupPost);
                  showPopup(true);
                "
              >
                <!-- <video-player
                  ref="aplayer"
                  class
                  :options="playerOptionsYoutube(groupPost)"
                  v-if="
                    groupPost.url != null &&
                      (groupPost.url.includes('youtube') ||
                        groupPost.url.includes('youtu.be'))
                  "
                  v-once
                />-->
                <div id="app" v-if="groupPost.url != null">
                  <link-prevue
                    :url="groupPost.url"
                    v-if="groupPost.url != null"
                  >
                    <template slot="loading">
                      <!-- set your custom loading -->
                    </template>
                    <template slot-scope="props">
                      <div class="card-block pd-10">
                        <h5 class="card-title">{{ props.title }}</h5>
                        <p class="card-text">{{ props.description }}</p>
                        <a
                          target="_blank"
                          v-bind:href="props.url"
                          class="btn btn-primary more-primarycolr"
                          >More</a
                        >
                      </div>
                      <img
                        class="mediaPost-Image mediaPost-mh"
                        :src="
                          props.img
                            ? props.img
                            : (props.img =
                                magittUrl +
                                'uploads/defaultImages/default_post.png')
                        "
                        :alt="props.title"
                      />
                    </template>
                  </link-prevue>
                </div>
                <img
                  class="mediaPost-Image mediaPost-mh"
                  :src="groupPost.image"
                  @error="
                    groupPost.image =
                      magittUrl + 'uploads/defaultImages/default_post.png'
                  "
                  alt="user-upload"
                  v-if="groupPost.image != null"
                />
                <img
                  class="mediaPost-Image mediaPost-mh"
                  :src="groupPost.featured_image"
                  @error="
                    groupPost.featured_image =
                      magittUrl + 'uploads/defaultImages/default_post.png'
                  "
                  alt="user-upload"
                  v-if="groupPost.featured_image != null"
                />
                <video-player
                  ref="player"
                  class
                  :options="playerOptions(groupPost)"
                  v-else-if="groupPost.video_url != null"
                  v-once
                />

                <audio
                  class="ml-3 mt-3 mb-3"
                  controls
                  v-else-if="groupPost.audio_url != null"
                >
                  <source :src="groupPost.audio_url" />
                </audio>

                <!-- <video-player
                  ref="aplayer"
                  class
                  :options="playerOptionsAudio(groupPost)"
                  v-else-if="groupPost.audio_url != null"
                  v-once
                  @click.stop="viewSinglePost(groupPost)"
                /> -->
              </div>
              <!-- <div
                style="height: 100%;"
                class="pd-10 cust-posttextfeed"
                v-show="groupPost.body != null"
                v-html="groupPost.body"
              ></div>-->
              <div
                class="flex items-center justify-between grp-page-postbtn mt-3 mx-3"
              >
                <!-- <vs-button
                  v-show="groupPost.body != null"
                  size="small"
                  class=""
                  @click="viewSinglePostPage(groupPost)"
                  >Read More</vs-button
                > -->
              </div>
              <div class="cust-addborder">
                <div class="flex justify-between pt-1 px-4 pb-1">
                  <div class="post-likeheart">
                    <!-- <main>
                      <input class="l" type="checkbox" />
                    </main>-->
                    <feather-icon
                      ref="loadableButton"
                      :id="'like-' + groupPost.post_UniqueId"
                      class="mr-2 groups_like_icon"
                      icon="HeartIcon"
                      :svgClasses="{
                        'text-danger fill-current stroke-current':
                          groupPost.is_active,
                      }"
                      @click.stop="
                        likePost(
                          groupPost.is_active,
                          groupPost.postId,
                          groupPost.likeId,
                          groupPost.post_user_id,
                          groupPost.post_UniqueId,
                          groupPost.usersDetails_UniqueId,
                          'like-' + groupPost.post_UniqueId
                        )
                      "
                    ></feather-icon>
                    <span
                      class="likes-fonts text-center"
                      v-if="
                        groupPost.LIKECOUNT == null || groupPost.LIKECOUNT == 0
                      "
                      >Like</span
                    >
                    <span
                      class="likes-fonts post-liketick"
                      v-else-if="groupPost.LIKECOUNT == 1"
                      >{{ groupPost.LIKECOUNT }} Like</span
                    >
                    <span class="likes-fonts" v-else
                      >{{ groupPost.LIKECOUNT }} Likes</span
                    >
                  </div>

                  <!-- <div class="flex justify-left smp_mt_40 ">
                    <div class="flex items-center likes-com-clss p-3">
                      <span
                        v-if="
                          groupPost.comment_count == null ||
                            groupPost.comment_count == 0
                        "
                        >No Comments</span
                      >
                      <span v-else-if="groupPost.comment_count == 1"
                        >{{ groupPost.comment_count }} Comment</span
                      >
                      <span v-else>{{ groupPost.comment_count }} Comments</span>
                      <span></span>
                    </div>
                  </div> -->
                </div>
              </div>
              <post-comment :post="groupPost" />

              <div class="smp_post_comment smp_socl_mdl_cm groupspage_text">
                <div>
                  <UserAvatar
                    :image="$store.state.user.userImage"
                    :text="$store.state.user.username"
                    :userID="$store.state.user.userID"
                    :size="'35px'"
                    @imageNotFound="$store.dispatch('storeUserImage', null)"
                  />
                </div>
                <vs-input
                  class="smp_plce_comment smp_cmnt_border smp_social_page_mg_btt ml-2"
                  placeholder="Write a commments......"
                  vs-placeholder="Nombre"
                  v-model="groupPost.newComment"
                  @keydown.enter="
                    addComment(
                      groupPost.post_UniqueId,
                      groupPost.newComment,
                      groupPost.usersDetails_UniqueId
                    )
                  "
                />
                <div class="send_icon_setting">
                  <a v-if="groupPost.newComment != ''" class>
                    <i
                      class="feather icon-send feather-20 text-primary"
                      icon="icon-send"
                      @click="
                        addComment(
                          groupPost.post_UniqueId,
                          groupPost.newComment,
                          groupPost.usersDetails_UniqueId
                        )
                      "
                    ></i>
                  </a>
                </div>
              </div>
            </vx-card>
            <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler">
              <div slot="no-more">
                <img
                  class="img-smp_responsive smp_rounded smp_respons_img_height smp_post_imgs"
                  :src="magittUrl + 'uploads/defaultImages/no_posts.svg'"
                />
              </div>
              <div slot="no-results">
                <img
                  class="img-smp_responsive smp_rounded smp_respons_img_height smp_post_imgs"
                  :src="magittUrl + 'uploads/defaultImages/no_posts.svg'"
                />
              </div>
            </infinite-loading>
          </div>
          <div v-if="isPrivateGroup"></div>
        </div>
      </div>
      <div
        v-show="isHidden2"
        class="vx-col w-full lg:w-2/12 smp_mt_base_scrollbar Grppage-right-w no-padd-0 smp_webview_info cust-height"
      >
        <vx-card class="smp_card_body smp_social_myPaddingTest smp_min_height">
          <div>
            <div>
              <div class v-show="groupSection">
                <vx-card
                  class="smp_card_body smp_shadow_grp smp_social_pss_sam"
                >
                  <div class="smp_movier_section">
                    <div class="social_section social_section_bottom_border">
                      <div class="diss-about-sec">
                        <!-- <div class="chat-top-titleparent flex">
                          <span class="h2-class chat-Top-lbl w-full">{{
                            currentGroupName
                          }}</span>
                          <div class="flex">
                            <div class="chat-topRight-btn">
                              <vs-dropdown
                                v-show="!isUniverseGroup && !isSystemGroup"
                              >
                                <a class="flex items-center" href="#">
                                  <i class="material-icons">expand_more</i>
                                </a>

                                <vs-dropdown-menu>
                                  <vs-dropdown-item>
                                    <div
                                      class
                                      @click="leaveGroupAlert()"
                                      v-show="
                                        !isUniverseGroup && !isSystemGroup
                                      "
                                    >
                                      <span>Leave</span>
                                    </div>
                                  </vs-dropdown-item>

                                  <vs-dropdown-item>
                                    <div
                                      @click="
                                        editGroup(currentGroupId, false, false)
                                      "
                                      v-show="
                                        (isGroupAdmin ||
                                          (check('admin') &&
                                            !currentGroupType)) &&
                                        !isUniverseGroup
                                      "
                                    >
                                      <span>Edit</span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vs-dropdown-item>
                                    <div
                                      @click="deleteChannel()"
                                      v-show="
                                        (isGroupAdmin ||
                                          (check('admin') &&
                                            !currentGroupType)) &&
                                        !isUniverseGroup
                                      "
                                    >
                                      <span>Delete</span>
                                    </div>
                                  </vs-dropdown-item>
                                </vs-dropdown-menu>
                              </vs-dropdown>
                            </div>

                            <div class="chat-topRight-btn"></div>
                          </div>
                        </div> -->
                        <div class="mem-show-scrl-r cust-marginbottom10">
                          <!-- <div class="parallax_top_bar_fix">
                            <img
                              v-if="currentGroupIcon != null"
                              :src="magittUrl + currentGroupIcon"
                              class="img-smp_responsive rightchannel-card-av"
                              @error="
                                currentGroupIcon =
                                  'uploads/defaultImages/default_group.png'
                              "
                            />
                            <vs-avatar
                              v-if="currentGroupIcon == null"
                              :src="null"
                              :text="currentGroupName"
                              class="img-smp_responsive rightchannel-card-av bg-primary"
                            />
                          </div> -->
                          <!-- <div class="parallax"></div> -->
                          <!--<img v-if="currentGroupIcon != null" :src="magittUrl+currentGroupIcon" class="img-smp_responsive smp_full_img pallax_img_img_top">-->
                          <!--<img v-if="currentGroupIcon == null" :src="magittUrl+'uploads/defaultImages/default_group.png'" class="img-smp_responsive smp_full_img" />-->

                          <!-- <div
                            class="chat_text-mute_grey h4-class w-full grouppage_solid_id float_left"
                          >
                            <p>{{ currentGroupDescription }}</p>
                          </div> -->

                          <div
                            class="post-media-container grp-right-member-ti smp_mt_4"
                          >
                            <div
                              class="panel"
                              v-if="groupRequestList.length > 0"
                            >
                              <div class="panel-body smp_panel_panel">
                                <label
                                  class="smp_text_members h2-class smp_social_img_padding"
                                  >Members Request</label
                                >
                                <ul class="media-list h3-class">
                                  <div class="vx-row h3-class">
                                    <li
                                      class="media w-full p-2 height-fix-membreq"
                                      v-for="requester of groupRequestList"
                                      :key="requester.userDetailsId"
                                    >
                                      <div class="media-left w-full">
                                        <div class="flex">
                                          <div class="cust-mg-5">
                                            <UserAvatar
                                              :image="
                                                requester.usersDetails_UserImage
                                              "
                                              :text="
                                                requester.usersDetails_UserName
                                              "
                                              size="35px"
                                              @imageNotFound="
                                                requester.usersDetails_UserImage =
                                                  null
                                              "
                                            />
                                          </div>
                                          <div
                                            class="bg-grid-color-secondary h-12"
                                          >
                                            <span
                                              class="user-text-md ml-3 mt-1"
                                            >
                                              {{
                                                requester.usersDetails_UserName
                                              }}
                                            </span>
                                            <p
                                              class="textmute-usermes h4-class ml-3"
                                            >
                                              {{
                                                requester.usersDetails_TagLine
                                              }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="bg-grid-color h-12">
                                        <div class="smp_pull-right">
                                          <span
                                            class="mr-2"
                                            @click="
                                              joinRequestResponse(
                                                2,
                                                requester.group_join_requestsId,
                                                requester.usersDetailsId
                                              )
                                            "
                                          >
                                            <img
                                              src="@/Core/assets/images/pages/carousel/accept_img.png"
                                              class="smp_img_height"
                                            />
                                          </span>

                                          <span
                                            class="mr-2"
                                            @click="
                                              joinRequestResponse(
                                                0,
                                                requester.group_join_requestsId,
                                                requester.usersDetailsId
                                              )
                                            "
                                          >
                                            <img
                                              src="@/Core/assets/images/pages/carousel/rect_img.png"
                                              class="smp_img_height"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </div>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <!-- POST ACTION DATA -->
                          <div class v-if="memberList.length > 0">
                            <div class="w-full flex justify-between">
                              <label class="h2-class p-3">Members</label>
                              <div>
                                <vs-button
                                  type="border"
                                  size="small"
                                  class="mr-3 mt-2 text-sm"
                                  @click="editGroup(currentGroupId, true, true)"
                                  v-show="
                                    (isGroupAdmin ||
                                      (check('admin') && !currentGroupType)) &&
                                    !isUniverseGroup
                                  "
                                  >Add Members</vs-button
                                >
                              </div>
                            </div>
                            <div class="flex h3-class pl-0 mt-4">
                              <ul class="media-list w-full">
                                <li
                                  class="media cursor-pointer px-2"
                                  v-for="member of memberList"
                                  :key="member.usersDetailsId"
                                  @click.stop="setUser(member)"
                                >
                                  <div class="media-left cust-mg-5">
                                    <UserAvatar
                                      :image="member.usersDetails_UserImage"
                                      :text="member.usersDetails_UserName"
                                      :size="'35px'"
                                      @imageNotFound="
                                        member.usersDetails_UserImage = null
                                      "
                                    />
                                  </div>

                                  <div
                                    class="row media-body ml-3 mt-3 w-full flex items-center gap-4"
                                  >
                                    <div
                                      color
                                      v-show="member.group_admin_id"
                                      class="user-admn-btn float-rht"
                                    >
                                      <h5 class="h4-class font-semibold">
                                        Admin
                                      </h5>
                                    </div>
                                    <span class="user-text-md">
                                      {{ member.usersDetails_UserName }}
                                      <!-- <span v-show="member.group_admin_id" class="media-annotation smp_pull-right border-btn diss_admin smp_Admin_btn">Discussion Admin</span>
                                <span v-show="member.group_admin_id == null && member.usersDetailsId != token.userID" class=" border-btn sm_btn smp_btn_reject ml-5" @click="removeUser(member.userDetailsId)">Remove</span>
                                      <span v-show="member.group_admin_id == null && member.usersDetailsId != token.userID" class=" border-btn sm_btn btn-success ml-5" @click="removeUser(member.userDetailsId)">Make Group Admin</span>-->
                                    </span>

                                    <p class="textmute-usermes h4-class m-0">
                                      {{ member.usersDetails_TagLine }}
                                    </p>
                                  </div>

                                  <div class="smp_social_prof_sett">
                                    <a class="smp_social_my_btn h1-class">
                                      <!-- <i class="icon feather smp_icon_settings icon feather-20 icon-settings" icon="icon-settings" @click="popupActive1=true; setUser(member.usersDetailsId); notAdmin = (member.group_admin_id == null) ? true : false;"></i> -->
                                    </a>
                                  </div>
                                </li>
                              </ul>
                              <!-- <div class="box">
                          <a class="button" ><i class="icon feather smp_icon_settings icon feather-20 text-dark" icon="smp_icon_settings" @click="popupActive=true"></i></a>
                              </div>-->
                            </div>
                          </div>
                        </div>
                        <div class="custbottom-fixbtn flex">
                          <vs-button
                            type="filled"
                            color="dark"
                            class="w-full cust-rembord font-semibold w-1/2"
                            @click="viewAllMembers()"
                            v-if="memberList.length > 5"
                            >VIEW MEMBERS</vs-button
                          >

                          <vs-button
                            color="success"
                            type="filled"
                            class="w-full cust-rembord font-semibold w-1/2"
                            @click="downloadMemberDetail()"
                            >Download</vs-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </vx-card>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <vs-sidebar
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="sidebarx"
      spacer
      v-model="Groupsidebar"
    >
      <vs-row
        vs-w="12"
        vs-align="flex-end"
        vs-type="flex"
        class="bg-primary top_bar text-white abs-pos"
      >
        <vs-col vs-type="flex" vs-align="flex-end">
          <div class="flex px-4 add_post text-light bg-primary">
            <feather-icon
              icon="ChevronLeftIcon"
              @click.stop="
                Groupsidebar = !Groupsidebar;
                showFirstSection = true;
              "
              class="cursor-pointer h2-class"
            ></feather-icon>
            <vs-button class="h2-class h3-upp-class">
              {{ currentGroupName }}
            </vs-button>
          </div>
        </vs-col>
      </vs-row>
      <div class v-show="groupSection">
        <div
          class="about-content chat_text-mute_grey member_mobnile_viewdescription h4-class smp_social_img_padding"
        >
          <p>{{ currentGroupDescription }}</p>
        </div>
        <div class="member_mobile_view_parent">
          <vs-button
            size="small"
            type="border"
            class="smp_invite_text_size"
            @click="editGroup(currentGroupId, false, false)"
            v-show="
              (isGroupAdmin || (check('admin') && !currentGroupType)) &&
              !isUniverseGroup
            "
            >EDIT</vs-button
          >
        </div>
        <div
          class="post-media-container mb-6 smp_mt_4"
          v-if="(isGroupAdmin || check('admin')) && !currentGroupType"
        >
          <div class="panel" v-if="groupRequestList.length > 0">
            <div class="panel-body smp_panel_panel">
              <label
                class="smp_text_members h2-class mb-6 smp_social_img_padding"
                >Members Request</label
              >
              <ul class="media-list h3-class">
                <div class="vx-row h3-class">
                  <li
                    class="media"
                    v-for="requester of groupRequestList"
                    :key="requester.userDetailsId"
                  >
                    <div class="media-left">
                      <img
                        v-if="requester.usersDetails_UserImage != null"
                        :src="magittUrl + requester.usersDetails_UserImage"
                        class="imgRadius-md"
                        alt
                      />
                      <img
                        v-if="requester.usersDetails_UserImage == null"
                        :src="magittUrl + requester.usersDetails_UserImage"
                        class="imgRadius-md"
                        alt
                      />
                    </div>
                    <div class="flex">
                      <div
                        class="media-body smp_media_middle smp_member_request_social_page"
                      >
                        <div class="bg-grid-color-secondary h-12">
                          <span class="user-text-md ml-3 mt-1">
                            {{ requester.usersDetails_UserName }}
                          </span>
                          <p class="textmute-usermes h4-class">
                            {{ requester.usersDetails_TagLine }}
                          </p>
                        </div>
                        <div class="bg-grid-color h-12">
                          <div class="smp_pull-right mr-4">
                            <span class="mr-2">
                              <img
                                src="@/Core/assets/images/pages/carousel/accept_img.png"
                                class="smp_img_height"
                              />
                            </span>
                            <span class="mr-2">
                              <img
                                src="@/Core/assets/images/pages/carousel/rect_img.png"
                                class="smp_img_height"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="panel member_panel_text" v-if="memberList.length > 0">
          <div class="flex justify-center items-center py-2">
            <label class="smp_text_members h2-class mb-4 smp_social_img_padding"
              >Members</label
            >
            <vs-button
              type="border"
              size="small"
              class="mr-3 mt-2 text-sm"
              @click="editGroup(currentGroupId, true, true)"
              v-show="
                (isGroupAdmin || (check('admin') && !currentGroupType)) &&
                !isUniverseGroup
              "
              >Add Members</vs-button
            >
          </div>
          <div
            class="panel-body smp_panel_two smp_new_panel h3-class mb-4 pl-0"
          >
            <ul class="media-list w-full">
              <!-- <li class="media" v-for="member of memberList" :key="member.usersDetailsId" @click="popupsetting=true"> -->
              <li
                class="media"
                v-for="member of memberList"
                :key="member.usersDetailsId"
                @click="
                  active_sidebar = !active_sidebar;
                  Groupsidebar = !Groupsidebar;
                "
              >
                <!-- <div class="smp_groups_setting_btn">
                  <vs-button color="primary" type="line" class="remove_focus_color_on_settingtn" icon-pack="feather" icon="icon-settings" ></vs-button>
                </div>-->
                <vs-chip
                  color
                  v-show="member.group_admin_id"
                  class="chip_text chip_class_groupadmin"
                >
                  <h5 class="h4-class discussion_slider_user_bold">Admin</h5>
                </vs-chip>
                <div class="media-left">
                  <UserAvatar
                    :image="member.usersDetails_UserImage"
                    :text="member.usersDetails_UserName"
                    @imageNotFound="member.usersDetails_UserImage = null"
                  />
                </div>
                <div class="row media-body ml-3">
                  <span class="smp_new_text my_text h4-class soc-wh-nowrap">
                    {{ member.usersDetails_UserName }}
                  </span>
                  <p class="smp_display_block h3-class">
                    {{ member.usersDetails_TagLine }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </vs-sidebar>
    <div id="parentx-demo-5">
      <!-- <vs-sidebar
        position-right
        parent="body"
        default-index="1"
        color="primary"
        class="sidebarx"
        spacer
        v-model="group_page_post_sidebar_active"
      >
        <div class="flex px-4 add_post text-light bg-primary Modifygroup_topbar">
          <feather-icon
            icon="ChevronLeftIcon"
            @click.stop="group_page_post_sidebar_active = false"
            class="cursor-pointer"
          ></feather-icon>
          <vs-button class="h2-class h3-upp-class">GROUPS</vs-button>
        </div>
        <img
          :src="postpopupImage"
          alt="banner"
          class="responsive responsive Carsouel_trending_height popup_img"
          @error="
            postpopupImage =
              magittUrl + 'uploads/defaultImages/default_post.png'
          "
        />
      </vs-sidebar>-->
    </div>
    <vs-popup class="holamundo" title="Options" :active.sync="popupActive">
      <div class="smp_social_modal_width">
        <div class="pop-up">
          <div class="row">
            <p v-show="!notAdmin || selectedUser == token.userID">No Options</p>
            <!-- <button class="smp_btn_add col-md-2">Member Admin</button> -->
          </div>
          <div class="row">
            <button
              v-show="notAdmin && selectedUser != token.userID"
              class="smp_btn_add col-md-2 cursor-pointer"
              @click="removeUser(selectedUser)"
            >
              Remove Member
            </button>
          </div>
          <div class="row">
            <button
              v-show="notAdmin && selectedUser != token.userID"
              class="smp_btn_add col-md-2 cursor-pointer"
              @click="makeGroupAdmin(selectedUser, selecteduuid)"
            >
              Make Channel Admin
            </button>
          </div>
          <div class="row">
            <button
              v-show="!notAdmin && selectedUser != token.userID && isGroupOwner"
              class="smp_btn_add col-md-2 cursor-pointer"
              @click="removeGroupAdmin(selectedUser, selecteduuid)"
            >
              Remove Channel Admin
            </button>
          </div>
        </div>
      </div>
    </vs-popup>
    <vs-sidebar
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="sidebarx"
      spacer
      v-model="active_sidebar"
    >
      <div class="groupage_setting_header h2-class">
        <div class="group_page_topbar">
          <feather-icon
            class="md:inline-flex discussion_title_backarrowpparent smp_cursor-pointer"
            icon="ChevronLeftIcon"
            style
            @click="
              active_sidebar = !active_sidebar;
              Groupsidebar = !Groupsidebar;
            "
          />
          <span class="group_Page_setting h2-class">SETTINGS</span>
        </div>
      </div>

      <ul class="holamundo group_page_sidebar" title="Settings">
        <li
          v-if="
            notAdmin &&
            selectedUser != token.userID &&
            !isUniverseGroup &&
            isGroupAdmin
          "
          color="primary"
          type="flat"
          class="w-full add_margin_bottom group_page_text_align cursor-pointer"
          @click="removeUser(selectedUser)"
        >
          <span class="padding-10 font-semibold h5-class chat_slider_user_bold"
            >Remove Member</span
          >
        </li>
        <li
          v-if="
            notAdmin &&
            selectedUser != token.userID &&
            isGroupAdmin &&
            !isReader
          "
          color="primary"
          type="flat"
          class="w-full add_margin_bottom group_page_text_align cursor-pointer"
          @click="makeGroupAdmin(selectedUser, selecteduuid)"
        >
          <span class="padding-10 font-semibold h5-class chat_slider_user_bold"
            >Make Channel Admin</span
          >
        </li>
        <li
          v-if="
            !notAdmin &&
            selectedUser != token.userID &&
            isGroupOwner &&
            !isReader
          "
          color="primary"
          style
          type="flat"
          class="w-full add_margin_bottom group_page_text_align cursor-pointer"
          @click="removeGroupAdmin(selectedUser, selecteduuid)"
        >
          <span class="padding-10 font-semibold h5-class chat_slider_user_bold"
            >Remove Channel Admin</span
          >
        </li>

        <div class="flex">
          <li
            color="primary"
            type="flat"
            class="w-full add_margin_bottom group_page_text_align smp_cursor-pointer w-1/2"
            v-if="selectedUser != token.userID"
            @click="showProfile(selectedUser)"
          >
            <span
              class="padding-10 font-semibold h5-class chat_slider_user_bold"
              >View Member</span
            >
          </li>
        </div>

        <!-- <li
          color="primary"
          type="flat"
          class="w-full add_margin_bottom group_page_text_align smp_cursor-pointer"
          v-if="selectedUser != token.userID"
        >
          <span class="padding-10 font-semibold h5-class chat_slider_user_bold"
            >Message</span
          >
        </li> -->
        <!-- <li
          color="primary"
          type="flat"
          class="w-full add_margin_bottom group_page_text_align smp_cursor-pointer"
          v-if="!isUniverseGroup"
        >
          <span class="padding-10 font-semibold h5-class chat_slider_user_bold"
            >Exit Group</span
          >
        </li> -->
      </ul>
    </vs-sidebar>
  </div>
</template>

<script>
import ChatContact from "./GroupChat/GroupChatContact.vue";
//import UserProfile from "./UserProfile.vue";
//import axios from "../../axios.js"
import { Cropper } from "vue-advanced-cropper";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import jwt from "jsonwebtoken";
import UploadDocuments from "@/Core/Views/CommonPlugins/documents/UploadDocuments";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import * as moment from "moment";

import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const groupsRepository = RepositoryFactory.get("groups");
const postsRepository = RepositoryFactory.get("posts");
const notificationRepository = RepositoryFactory.get("notifications");
const profileRepository = RepositoryFactory.get("profile");
// const promotionsRepository = RepositoryFactory.get("promotions");
// import { ClientRepositoryFactory } from "@/Client/Kotak/kotakapicall/ClientRepositoryFactory";
// const EmailSMSRepository = ClientRepositoryFactory.get("emailsms");
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import AddPosts from "../AddPost/AddPosts";
import ModifyGroup from "./ModifyGroup.vue";
import PollView from "./components/PollView";
import "@/Core/Views/Commoncss/componentCss/GroupPage.css";
import LinkPrevue from "link-prevue";
require("videojs-youtube");
import DownloadDocuments from "@/Core/Views/CommonPlugins/documents/DownloadDocuments";
import { TwemojiTextarea } from "@kevinfaguiar/vue-twemoji-picker";
import EmojiAllData from "@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json";
import EmojiGroups from "@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json";
import ViewPostSidebar from "./ViewPostSidebar.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import GroupMembersSidebar from "./components/GroupMembersSidebar.vue";
import PostComment from "./components/PostComment.vue";
import vSelect from "vue-select";
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
import notificationsMixin from "@/Core/mixins/notificationsMixin";
import ChannelOwnerLeaveSidebar from "./components/ChannelOwnerLeaveSidebar";
// import * as emailer from "@/Client/Kotak/emailer/marketUpdateEmailer.js";
import { mapState } from "vuex";
import BlogEditor from "@/Core/Views/pages/AddPost/BlogEditor";
import axios from "axios";
import successPostMixin from "@/Core/mixins/successPostMixin";
import Promotepopup from "./components/Promotepopup.vue";

//
import "@/Core/Views/Commoncss/componentCss/MembersPage.css";

export default {
  // mixins: [connectymixin],
  mixins: [notificationsMixin, successPostMixin],
  data() {
    return {
      imagepreview: null,
      selectedGroupDisplay: {},
      imagefile: null,
      newvideo: false,
      newimg: false,
      newFeaimg: false,
      stencilPropsImagePost: {},
      editpostId: "",
      editpost_UniqueId: null,
      featuredimage: null,
      blogimage: null,
      blogVideo: null,
      newfeaturedimage: null,
      newblogimage: null,
      newblogVideo: null,
      category: [],
      author: [],
      textarea: "",
      setTextarea1: null,
      setTextarea2: null,
      select2Normal: "",
      post_attachedDocument: null,
      documentFile: null,
      newdocumentFile: null,
      select2: [],
      select3: [],
      select4: [],
      options1: [
        { text: "IT", value: 0 },
        { text: "Blade Runner", value: 2 },
        { text: "Thor Ragnarok", value: 3 },
      ],
      popupActivo: false,
      editPopUp: false,
      activeSidebar: false,
      colorx: "#114A8D",
      colorLoading: this.$vs.theme.primary,
      select1: "",
      LeaveGroup: false,
      selectedVote: [],
      renderGroupsPageAddPost: false,
      group_page_post_sidebar_active: false,
      popupActive_post: false,
      postPopupImage: "",
      active_sidebar: false,
      activate_gr: 0,
      chatLog: [],
      chatLogPS: [],
      token: null,
      popupActive: false,
      popupActive1: false,
      popupsetting: false,
      isHidden: true,
      Groupsidebar: false,
      isHidden2: true,
      isHidden3: true,
      currentGroupId: null,
      currentGroupName: null,
      currentGroupIcon: null,
      currentGroupType: null,
      currentGroupDescription: null,
      currentGroupPostingType: null,
      memberList: [],
      groupRequestListArray: [],
      groupPosts: [],
      postsPage: 1,
      postsLimit: 30,
      postsOffset: 0,
      selectedUser: null,
      selecteduuid: null,
      notAdmin: null,
      isGroupOwner: null,
      isGroupAdmin: false,
      isUniverseGroup: false,
      viewAll: false,
      addNewPostDataSidebar: false,
      addPostSidebarData: {},
      groupSection: true,
      showGroups: true,
      showFirstSection: true,
      showSecondSection: true,
      openGroupsSidebar: false,
      openViewPostSidebar: false,
      openGroupMembersSidebar: false,
      openChannelOwnerLeaveSidebar: false,
      groupsSidebarData: {},
      viewPostSidebarData: {},
      phoneList: [],
      emailList: [],
      groupAdminList: [],
      mediaExtensions: {
        img: ["jpg", "jpeg", "png", "bmp", "gif", "exif", "tiff"],
        video: ["avi", "flv", "wmv", "mov", "mp4", "3gp"],
      },
      wasSidebarOpen: null,
      now: moment(),
      //Chat
      active: true,
      searchContact: "",
      activeProfileSidebar: false,
      activeChatUser: "123",
      userProfileId: -1,
      typedMessage: "",
      isChatPinned: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7,
      },
      clickNotClose: true,
      isChatSidebarActive: true,
      isLoggedInUserProfileView: false,
      AllDialog: [],
      GroupList: [],
      currentUserName: "Brainstorm",
      groupPage: 1,
      groupSize: 10,
      showReply: false,
      firstReply: "",
      isPrivateGroup: false,
      isSystemGroup: false,
      CommentOffset: 0,
      isReader: false,
      blurpostopen: false,
      uuidList: [],
      initialRole: "admin",
      loginUserRole: false,
      groupPostDelete: [],

      //

      group_all_time_group: "",
      grouptypeid: "",
      uniquid: "",
      groupName: "",
      groupDescription: "",
      photourl: "",
    };
  },
  watch: {
    windowWidth() {
      //this.setSidebarWidth();
      this.setSectionDisplay();
    },
    $route(to, from) {
      if (to !== from) {
        this.active_sidebar = false;
        this.$emit("closeSidebar");
      }
    },
    openGroupsSidebar(newVal, oldVal) {
      if (this.windowWidth < 506) {
        this.Groupsidebar = true;
      }
    },
  },
  computed: {
    ...mapState("Group", ["channelMember"]),
    ...mapState("post", ["promotedPostId"]),
    OwnerMemberList() {
      let self = this;
      return self.memberList
        .filter((member) => {
          return member.usersDetailsId !== self.token.userID;
        })
        .map((el) => ({
          ...el,
          isSelected: false,
        }));
    },
    emojiDataAll() {
      return EmojiAllData;
    },
    emojiGroups() {
      return EmojiGroups;
    },
    // mediaType() {
    //   return media => {
    //     if (media.img) {
    //       const ext = media.img.split(".").pop();
    //       if (this.mediaExtensions.img.includes(ext)) return "image";
    //     } else if (media.sources && media.poster) {
    //       // other validations
    //       return "video";
    //     }
    //   };
    // },
    playerOptions() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ src: media.video_url }],
          poster: media.poster,
        };
      };
    },
    playerOptionsAudio() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ src: media.audio_url }],
          poster:
            this.magittUrl +
            "uploads/defaultImages/default_audio_post_wide.jpg",
        };
      };
    },
    playerOptionsYoutube() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          techOrder: ["youtube"],
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ type: "video/youtube", src: media.url }],
        };
      };
    },
    magittUrl() {
      return this.baseUrlMagitt;
    },

    activeUser() {
      return this.$store.state.AppActiveUser;
    },

    groups() {
      const start = (this.groupPage - 1) * this.groupSize,
        end = start + this.groupSize;
      return this.GroupList;
    },
    groupRequestList() {
      return this.groupRequestListArray;
    },

    isActiveChatUser() {
      return (userId) => userId == this.activeChatUser;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    groupPageCount() {
      let l = this.GroupList.length,
        s = this.groupSize;
      return Math.ceil(l / s);
    },
    posts() {
      // console.log(this.groupPosts);
      return this.groupPosts;
    },

    currentGroup() {
      return this.$store.state.Group.groupId;
    },
    // selectedGroup() {
    //   return this.selectedGroupDisplay.groupId;
    // },
    isGroupSelected() {
      return this.currentGroup === this.selectedGroup;
    },
    // avatarname() {
    //   if (this.selectedGroupDisplay.group_name.length > 1) {
    //     return this.selectedGroupDisplay.group_name.trim()[0].toUpperCase();
    //   }
    // },
    //   photo() {
    //     if (this.selectedGroupDisplay.group_icon != null) {
    //       return this.baseUrlMagitt + this.selectedGroupDisplay.group_icon;
    //     }
    //   },
  },
  methods: {
    async editBlogPost() {
      this.$vs.loading();
      const payload = {
        blogPostData: {
          userId: this.token.userID,
          ownerName: this.token.username,
          category: this.select3,
          author: this.select2,
        },

        communityData: {
          communityName: currentCommunityName.communityName,
        },
        newBlogPostData: {
          postId: this.editpostId,
          // featured_image: this.featuredimage,
          // body: self.blog,
          // text: self.textarea,
          // image: this.blogimage,
          // video: this.blogVideo
        },
        groupData: {},
      };
      if (this.newfeaturedimage != null) {
        Object.assign(payload.newBlogPostData, {
          featured_image: this.newfeaturedimage,
        });
      }
      if (this.newblogimage != null) {
        Object.assign(payload.newBlogPostData, { image: this.newblogimage });
      }
      if (this.newblogVideo != null) {
        Object.assign(payload.newBlogPostData, { video: this.newblogVideo });
      }
      if (this.setTextarea1 != null) {
        Object.assign(payload.newBlogPostData, { text: this.setTextarea1 });
      }
      if (this.setTextarea2 != null) {
        Object.assign(payload.newBlogPostData, { body: this.setTextarea2 });
      }
      if (this.imagefile != null) {
        Object.assign(payload.newBlogPostData, {
          featured_image: this.imagefile,
        });
      }
      console.log("sdfsdfsdfsdfsdfsdsdf", payload.newBlogPostData);
      const { data } = await postsRepository.editBlogPost(payload);
      // console.log(data);
      if (data.ResponseCode == 100) {
        if (this.newdocumentFile) {
          try {
            await this.attachDocument(this.editpost_UniqueId);
            this.$vs.loading.close();
            this.postAddedAlert();
          } catch (err) {
            this.$vs.loading.close();
          }
        } else {
          this.postAddedAlert();
        }
        this.$vs.loading.close();
        this.popupActivo = false;
        // console.log(data);
      } else {
        this.$vs.loading.close();
      }
    },
    setFeatureImage(image) {
      this.$emit("load", (this.newfeaturedimage = image));
      this.newFeaimg = true;
    },
    uploadPostImages(e) {
      const files = e.target.files[0];
      this.imagepreview = URL.createObjectURL(files);
      const reader = new FileReader();
      let rawimg;
      reader.onload = () => {
        this.imagefile = reader.result;
        console.log("dfkjsdfjsdlkfjlskdfjlsdjflkjflsd", this.imagefile);
      };
      reader.readAsDataURL(files);
    },
    uploadPostImages1(e) {
      const files = e.target.files[0];
      this.imagepreview = URL.createObjectURL(files);
      const reader = new FileReader();
      reader.onload = () => {
        this.imagefile = reader.result;
      };
      reader.readAsDataURL(files);
    },
    uploadPostImage(ev) {
      this.$emit("load", (this.newblogimage = ev));
      this.newimg = true;
      console.log("sdsadjsalkdjsaldjlkasjdlkasjdlkasd", this.newblogimage);
    },
    uploadPostVideo(ev) {
      this.$emit("load", (this.newblogVideo = ev));
      this.newvideo = true;
    },
    loadDocumentData(values) {
      this.newdocumentFile = values;
    },
    async getcategory() {
      const { data } = await postsRepository.KotakgetCategory();
      // if(data.status==200){
      // console.log(data);

      this.category.push(data);
      // console.log(this.category);
      // }
    },
    async getauthor() {
      const { data } = await postsRepository.Kotakgetauthor();
      // if(data.status==200){
      // console.log(data);

      this.author.push(data);
      // console.log(this.author);
      // }
    },
    handleClick(name) {
      this.$router.push({ name: name });
    },
    openLoadingContained(id) {
      this.$vs.loading({
        color: this.colorLoading,
        container: `#${id}`,
        scale: 0.45,
      });
    },
    async deleteMultiple() {
      let isDelete = await this.deleteAlert("post");
      if (isDelete.value) {
        this.$vs.loading();
        // console.log(this.groupPostDelete);
        let obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          status: 1,
          name: this.token.username,
          postId: this.groupPostDelete,
        };
        const { data } = await postsRepository.deletePostMultiple(obj);

        if (data) {
          this.$vs.loading.close();
          for (let iterator of this.groupPostDelete) {
            const index = this.groupPosts.findIndex(
              (x) => x.post_UniqueId === iterator
            );
            this.groupPosts.splice(index, 1);
          }
        } else {
          this.$vs.loading.close();
        }
      }
    },
    async currentUserRole() {
      let token = jwt.decode(localStorage.getItem("token"));
      let payload = {
        userData: {
          id: token.userID,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await profileRepository.getAllUserData(payload);
      if (data[0].communityManager_Is_owner) {
        if (
          data[0].communityManager_Is_owner == "1" ||
          data[0].communityManager_Is_owner == "2"
        ) {
          this.loginUserRole = true;
        } else {
          this.loginUserRole = false;
        }
      }
    },
    closeLoadingContained(id) {
      this.$vs.loading.close(`#${id} > .con-vs-loading`);
    },
    async MakeOwnerAndLeave() {
      this.$swal({
        title: "Are you sure you want to leave this channel ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.ownerLeaveGroup();
        }
      });
    },
    async ownerLeaveGroup() {
      this.$vs.loading();
      let self = this;
      let nextOwnerIsGroupAdmin =
        self.memberList[
          self.memberList.findIndex((x) => x.usersDetailsId == self.select1)
        ].group_admin_id != null;
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          groupId: self.currentGroupId,
          CurrentOwnerUserName: self.token.username,
          CurrentOwnerUserId: self.token.userID,
          nextOwnerUserId: self.select1,
          nextOwnerIsGroupAdmin: nextOwnerIsGroupAdmin,
        },
      };

      const { data } = await groupsRepository.MakeGroupOwnerAndLeave(obj);

      if (data) {
        this.LeaveGroup = false;
        this.$vs.loading.close();

        this.$store.state.groupId = null;
        this.$store.state.groupuuid = null;
        this.$router.push("/community-home").catch(() => {});
      } else {
        this.$vs.loading.close();
      }
    },
    ownerLeaveNotifications(newAdmin) {
      this.leaveChannelNotification(this.groupAdminList);
      this.newOwnerNotification(newAdmin);
    },
    getNewGroup() {
      this.GroupList = [];
      this.getGroupList();
    },
    refreshMembers(values) {
      this.getUsersInGroup();
      this.newMenbersAddedNotification(values);
    },
    refreshGroup() {
      this.getGroupDetails();
      this.GroupList = [];
      this.getGroupList();
    },
    newMenbersAddedNotification(userId) {
      this.insertNotification(
        `has added you to ${this.currentGroupName}`,
        "Group",
        "Group Invitation",
        this.$store.state.groupId,
        userId
      );
    },
    openProfilePage() {
      this.$router.push("/community-user-profile").catch(() => {});
      this.$store.state.memberId = this.currentUserId;
    },
    loadContent() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-load-more-posts",
        scale: 0.45,
      });
      setTimeout(() => {
        this.$vs.loading.close("#button-load-more-posts > .con-vs-loading");
      }, 3000);
    },
    async getGroupDetails() {
      var self = this;
      let obj = {
        groupData: {
          id: self.$store.state.groupId,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
          communityID: "b2146af0-15c3-11ea-b55d-bdde97cb4377",
          communitiesType: 1,
          communitiesSubType: 0,
        },
      };

      const { data } = await groupsRepository.getGroup(obj);

      if (data) {
        if (data[0].group_type_id == 3) {
          this.isSystemGroup = true;
        } else {
          this.isSystemGroup = false;
        }
        this.$store.state.groupId = data[0].groupId;
        this.$store.dispatch("Group/storeGroupId", data[0].groupId);
        this.currentGroupId = data[0].groupId;
        this.currentGroupName = data[0].group_name;
        this.currentGroupIcon = data[0].group_icon;
        this.currentGroupDescription = data[0].group_description;
        this.currentGroupPostingType = data[0].group_all_time_group;
        if (data[0].group_type_id == 1) {
          this.currentGroupType = true;
        } else {
          this.currentGroupType = false;
        }
        if (data[0].group_type_id == null) {
          this.isUniverseGroup = true;
        } else {
          this.isUniverseGroup = false;
        }
        // if (data[0].group_type_id == 2) {
        //   this.getUsersInPublicGroup();
        // } else {
        this.getUsersInGroup();
        // }

        this.getGroupRequestDetails();
        this.$refs.infiniteLoading.stateChanger.reset();
        // setTimeout(() => {
        //   this.activate_gr =
        //     this.GroupList.findIndex(x => x.groupId == this.currentGroupId) + 1;
        // }, 100);
      }
    },
    async setGroupDetails(group, index) {
      this.selectedGroupDisplay = group;
      if (group.isPrivateGroup) {
        this.$swal({
          title: "This Channel is private.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Request to Join",
        }).then((result) => {
          if (result.value) {
            this.requestToJoin(group.groupId, group.group_name);
          }
        });
      } else if (group.isPublicGroup) {
        this.$swal({
          title: "Would you like to join this channel ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Join",
        }).then((result) => {
          if (result.value) {
            this.joinGroup(group);
          }
        });
      } else {
        this.getDetails(group, index);
      }
    },
    memberSideBar(group) {
      console.log("Member Side bar");
      this.showFirstSection = false;
      this.Groupsidebar = true;
      this.userSection = false;
      this.discussionSection = false;
      this.groupSection = true;
      this.$store.state.groupId = group.groupId;
      this.$store.state.groupuuid = group.group_UniqueId;
      this.$store.dispatch("Group/storeGroupId", group.groupId);
      // this.$refs.infiniteLoading.stateChanger.reset();
      this.groupRequestListArray = [];
      this.groupPosts = [];
      this.postsPage = 1;
      this.postsLimit = 30;
      this.postsOffset = 0;
      this.getGroupDetails();
    },
    getDetails(group, index) {
      this.activate_gr = index + 1;
      if (this.windowWidth < 506) {
        this.showFirstSection = false;
        this.showSecondSection = true;
      }

      this.userSection = false;
      this.discussionSection = false;
      this.groupSection = true;
      this.$store.state.groupId = group.groupId;
      this.$store.state.groupuuid = group.group_UniqueId;
      this.$store.dispatch("Group/storeGroupId", group.groupId);
      // this.$refs.infiniteLoading.stateChanger.reset();
      this.groupRequestListArray = [];
      this.groupPosts = [];
      this.postsPage = 1;
      this.postsLimit = 30;
      this.postsOffset = 0;
      this.getGroupDetails();

      // var self = this;
      // let obj = {
      //   groupData: {
      //     id: groupId
      //   },
      //   communityData: {
      //     communityName: currentCommunityName.communityName,
      //     communityID: "b2146af0-15c3-11ea-b55d-bdde97cb4377",
      //     communitiesType: 1,
      //     communitiesSubType: 0
      //   }
      // }

      // const { data } = await groupsRepository.getGroup(obj);

      // if(data) {
      //   this.$store.state.groupId = data[0].groupId;
      //   this.currentGroupName = data[0].group_name;
      //   this.currentGroupIcon = data[0].group_icon;
      //   this.currentGroupDescription = data[0].group_description;
      // }
    },
    async getUsersInGroup() {
      // this.active = true;
      var self = this;
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },

        groupId: self.$store.state.groupId,
        userId: self.token.userID,
      };

      const { data } = await groupsRepository.getUsersInGroup(obj);

      await this.$store.commit("Group/channelMember", data);

      if (data.length > 0) {
        this.memberList = data;
        this.phoneList = data.map((x) => x.usersDetails_Mobile);
        this.emailList = data.map((x) => x.usersDetails_Email);
        this.uuidList = data.map((x) => x.usersDetails_UniqueId);
        // this.emailList = data.filter(x != null);
        this.groupAdminList = data
          .filter((x) => x.group_admin_id != null)
          .map((x) => x.usersDetails_UniqueId);
        if (
          data.findIndex((x) => x.usersDetails_UniqueId == this.token.uuid) !=
          -1
        ) {
          if (
            data[
              data.findIndex((x) => x.usersDetails_UniqueId == this.token.uuid)
            ].group_admin_id != null
          ) {
            this.isGroupAdmin = true;
            this.isGroupOwner =
              data[
                data.findIndex(
                  (x) => x.usersDetails_UniqueId == this.token.uuid
                )
              ].groupOwner;
          } else {
            this.isGroupAdmin = false;
            this.isGroupOwner = false;
          }
        }
      } else {
        this.memberList = [];
        this.phoneList = [];
        this.emailList = [];
        // this.emailList = data.filter(x != null);
        this.groupAdminList = [];
        this.isGroupOwner = data[0].groupOwner;
        if (
          data.findIndex((x) => x.usersDetails_UniqueId == this.token.uuid) !=
          -1
        ) {
          if (
            data[
              data.findIndex((x) => x.usersDetails_UniqueId == this.token.uuid)
            ].group_admin_id != null
          ) {
            this.isGroupAdmin = true;
          } else {
            this.isGroupAdmin = false;
          }
        }
      }
    },
    async getUsersInPublicGroup() {
      // this.active = true;
      var self = this;
      let obj = {
        communityData: {},
        communityName: currentCommunityName.communityName,
        groupId: self.$store.state.groupId,
        userId: self.token.userID,
      };

      const { data } = await groupsRepository.getUsersInPublicGroup(obj);

      if (data.length > 0) {
        this.memberList = data;
        this.phoneList = data.map((x) => x.usersDetails_Mobile);
        this.emailList = data.map((x) => x.usersDetails_Email);
        // this.emailList = data.filter(x != null);
        this.groupAdminList = data.filter((x) => x.group_admin_id != null);

        this.isGroupOwner = data[0].groupOwner;

        if (
          data.findIndex((x) => x.usersDetails_UniqueId == this.token.uuid) !=
          -1
        ) {
          if (
            data[
              data.findIndex((x) => x.usersDetails_UniqueId == this.token.uuid)
            ].group_admin_id != null
          ) {
            this.isGroupAdmin = true;
          } else {
            this.isGroupAdmin = false;
          }
        }
      } else {
        this.memberList = [];
        this.phoneList = [];
        this.emailList = [];
        // this.emailList = data.filter(x != null);
        this.groupAdminList = [];
        this.isGroupOwner = data[0].groupOwner;
        if (
          data.findIndex((x) => x.usersDetails_UniqueId == this.token.uuid) !=
          -1
        ) {
          if (
            data[
              data.findIndex((x) => x.usersDetails_UniqueId == this.token.uuid)
            ].group_admin_id != null
          ) {
            this.isGroupAdmin = true;
          } else {
            this.isGroupAdmin = false;
          }
        }
      }
    },
    leaveGroupAlert() {
      if (this.isGroupOwner) {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 0);
        this.ownerLeaveChannel();
        this.LeaveGroup = true;
      } else {
        this.$swal({
          title: "Are you sure you want to leave this channel ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.leaveGroup();
          }
        });
      }
    },
    async leaveGroup() {
      this.$vs.loading();
      let self = this;
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          groupId: self.$store.state.groupId,
          userId: self.token.userID,
        },
      };
      if (this.isGroupOwner) {
        setTimeout(() => {
          this.$vs.loading.close();
        }, 0);
        this.LeaveGroup = true;
      } else {
        const { data } = await groupsRepository.leaveGroup(payload);
        if (data) {
          this.$vs.loading.close();
          this.leaveChannelNotification(this.groupAdminList);
          this.$store.state.groupId = null;
          this.$store.state.groupuuid = null;
          this.$router.push("/community-home").catch(() => {});
        } else {
          this.$vs.loading.close();
        }
      }
    },
    // setUser(userID) {
    //   console.log(userID);
    //   this.selectedUser = userID;
    //   console.log(this.selectedUser);
    // },
    setUser(user) {
      this.selectedUser = user.usersDetailsId;
      this.selecteduuid = user.usersDetails_UniqueId;
      this.isReader = user.usersDetails_Password == null ? true : false;
      this.notAdmin = user.group_admin_id == null ? true : false;
      // console.log(this.selectedUser);
      // console.log(this.notAdmin && this.selectedUser != this.token.userID);
      if (this.token.userID != user.usersDetailsId) {
        this.active_sidebar = !this.active_sidebar;
      }
    },
    removeUser(userId) {
      this.$swal({
        title: "Are you sure you want to remove a member",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.confirmRemoveUser(userId);
        }
      });
    },
    async confirmRemoveUser(userId) {
      var self = this;
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          groupId: self.$store.state.groupId,
          userId: userId,
        },
      };

      const { data } = await groupsRepository.leaveGroup(obj);

      if (data) {
        this.active_sidebar = false;
        this.memberList.splice(
          this.memberList.findIndex((x) => x.usersDetailsId == userId),
          1
        );
      }
    },
    async makeGroupAdmin(userId, uuid) {
      let self = this;
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          groupId: self.$store.state.groupId,
          userId: userId,
        },
      };
      const { data } = await groupsRepository.makeGroupAdmin(payload);

      if (data.ResponseCode == 100) {
        this.active_sidebar = false;
        this.makeGroupAdminNotification(uuid);
        this.memberList[
          this.memberList.findIndex((x) => x.usersDetailsId == userId)
        ].group_admin_id = data.adminId;
        if (this.currentGroupPostingType == 0) {
          this.changePostingRights(userId, 1);
        }
      }
    },
    async removeGroupAdmin(userId, uuid) {
      let self = this;
      this.$vs.loading();
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          groupId: self.$store.state.groupId,
          userId: userId,
        },
      };
      const { data } = await groupsRepository.removeGroupAdmin(payload);

      if (data.affectedRows > 0) {
        this.active_sidebar = false;
        this.$vs.loading.close();
        this.removeGroupAdminNotification(uuid);
        this.memberList[
          self.memberList.findIndex((x) => x.usersDetailsId === userId)
        ].group_admin_id = null;
        if (this.currentGroupPostingType == 0) {
          this.changePostingRights(userId, 0);
        }
      }
    },
    async makeGroupAdminNotification(userId) {
      this.insertNotification(
        `has made you the channel admin of ${this.currentGroupName}`,
        "Channels",
        "Channel Admin",
        this.$store.state.groupId,
        userId
      );
    },
    async removeGroupAdminNotification(userId) {
      this.insertNotification(
        `has removed your channel admin rights of ${this.currentGroupName}`,
        "Channels",
        "Channel Admin",
        this.$store.state.groupId,
        userId
      );
    },
    leaveChannelNotification(userId) {
      this.insertNotification(
        `has left ${this.currentGroupName}`,
        "Channels",
        "Channel Leave",
        this.$store.state.groupId,
        userId
      );
    },
    newOwnerNotification(userId) {
      this.insertNotification(
        `has made you the channel owner of ${this.currentGroupName}`,
        "Channels",
        "Channel New Owner",
        this.$store.state.groupId,
        userId
      );
    },
    async changePostingRights(userId, status) {
      let self = this;
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          groupID: self.$store.state.groupId,
          userID: [userId],
          status: status,
        },
      };
      await groupsRepository.changePostingRights(payload);
    },
    async reInit() {
      if (this.$store.state.groupId == undefined) {
        this.getUniverseGroup();
      } else {
        this.groupRequestListArray = [];
        this.groupPosts = [];
        this.postsPage = 1;
        this.postsLimit = 30;
        this.postsOffset = 0;
        this.wasSidebarOpen = this.$store.state.reduceButton;
        this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
        this.getGroupDetails();
        this.getGroupList();

        this.toggleChatSidebar(true);
        //this.createSession()
        //  this.getDialog();
        // this.getMessage();
      }
    },
    async getUniverseGroup() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: this.token.userID,
        },
      };

      const { data } = await groupsRepository.getUniverseGroup(obj);

      if (data) {
        this.$store.state.groupId = data[0].groupId;
        //this.reInit();
      }
    },
    async getGroupRequestDetails() {
      let obj = {
        groupData: {
          groupId: this.$store.state.groupId,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };

      const { data } = await groupsRepository.getJoinRequestsOfGroup(obj);

      if (data) {
        this.groupRequestListArray = data;
      }
    },
    async joinRequestResponse(res, joinRequestId, userId) {
      const requesterindex = this.groupRequestListArray.findIndex(
        (x) => x.group_join_requestsId === joinRequestId
      );
      const requesteruuid =
        this.groupRequestListArray[requesterindex].usersDetails_UniqueId;
      let obj = {
        invitationData: {
          groupRequestId: joinRequestId,
          status: res,
          userId: userId,
          groupId: this.$store.state.groupId,
          ownerName: this.token.username,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };

      const { data } = await groupsRepository.invitationResponseOfGroup(obj);

      if (data.ResponseCode == 100) {
        this.groupRequestListArray.splice(
          this.groupRequestListArray.findIndex(
            (x) => x.group_join_requestsId === joinRequestId
          ),
          1
        );
        const groupIndex = this.GroupList.findIndex(
          (x) => x.groupId == this.$store.state.groupId
        );
        this.joinResponseNotification(res, requesteruuid);
        this.GroupList[groupIndex].JOINREQUESTCOUNT--;
        this.refreshMembers();
      }
    },
    joinResponseNotification(res, userId) {
      if (res === 2) {
        this.insertNotification(
          `has accepted your request to join ${this.currentGroupName}`,
          "Channels",
          "Channel Join Response",
          this.$store.state.groupId,
          userId
        );
      } else if (res === 0) {
        this.insertNotification(
          `has declined your request to join ${this.currentGroupName}`,
          "Channels",
          "Channel Join Response",
          this.$store.state.groupId,
          userId
        );
      }
    },
    async getPostsOfGroup() {
      let obj = {
        userData: {
          id: this.token.userID,
          uuid: this.token.uuid,
        },
        groupData: {
          id: this.$store.state.groupId,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        paging: {
          limit: this.postsLimit,
          offset: this.postsOffset,
        },
      };

      const { data } = await postsRepository.getPostsOfGroup(obj);

      return new Promise((resolve) => {
        if (data) {
          this.postsPage += 1;
          this.postsOffset += this.postsLimit;

          if (data.length > 0) {
            data.forEach((element) => {
              // if (
              //   moment().diff(
              //     moment(element.post_group_mapping_created_datetimestamp),
              //     "hours"
              //   ) < 13
              // ) {
              element.post_group_mapping_created_datetimestamp = moment(
                element.post_group_mapping_created_datetimestamp
              ).fromNow();
              // } else {
              //   element.post_group_mapping_created_datetimestamp = moment(
              //     element.post_group_mapping_created_datetimestamp
              //   ).format("DD-MM-YYYY HH:mm");
              // }
              if (element.image != null) {
                if (element.image.includes("uploads/postImages")) {
                  element.image = this.magittUrl + element.image;
                }
              }
              if (element.video_url != null) {
                if (element.video_url.includes("uploads/postVideos")) {
                  element.video_url = this.magittUrl + element.video_url;
                }
              }
              if (element.audio_url != null) {
                if (element.audio_url.includes("uploads/postAudios")) {
                  element.audio_url = this.magittUrl + element.audio_url;
                }
              }
              if (element.featured_image != null) {
                if (element.featured_image.includes("uploads/postImages")) {
                  element.featured_image =
                    this.magittUrl + element.featured_image;
                }
              }
              if (element.PollQuestion !== null) {
                element.VotedIds =
                  element.VotedIds !== null ? element.VotedIds : "";
                element.AnswersIds =
                  element.AnswersIds !== null
                    ? element.AnswersIds.split(",")
                    : [];
                element.PollAnswers =
                  element.PollAnswers !== null
                    ? element.PollAnswers.split(",")
                    : [];
                element.AnswersVotes =
                  element.AnswersVotes !== null
                    ? element.AnswersVotes.split(",")
                    : [];
              }
              element.comments = [];
              element.newComment = "";
              element.showReply = false;
              element.first_comment_replies = [];
              element.isLikeLoading = false;
              this.groupPosts.push(element);
            });
            resolve(true);
          } else {
            //       ////debugger
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    },
    infiniteHandler($state) {
      setTimeout(() => {
        this.getPostsOfGroup().then((data) => {
          if (data) {
            $state.loaded();
          } else {
            $state.complete();
          }
        });
      }, 500);

      // axios.get(api, {
      //   params: {
      //     page: this.page,
      //   },
      // }).then(({ data }) => {
      //   if (data.hits.length) {
      //     this.page += 1;
      //     this.list.push(...data.hits);
      //     $state.loaded();
      //   } else {
      //     $state.complete();
      //   }
      // });
    },
    showLikeLoader(loaderid, loader) {
      this.groupPosts[loader].isLikeLoading = true;
      this.openLoadingContained(loaderid);
    },
    hideLikeLoader(loaderid, loader) {
      this.groupPosts[loader].isLikeLoading = false;
      this.closeLoadingContained(loaderid);
    },
    async likePost(status, postId, likeId, userId, postuid, uuid, loaderid) {
      const index = this.groupPosts.findIndex(
        (x) => x.post_UniqueId == postuid
      );
      this.showLikeLoader(loaderid, index);

      if (likeId) {
        let self = this;
        let payload = {
          likeData: {
            id: likeId,
            isActive: !status,
            ownerName: self.token.username,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const { data } = await postsRepository.editLikedPost(payload);

        if (data.ResponseCode == 100) {
          this.hideLikeLoader(loaderid, index);
          this.groupPosts[
            this.groupPosts.findIndex((x) => x.likeId === likeId)
          ].is_active = !status;
          if (!status) {
            this.groupPosts[
              this.groupPosts.findIndex((x) => x.likeId === likeId)
            ].LIKECOUNT += 1;
            this.insertLikeNotification(postuid, uuid);
          } else {
            this.groupPosts[
              this.groupPosts.findIndex((x) => x.likeId === likeId)
            ].LIKECOUNT -= 1;
          }
        } else {
          this.hideLikeLoader(loaderid, index);
          self.$vs.notify({
            title: "Error",
            text: "Something went wrong",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      } else {
        let self = this;
        let payload = {
          likeData: {
            postId: postuid,
            groupId: this.$store.state.groupId,
            userId: self.token.uuid,
            isActive: 1,
            ownerName: self.token.username,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const { data } = await postsRepository.likePost(payload);

        if (data.ResponseCode == 100) {
          this.hideLikeLoader(loaderid, index);
          this.groupPosts[
            this.groupPosts.findIndex((x) => x.post_UniqueId === postuid)
          ].is_active = 1;
          this.groupPosts[
            this.groupPosts.findIndex((x) => x.post_UniqueId === postuid)
          ].likeId = data.insertId;
          if (!status) {
            this.groupPosts[
              this.groupPosts.findIndex((x) => x.post_UniqueId === postuid)
            ].LIKECOUNT += 1;
            this.insertLikeNotification(postuid, uuid);
          } else {
            this.groupPosts[
              this.groupPosts.findIndex((x) => x.post_UniqueId === postuid)
            ].LIKECOUNT -= 1;
          }
        } else {
          this.hideLikeLoader(loaderid, index);
          self.$vs.notify({
            title: "Error",
            text: "Something went wrong",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      }
    },
    async insertLikeNotification(postId, userId) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "liked your post",
            notificationTitle: "Likes",
            notificationEntityType: "Post",
            notificationEntityId: postId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        await notificationRepository.addNotification(payload);
      }
    },
    async insertCommentNotification(postId, userId) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "commented on your post",
            notificationTitle: "Comments",
            notificationEntityType: "Post",
            notificationEntityId: postId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        await notificationRepository.addNotification(payload);
      }
    },
    changeHidden() {
      this.isHidden = !this.isHidden;
      this.isHidden2 = !this.isHidden2;
      if (this.isHidden3) {
        this.isHidden3 = false;
        this.isHidden = false;
      }
    },
    changeGroupInfo() {
      this.isHidden = !this.isHidden;
      this.isHidden3 = !this.isHidden3;
      if (this.isHidden2) {
        this.isHidden2 = false;
        this.isHidden = false;
      }
    },
    async addComment(postId, comment, uuid) {
      //insertComment
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
        userId: this.token.uuid,
        comment: comment,
        commenterName: this.token.username,
      };

      const { data } = await postsRepository.insertComment(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        let post =
          this.groupPosts[
            this.groupPosts.findIndex((x) => x.post_UniqueId === postId)
          ];
        // if (
        //   !this.groupPosts[
        //     this.groupPosts.findIndex(x => x.post_UniqueId === postId)
        //   ].first_comment
        // ) {
        //   this.groupPosts[
        //     this.groupPosts.findIndex(x => x.post_UniqueId === postId)
        //   ].first_comment = comment;
        //   this.groupPosts[
        //     this.groupPosts.findIndex(x => x.post_UniqueId === postId)
        //   ].first_comment_user_name = this.token.username;
        //   this.groupPosts[
        //     this.groupPosts.findIndex(x => x.post_UniqueId === postId)
        //   ].first_comment_user_image = this.token.userImage;
        // } else {
        post.comments.push({
          usersDetails_UserImage: this.token.userImage,
          comments_commentedBy: this.token.username,
          comments_comment: comment,
          comments_commentsId: data.commentId,
          replyShow: false,
          replies: [],
          showReplies: false,
        });
        // }
        this.insertCommentNotification(postId, uuid);
        post.newComment = "";

        post.comment_count =
          post.comment_count == null ? 1 : parseInt(post.comment_count) + 1;
      }
    },

    async addReply(postId, commentId, reply) {
      //insertReply
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
        commentId: commentId,
        reply: reply,
        commenterName: this.token.username,
        userId: this.token.uuid,
      };

      const { data } = await postsRepository.insertReply(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        this.groupPosts[
          this.groupPosts.findIndex((x) => x.post_UniqueId === postId)
        ].first_comment_replies.push({
          usersDetails_UserImage: this.token.userImage,
          usersDetails_UserName: this.token.username,
          replies_comment: reply,
        });

        this.firstReply = "";
      } else {
        this.$vs.loading.close();
      }
    },
    async getComments(postId) {
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
      };

      const { data } = await postsRepository.getComments(obj);

      if (data.ResponseCode == 100) {
        data.Data.forEach((element) => {
          this.groupPosts[
            this.groupPosts.findIndex((x) => x.post_UniqueId === postId)
          ].comments.push(element);
        });
      }
    },
    togglePostDataSidebar(val = false) {
      if (!val) {
        this.renderGroupsPageAddPost = !this.renderGroupsPageAddPost;
      }
      this.addNewPostDataSidebar = val;
    },
    addNewPostData() {
      this.renderGroupsPageAddPost = !this.renderGroupsPageAddPost;
      this.addPostSidebarData = {};
      this.togglePostDataSidebar(true);
    },
    async getGroupList() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: this.token.userID,
        },
      };
      const { data } = await groupsRepository.getGroupsOfUser(obj);

      if (data) {
        data.forEach((element) => {
          element.isUserGroup = true;
          this.GroupList.push(element);
        });
        // self.GroupList=data;
        this.getPublicGroupList();
      }
    },
    async getPublicGroupList() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: this.token.userID,
        },
      };
      const { data } = await groupsRepository.getPublicGroups(obj);

      if (data) {
        data.forEach((element) => {
          element.isPublicGroup = true;
          this.GroupList.push(element);
        });
        this.getPrivateGroupList();
        // self.GroupList=data;
      }
    },
    async getPrivateGroupList() {
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: this.token.userID,
        },
      };
      const { data } = await groupsRepository.getPrivateGroups(obj);

      if (data) {
        data.forEach((element) => {
          element.isPrivateGroup = true;
          this.GroupList.push(element);
        });
        // self.GroupList=data;
      }
    },

    getUserStatus(isActiveUser) {
      // return "active"
      return isActiveUser
        ? this.$store.state.AppActiveUser.status
        : this.contacts[this.activeChatUser].status;
    },
    closeProfileSidebar(value) {
      this.activeProfileSidebar = value;
    },

    showProfileSidebar(userId, openOnLeft = false) {
      this.userProfileId = userId;
      this.isLoggedInUserProfileView = openOnLeft;
      this.activeProfileSidebar = !this.activeProfileSidebar;
    },

    toggleIsChatPinned(value) {
      this.isChatPinned = value;
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.isChatSidebarActive = this.clickNotClose = false;
      } else {
        this.isChatSidebarActive = this.clickNotClose = true;
      }
    },
    setSectionDisplay() {
      if (this.windowWidth < 426) {
        this.showFirstSection = true;
        this.showSecondSection = false;
      } else {
        this.showFirstSection = true;
      }
    },
    toggleChatSidebar(value = false) {
      if (!value && this.clickNotClose) return;
      this.isChatSidebarActive = value;
    },
    OpenHomePage() {
      this.$router.push("/community-home").catch(() => {});
    },

    toggleGroupsDataSidebar(val = false) {
      this.openGroupsSidebar = val;
    },
    toggleViewPostDataSidebar(val = false) {
      this.openViewPostSidebar = val;
    },
    toggleGroupMembersSidebar(val = false) {
      this.openGroupMembersSidebar = val;
    },
    toggleChannelOwnerLeaveSidebar(val = false) {
      this.openChannelOwnerLeaveSidebar = val;
    },
    addEditGroup() {
      this.groupsSidebarData = {};
      this.toggleGroupsDataSidebar(true);
    },
    viewSinglePost(postData) {
      this.viewPostSidebarData = postData;
    },
    viewAllMembers() {
      this.toggleGroupMembersSidebar(true);
    },
    ownerLeaveChannel() {
      this.toggleChannelOwnerLeaveSidebar(true);
    },
    viewSinglePostPage(postData) {
      let singlePostLink = `${window.document.location.origin}/readers-view?pid=${postData.post_UniqueId}`;
      // window.open(singlePostLink, "_blank");

      let payload = {
        full: singlePostLink,
      };

      this.$vs.loading();

      this.$store
        .dispatch("shortURL/addShortUrl", payload)
        .then(() => {
          this.$vs.loading.close();
          window.open(this.$store.state.shortURL.url.full, "_blank");
        })
        .catch(() => {
          this.$vs.loading.close();
        });

      // this.$router.push({ name: "readers-view" });
      this.$store.commit("post/channelPostDetail", postData);
      // this.$store.commit("post/postDetail", data);

      // this.$router
      //   .push({
      //     name: "page-IndividualPost",
      //     params: { groupPost: postData }
      //   })
      //   .catch(() => {});
      // let rawString = {
      //   postId: postData.post_UniqueId,
      //   community: currentCommunityName.communityName,
      //   communityColor: localStorage.getItem("communityColor"),
      //   communityId: localStorage.getItem("communityID")
      // };
      // rawString = JSON.stringify(rawString);
      // let postLink =
      //   window.document.location.origin + "/posts?pid=" + btoa(rawString);
      // let postLink = `${window.document.location.origin}/posts?pid=${postData.post_UniqueId}`;
      // let routeData = this.$router.resolve({
      //   path: `/posts?pid=${postData.post_UniqueId}`
      // });
      // let payload = {
      //   full: postLink,
      // };

      // this.$vs.loading();

      // this.$store
      //   .dispatch("shortURL/addShortUrl", payload)
      //   .then(() => {
      //     this.$vs.loading.close();
      //     window.open(this.$store.state.shortURL.url.full, "_blank");
      //   })
      //   .catch(() => {
      //     this.$vs.loading.close();
      //   });
      // window.open(routeData.href, "_blank");
    },
    async promotePost(post) {
      // let rawString = {
      //   postId: post.post_UniqueId,
      //   community: currentCommunityName.communityName,
      //   communityColor: localStorage.getItem("communityColor"),
      //   communityId: localStorage.getItem("communityID")
      // };
      // rawString = JSON.stringify(rawString);
      // let postLink =
      //   window.document.location.origin + "/posts?pid=" + btoa(rawString);
      let postLink = `${window.document.location.origin}/posts?pid=${post.post_UniqueId}`;
      let payload = {
        full: postLink,
      };

      this.$vs.loading();

      let postData = post;
      if (!postData.post_whatsapp_body1) {
        postData.post_whatsapp_body1 = " ";
      }
      if (!postData.post_whatsapp_body2) {
        postData.post_whatsapp_body2 = " ";
      }
      if (!postData.post_highlights2) {
        postData.post_highlights2 = " ";
      }

      this.$store
        .dispatch("shortURL/addShortUrl", payload)
        .then(() => {
          this.$vs.loading.close();
          let postbody = "";
          if (post.body) {
            let doc = new DOMParser().parseFromString(post.body, "text/html");
            // console.log(doc.querySelector("p"));
            postbody = doc.querySelector("p").textContent;
          }
          // console.log(post,"uhuhk");
          this.shortLinkAlert(
            post.post_UniqueId,
            `${window.document.location.origin}/posts/${this.$store.state.shortURL.url.short}`,
            this.postText(post),
            postbody,
            postData
          );
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    postText(post) {
      return (
        post.text ||
        post.imageText ||
        post.videoText ||
        post.audioText ||
        post.blogText
      );
    },
    shortLinkAlert(post_UniqueId, postLink, postCaption, postBlog = "", post) {
      this.successPost(
        post_UniqueId,
        postCaption,
        [this.$store.state.groupId],
        [this.$store.state.groupuuid],
        [this.currentGroupName],
        postLink,
        postBlog,
        post
      );
    },
    async editGroup(groupId, userList, addMembers) {
      this.groupEdit = true;
      // this.active = true;
      let obj = {
        groupData: {
          id: groupId,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };

      const { data } = await groupsRepository.getGroup(obj);

      if (data) {
        this.setEditGroupData(data[0], userList, addMembers).then(() => {
          this.getUsersForGroup(groupId, this.token.userID);
        });
      }
      this.active_sidebar = true;
    },
    async setEditGroupData(groupData, userList, addMembers) {
      if (groupData.group_icon != null) {
        this.groupsSidebarData = {
          groupId: groupData.groupId,
          groupName: groupData.group_name,
          newGroupImage: this.magittUrl + groupData.group_icon,
          groupType: groupData.group_type_id,
          groupDescription: groupData.group_description,
          postingRights: groupData.group_all_time_group,
          usersListForEditGroup: [],
          groupEdit: true,
          usersList: userList,
          addMembers: addMembers,
        };
      } else {
        this.groupsSidebarData = {
          groupId: groupData.groupId,
          groupName: groupData.group_name,
          newGroupImage: null,
          groupType: groupData.group_type_id,
          groupDescription: groupData.group_description,
          postingRights: groupData.group_all_time_group,
          usersListForEditGroup: [],
          groupEdit: true,
          usersList: userList,
          addMembers: addMembers,
        };
      }
    },
    async getUsersForGroup(groupId, userId) {
      this.usersList = false;
      // this.active = true;
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },

        groupId: groupId,
        userId: userId,
      };

      const { data } = await groupsRepository.getUsersInGroup(obj);

      if (data) {
        this.active = true;
        this.phoneList = data.map((x) => x.usersDetails_Mobile);
        this.emailList = data.map((x) => x.usersDetails_Email);
        this.emailList = this.emailList.filter((x) => x != null);
        this.groupsSidebarData.usersListForEditGroup = data;
        this.toggleGroupsDataSidebar(true);
      }
    },
    showProfile(userid) {
      if (this.active_sidebar) {
        this.active_sidebar = false;
        this.Groupsidebar = false;
      }
      this.$store.state.memberId = userid;
      this.$router.push("/community-user-profile").catch(() => {});
    },
    async requestToJoin(groupid, groupName) {
      this.$vs.loading();
      var obj = {
        invitationData: {
          userId: this.token.userID,
          status: 1,
          ownerName: this.token.username,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: [groupid],
      };
      const { data } = await groupsRepository.requestToJoinGroup(obj);

      if (data.ResponseCode == 100) {
        this.getUsersInGroupForGroupJoin(groupid, groupName);
        this.$vs.notify({
          title: "Channel Join Request",
          text: "Your request to join this channel has been sent successfully",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
      }
      if (data.ResponseCode == 101) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Channel Join Request",
          text: "Your channel request is under process",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
      }
    },
    async joinGroup(group) {
      let obj = {
        invitationData: {
          groupId: group.groupId,
          inviterUserId: this.token.userID,
          ownerName: this.token.username,
          status: group.group_all_time_group,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        userData: [this.token.userID],
      };

      const { data } = await groupsRepository.inviteUserToGroup(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        this.$swal("", "Channel Joined", "success");
        const index = this.GroupList.findIndex(
          (x) => x.groupId == group.groupId
        );
        this.GroupList[index] = false;
        this.getDetails(group, index);
      }
    },
    async getUsersInGroupForGroupJoin(groupId, groupName) {
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupId: groupId,
        userId: this.token.userID,
      };

      const { data } = await groupsRepository.getUsersInGroup(obj);

      if (data.length > 0) {
        this.groupAdminList = data
          .filter((x) => x.group_admin_id != null)
          .map((x) => x.usersDetails_UniqueId);
        this.insertJoinRequestNotification(
          groupId,
          this.groupAdminList,
          groupName
        );
      }
    },
    async insertJoinRequestNotification(groupId, userId, groupName) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "wants to join " + groupName,
            notificationTitle: "Group",
            notificationEntityType: "Group Join Request",
            notificationEntityId: groupId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const { data } = await notificationRepository.addNotification(payload);
        if (data) {
          this.$vs.loading.close();
        }
      }
    },
    showPopup(status) {
      this.blurpostopen = status;
    },
    deleteAlert(option) {
      return this.$swal({
        title: `Are you sure you want to delete this ${option} ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });
    },
    async deleteChannel() {
      let isDelete = await this.deleteAlert("channel");
      if (isDelete.value) {
        this.deleteChannelAPICall();
      }
    },
    async deleteChannelAPICall() {
      this.$vs.loading();
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        status: 1,
        name: this.token.username,
        groupId: this.$store.state.groupuuid,
      };
      const { data } = await groupsRepository.deleteGroup(payload);
      if (data) {
        this.$vs.loading.close();
        this.$store.state.groupId = null;
        this.$store.state.groupuuid = null;
        this.$router.push("/community-home").catch(() => {});
      } else {
        this.$vs.loading.close();
      }
    },
    async deletePost(groupPost) {
      let isDelete = await this.deleteAlert("post");
      if (isDelete.value) {
        this.deletePostAPICall(groupPost);
      }
    },
    async deletePostAPICall(post) {
      this.$vs.loading();
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        status: 1,
        name: this.token.username,
        postId: post.post_UniqueId,
      };
      const { data } = await postsRepository.deletePost(payload);
      if (data) {
        this.$vs.loading.close();
        this.$swal({
          title: `Post has been deleted successfully`,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        });
        const index = this.groupPosts.findIndex(
          (x) => x.post_UniqueId === post.post_UniqueId
        );
        this.groupPosts.splice(index, 1);
      } else {
        this.$vs.loading.close();
      }
    },
    check(role) {
      if (role === "admin") {
        if (this.initialRole == "admin" || this.initialRole == "superadmin") {
          return true;
        }
      } else if (role === "superadmin") {
        if (this.initialRole == "superadmin") {
          return true;
        }
      }
    },
    async downloadMemberDetail() {
      let id = [];
      this.channelMember.map((x) => {
        id.push(x.usersDetailsId);
      });

      let memberName,
        memberCompanyName,
        kotakCustomer,
        memberEmail,
        memberMobile,
        createdAt,
        CRN,
        whatsappUpdate;

      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        userData: { id: id },
      };

      const response = await groupsRepository.exportUsersInGroup(payload);

      import("@/Core/vendor/Export2Excel").then((excel) => {
        memberName = response.data.map((x) => {
          return x.usersDetails_UserName;
        });
        memberCompanyName = response.data.map((x) => {
          return x.companyName;
        });
        kotakCustomer = response.data.map((x) => {
          if (x.kotakCustomer) {
            return x.kotakCustomer;
          } else {
            return "No";
          }
        });
        memberEmail = response.data.map((x) => {
          return x.usersDetails_Email;
        });
        memberMobile = response.data.map((x) => {
          return x.usersDetails_Mobile;
        });
        whatsappUpdate = response.data.map((x) => {
          if (x.whatsappContent) {
            return x.whatsappContent;
          } else {
            return "No";
          }
        });
        createdAt = response.data.map((x) => {
          return new Date(x.usersDetails_created_datetimestamp).toLocaleString(
            "en-GB"
          );
        });
        CRN = response.data.map((x) => {
          return x.CRN;
        });

        let list = memberName.map((x, i) => {
          return {
            Name: x,
            "Company Name": memberCompanyName[i],
            "Kotak Customer": kotakCustomer[i],
            Email: memberEmail[i],
            Mobile: memberMobile[i],
            "Whatsapp Update": whatsappUpdate[i],
            "Created Date": createdAt[i],
            CRN: CRN[i],
          };
        });
        const headers = [
          "Name",
          "Company Name",
          "Kotak Customer",
          "Email",
          "Mobile",
          "Whatsapp Update",
          "Created Date",
          "CRN",
        ];
        const data = this.formatJson(headers, list);

        excel.export_json_to_excel({
          header: headers,
          data,
          filename: "MemberData",
          autoWidth: true,
          bookType: "csv",
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // console.log(j);
          return v[j];
        })
      );
    },
    uploadDocument(postId) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("postData", `{ "postId": "${postId}" }`);
        formData.append(
          "communityData",
          `{ "communityName": "${currentCommunityName.communityName}" }`
        );
        formData.append("document", this.documentFile);
        if (formData != {}) {
          resolve(formData);
        } else {
          reject(formData);
        }
      });
    },
    attachDocument(postId) {
      this.uploadDocument(postId).then((data) => {
        // var self = this;
        return axios.post(
          this.magittUrl + "BrainstormPostsApi/uploadDocument",
          data,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      });
    },
    async getPost(postId) {
      (this.blogVideo = null),
        (this.blogimage = null),
        (this.featuredimage = null);
      (this.newblogVideo = null),
        (this.newblogimage = null),
        (this.newfeaturedimage = null);
      // console.log(postId,'hhsiodfhiuosdhafpoho');
      // this.$vs.loading();
      let obj = {
        userData: {
          id: this.token.userID,
          uuid: this.token.uuid,
        },
        groupData: {
          id: postId.post_UniqueId,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };

      const { data } = await postsRepository.getPost(obj);

      // this.$vs.loading.close();
      if (data) {
        this.popupActivo = true;
        // this.$vs.loading.close();
        if (data.length > 0) {
          // console.log(data[0]);
          // console.log(data[0].blogVideo);
          let groupPost = {};
          this.editpostId = data[0].post_id;
          this.editpost_UniqueId = data[0].post_UniqueId;
          this.setTextareabody(data[0].body);
          this.getcategory();
          this.getauthor();
          this.select3 = data[0].post_category;
          this.select2 = data[0].post_author;
          this.featuredimage = data[0].featured_image;

          this.blogimage = data[0].blogImage;
          this.blogVideo = data[0].blogVideo;
          this.documentFile = data[0].post_attachedDocument;
          // data.forEach(element => {
          //   element.post_group_mapping_created_datetimestamp = moment(
          //     element.post_group_mapping_created_datetimestamp
          //   ).fromNow();
          //   if (element.image != null) {
          //     if (element.image.includes("uploads/postImages")) {
          //       element.image = this.magittUrl + element.image;
          //     }
          //   }
          //   if (element.video_url != null) {
          //     if (element.video_url.includes("uploads/postVideos")) {
          //       element.video_url = this.magittUrl + element.video_url;
          //     }
          //   }
          //   if (element.audio_url != null) {
          //     if (element.audio_url.includes("uploads/postAudios")) {
          //       element.audio_url = this.magittUrl + element.audio_url;
          //     }
          //   }
          //   if (element.featured_image != null) {
          //     if (element.featured_image.includes("uploads/postImages")) {
          //       element.featured_image =
          //         this.magittUrl + element.featured_image;
          //     }
          //   }
          //   if (element.PollQuestion !== null) {
          //     element.VotedIds =
          //       element.VotedIds !== null ? element.VotedIds : "";
          //     element.AnswersIds =
          //       element.AnswersIds !== null
          //         ? element.AnswersIds.split(",")
          //         : [];
          //     element.PollAnswers =
          //       element.PollAnswers !== null
          //         ? element.PollAnswers.split(",")
          //         : [];
          //     element.AnswersVotes =
          //       element.AnswersVotes !== null
          //         ? element.AnswersVotes.split(",")
          //         : [];
          //   }
          //   element.comments = [];
          //   element.newComment = "";
          //   element.comments = [];
          //   element.newComment = "";
          //   element.first_comment_replies = [];
          //   groupPost = element;

          //   this.$store.dispatch("storePostPopupData", groupPost);

          // });
        }
      } else {
        // this.$vs.loading.close();
      }
    },
    setTextareatitle(val) {
      this.setTextarea1 = val;
    },
    setTextareabody(val) {
      this.setTextarea2 = val;
    },

    handleeditchannels(data) {
      console.log("datadatadatadatadata", data);
      this.editPopUp = true;
      this.uniquid = data.group.group.group_UniqueId;
      this.grouptypeid = data.group.group_type_id;
      this.groupName = data.group.group_name;
      this.groupDescription = data.group.group_description;
      this.group_all_time_group = data.group.group_all_time_group;
      this.photourl = data.group.group_icon;
    },
    // successUpload() {
    //   this.$vs.notify({
    //     color: "success",
    //     title: "Upload Success",
    //     text: "Lorem ipsum dolor sit amet, consectetur",
    //   });
    // },

    async handleeditchannel(id) {
      console.log("dfdsfsdfsdfdsf", this.token);
      // this.$vs.loading();
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        status: 1,
        groupId: id,
        name: this.token.username,
      };

      let isDelete = await this.deleteAlert("channel");

      if (isDelete.isConfirmed) {
        const { data } = await groupsRepository.deleteGroup(payload);
        console.log("datadata", data);

        if (data) {
          this.$swal({
            title: `Channel deleted`,
            icon: "success",
          });
          window.location.reload();
        }
      }
    },

    getTime(dateSent) {
      const date = dateSent ? new Date(dateSent * 1000) : new Date();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${hours > 9 ? hours : "0" + hours}:${
        minutes > 9 ? minutes : "0" + minutes
      }`;
    },
    lastDate(lastDate, lastMessage, updatedDate) {
      const monthes = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const msgLastDate = lastMessage
        ? new Date(lastDate * 1000)
        : new Date(updatedDate);
      const msgYear = msgLastDate.getFullYear();
      const msgMonth = msgLastDate.getMonth();
      const msgDate = msgLastDate.getDate();
      const msgDay = msgLastDate.getDay();
      const msgHours = msgLastDate.getHours();
      const msgMinutes = msgLastDate.getMinutes();
      const LastDate = new Date();
      const curYear = LastDate.getFullYear();
      const curMonth = LastDate.getMonth();
      const curDate = LastDate.getDate();
      const curDay = LastDate.getDay();

      if (curYear > msgYear) {
        return `${monthes[msgMonth]} ${msgDate}, ${msgYear}`;
      } else if (curMonth > msgMonth) {
        return `${monthes[msgMonth]} ${msgDate}`;
      } else if (curDate > msgDate + 6) {
        return `${monthes[msgMonth]} ${msgDate}`;
      } else if (curDay > msgDay) {
        return `${days[msgDay]}`;
      } else {
        return `${msgHours > 9 ? msgHours : "0" + msgHours}:${
          msgMinutes > 9 ? msgMinutes : "0" + msgMinutes
        }`;
      }
    },
  },

  components: {
    DownloadDocuments,
    Promotepopup,
    videoPlayer,
    VuePerfectScrollbar,
    ChatContact,
    Cropper,
    //UserProfile,
    // ChatNavbar,
    // ChatLog,

    AddPosts,
    ModifyGroup,
    PollView,
    LinkPrevue,
    "twemoji-textarea": TwemojiTextarea,
    ViewPostSidebar,
    quillEditor,
    GroupMembersSidebar,
    PostComment,
    "v-select": vSelect,
    UserAvatar,
    ChannelOwnerLeaveSidebar,
    BlogEditor,
    UploadDocuments,
  },
  async created() {
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(localStorage.getItem("token"));
    this.setSidebarWidth();
    let token = jwt.decode(newtoken);
    let payload = {
      userData: {
        id: token.userID,
      },
      communityData: {
        communityName: currentCommunityName.communityName,
      },
    };
    const { data } = await profileRepository.getAllUserData(payload);
    if (data[0].communityManager_Is_owner == 1) {
      this.initialRole = "superadmin";
    } else if (data[0].communityManager_UserId != null) {
      this.initialRole = "admin";
    } else if (data[0].usersDetails_Password == null) {
      this.initialRole = "normal";
    } else {
      this.initialRole = "editor";
    }
  },
  mounted() {
    this.currentUserRole();
    if (this.$router.currentRoute.params.groupId) {
      this.showGroups = true;
    }
    if (this.$store.state.groupId == undefined) {
      this.getUniverseGroup().then(() => {
        this.reInit();
      });
    } else {
      //this.$refs.infiniteLoading.stateChanger.reset();
      this.wasSidebarOpen = this.$store.state.reduceButton;
      this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
      this.getGroupDetails();
      this.getGroupList();

      this.toggleChatSidebar(true);
      //this.createSession()
      //this.getDialog();
      // this.getMessage();
    }
    this.setSectionDisplay();

    // console.log(this.group.group_name, "jhgjhg");
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(localStorage.getItem("token"));

    let token = jwt.decode(newtoken);
  },
  beforeDestroy() {
    if (!this.wasSidebarOpen) this.$store.commit("TOGGLE_REDUCE_BUTTON", false);
    this.$store.unregisterModule("chat");
  },
};
</script>

<style lang="scss">
@import "@/Core/assets/scss/vuexy/pages/profile.scss";

.con-vs-popup .vs-popup,
.vs-popup--close {
  -webkit-box-shadow: 0 0px 20px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 0%);
}
.vs-popup--background {
  background: rgba(20, 19, 20, 0.01);
}
.con-select {
  width: 100%;
}
.con-input-upload {
  width: 100%;
  margin: 0px;
}
.vs-upload--button-upload {
  top: -35px;
}
.wid-hei-9rem {
  width: 9rem;
  height: 9rem;
}
.desc {
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.7);
  padding-left: 5px;
  font-weight: 700;
}
.upload {
  width: 9rem;
  height: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashed-border {
  border: 1.5px dashed rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
  cursor: pointer;
}
.border-point5 {
  border: 0.5px solid #303030;
}
.setinput {
  height: 100%;
  position: absolute;
  /* position: absolute; */
  /* display: none; */
  top: 0;
  top: 321px;
  background: transparent;
  cursor: pointer;
  /* visibility: hidden; */
  /* display: none; */
  top: 0;
  width: 100%;
  opacity: 0;
  left: 0;
  border: 1px solid;
}
.setheight {
  height: 400px;
}
</style>
