<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="postimgblur-parent" v-if="showLocal">
    <div class="flex relative cust-h-webkit">
      <div class="w-3/4 cust-blurbg">
        <div class="post-media-container cust-midcenter">
          <div class>
            <div
              class="blurbackarrow cursor-pointer"
              @click="showLocal = false"
            >
              <feather-icon class="cust-colorwhite" icon="XIcon"></feather-icon>
            </div>
          </div>
          <PostMedia :groupPost="groupPost"></PostMedia>
          <!-- <img
            class="mediaPost-Image mediaPostblureff-mh cursor-pointer"
            src="./../../../assets/images/Activities/check.jpg"
          /> -->
        </div>
      </div>

      <div class="w-1/4 card-bg cust-mgblur">
        <div class="cust-addborder">
          <div class="post-header flex">
            <div class="w-full flex">
              <PostAuthor :groupPost="groupPost"></PostAuthor>

              <div class="mt-4 mr-4 grid">
                <PostLikes :groupPost="groupPost"></PostLikes>
                <!-- <feather-icon class icon="HeartIcon"></feather-icon>
                <span class="likes-fonts text-center">Like</span> -->
              </div>
            </div>
          </div>
          <div class="postcaptionsec pd-10">
            <PostCaption :groupPost="groupPost"></PostCaption>
          </div>
        </div>
        <PostCommentsView :groupPost="groupPost"></PostCommentsView>
      </div>
    </div>
  </div>
</template>

<script>
import PostCaption from "./components/PostCaption";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const postsRepository = RepositoryFactory.get("posts");
import "@/Core/Views/Commoncss/componentCss/allSidebar.css";
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import PostLikes from "./components/PostLikes";
import PostAuthor from "./components/PostAuthor";
import PostMedia from "./components/PostMedia";
import PostCommentsView from "./components/PostCommentsView";
import "@/Core/Views/Commoncss/componentCss/GroupPage.css";
export default {
  props: {
    groupPost: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      else {
        let token = localStorage.getItem("token");
        this.token = jwt.decode(token);
      }
    }
  },
  data() {
    return {
      viewAll: false,
      blurpostopen: false
    };
  },
  computed: {
    showLocal: {
      get() {
        return this.show;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      }
    }
  },
  methods: {
    async getComments() {
      this.viewAll = true;
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: this.groupPost.post_UniqueId
      };

      const { data } = await postsRepository.getComments(obj);

      if (data.ResponseCode == 100) {
        // if (!this.$acl.check("admin")) {
        //   this.groupPost.comments = data.Data.filter(x => x.comments_userId != this.token.uuid)
        // } else {
        //   this.groupPost.comments = data.Data;
        // }
        data.Data.forEach(element => {
          if (!this.$acl.check("admin")) {
            if (element.comments_userId == this.token.uuid) {
              element.showReply = false;
              element.showReplies = false;
              element.newReply = "";
              element.replies = [];
              this.groupPost.comments.push(element);
            }
          } else {
            element.showReply = false;
            element.showReplies = false;
            element.newReply = "";
            element.replies = [];
            this.groupPost.comments.push(element);
          }
        });
      }
    }
  },
  mounted() {},
  components: {
    PostCaption,
    PostLikes,
    PostAuthor,
    PostMedia,
    PostCommentsView
  },

  created() {}
};
</script>
