<template>
  <div class="flex items-center smp_text_pddd w-full smp_top_10">
    <div>
      <UserAvatar
        :image="groupPost.usersDetails_UserImage"
        :text="groupPost.usersDetails_UserName"
        :size="'45px'"
        :userID="groupPost.usersDetailsId"
        @imageNotFound="groupPost.usersDetails_UserImage = null"
      />
    </div>
    <div class="ml-4">
      <h6 class="h-5-clsbold cursor-pointer fontWeight-600 h4-class">
        {{ groupPost.usersDetails_UserName }}
      </h6>
      <span class="textmute-usermes h4-class">{{
        groupPost.post_group_mapping_created_datetimestamp
      }}</span>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
export default {
  props: {
    groupPost: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    UserAvatar
  }
};
</script>
