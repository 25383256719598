var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-likeheart likePost__btn"},[_c('feather-icon',{staticClass:"mr-2 groups_like_icon vs-con-loading__container",attrs:{"icon":"HeartIcon","id":"meralikeloader","svgClasses":{
      'text-danger fill-current stroke-current': _vm.groupPost.is_active
    }},on:{"click":function($event){_vm.openLoading();
      _vm.likePost(
        _vm.groupPost.is_active,
        _vm.groupPost.postId,
        _vm.groupPost.likeId,
        _vm.groupPost.post_user_id,
        _vm.groupPost.post_UniqueId,
        _vm.groupPost.usersDetails_UniqueId
      );}}}),(_vm.groupPost.LIKECOUNT == null || _vm.groupPost.LIKECOUNT == 0)?_c('span',{staticClass:"likes-fonts text-center"},[_vm._v("Like")]):(_vm.groupPost.LIKECOUNT == 1)?_c('span',{staticClass:"likes-fonts post-liketick"},[_vm._v(_vm._s(_vm.groupPost.LIKECOUNT)+" Like")]):_c('span',{staticClass:"likes-fonts"},[_vm._v(_vm._s(_vm.groupPost.LIKECOUNT)+" Likes")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }