<template>
  <div>
    <swiper
      :options="swiperGroupsOption"
      ref="mySwiper"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :key="$vs.rtl"
    >
      <swiper-slide v-for="(item, index) of imgs" :key="'item-' + index">
        <img
          :src="item"
          alt="image"
          class="object-cover w-full h-24"
          @click="selectedimage(item)"
        />
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperGroupsOption: {
        observer: true,
        observeParents: true,
        slidesPerView: 4.3,
        centeredSlides: false,
        spaceBetween: 10,
        // pagination: {
        //   el: ".swiper-pagination",
        //   type: "fraction",
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        breakpoints: {
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          1240: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          375: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
        },
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
      },
      imgs: [],
    };
  },

  methods: {
    blogImgs() {
      for (let index = 1; index <= 100; index++) {
        this.imgs.push(
          require(`@/Core/assets/images/home/blogs/blogsimages/blog${index}.webp`)
        );
      }
    },
    selectedimage(image) {
      this.$emit("selectedimage", image);
    },
  },

  mounted() {
    this.blogImgs();
  },
};
</script>
