<template>
  <div class="smp_post_comment smp_socl_mdl_cm groupspage_text">
    <div>
      <UserAvatar
        :image="$store.state.user.userImage"
        :text="$store.state.user.username"
        :userID="$store.state.user.userID"
        :size="'35px'"
        @imageNotFound="$store.dispatch('storeUserImage', null)"
      />
    </div>
    <vs-input
      class="smp_plce_comment smp_cmnt_border smp_social_page_mg_btt ml-2"
      placeholder="Write a commments......"
      vs-placeholder="Nombre"
      v-model="groupPost.newComment"
      @keydown.enter="
        addComment(
          groupPost.post_UniqueId,
          groupPost.newComment,
          groupPost.usersDetails_UniqueId
        )
      "
    />
    <div class="send_icon_setting send__icon">
      <a v-if="groupPost.newComment != ''" class>
        <i
          class="feather icon-send feather-20 text-primary"
          icon="icon-send"
          @click="
            addComment(
              groupPost.post_UniqueId,
              groupPost.newComment,
              groupPost.usersDetails_UniqueId
            )
          "
        ></i>
      </a>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const postsRepository = RepositoryFactory.get("posts");
const notificationRepository = RepositoryFactory.get("notifications");
export default {
  props: {
    groupPost: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    UserAvatar
  },
  methods: {
    async addComment(postId, comment, uuid) {
      //insertComment
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
        userId: this.$store.state.user.uuid,
        comment: comment,
        commenterName: this.$store.state.user.username
      };

      const { data } = await postsRepository.insertComment(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        let post = this.groupPost;
        post.comments.push({
          usersDetails_UserImage: this.$store.state.user.userImage,
          usersDetails_UserName: this.$store.state.user.username,
          comments_commentedBy: this.$store.state.user.username,
          comments_comment: comment,
          replyShow: false,
          replies: [],
          showReplies: false
        });
        // }
        this.insertCommentNotification(postId, uuid);
        post.newComment = "";

        post.comment_count =
          post.comment_count == null ? 1 : parseInt(post.comment_count) + 1;
      }
    },
    async insertCommentNotification(postId, userId) {
      if (this.$store.state.user.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.$store.state.user.uuid,
            initiaterName: this.$store.state.user.username,
            initiaterImage: this.$store.state.user.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "commented on your post",
            notificationTitle: "Comments",
            notificationEntityType: "Post",
            notificationEntityId: postId,
            notificationReadStatus: 0
          },
          communityData: {
            communityName: currentCommunityName.communityName
          }
        };
        await notificationRepository.addNotification(payload);
      }
    }
  }
};
</script>
