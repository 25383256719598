<template>
  <div class="post-likeheart likePost__btn">
    <!-- <main>
        <input class="l" type="checkbox" />
    </main>-->
    <feather-icon
      class="mr-2 groups_like_icon vs-con-loading__container"
      icon="HeartIcon"
      id="meralikeloader"
      :svgClasses="{
        'text-danger fill-current stroke-current': groupPost.is_active
      }"
      @click="
        openLoading();
        likePost(
          groupPost.is_active,
          groupPost.postId,
          groupPost.likeId,
          groupPost.post_user_id,
          groupPost.post_UniqueId,
          groupPost.usersDetails_UniqueId
        );
      "
    ></feather-icon>
    <span
      class="likes-fonts text-center"
      v-if="groupPost.LIKECOUNT == null || groupPost.LIKECOUNT == 0"
      >Like</span
    >
    <span class="likes-fonts post-liketick" v-else-if="groupPost.LIKECOUNT == 1"
      >{{ groupPost.LIKECOUNT }} Like</span
    >
    <span class="likes-fonts" v-else>{{ groupPost.LIKECOUNT }} Likes</span>
  </div>
</template>

<script>
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const postsRepository = RepositoryFactory.get("posts");
const notificationRepository = RepositoryFactory.get("notifications");
export default {
  props: {
    groupPost: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      colorLoading: this.$vs.theme.primary,
      token: {}
    };
  },
  methods: {
    openLoading() {
      this.$vs.loading({
        color: this.colorLoading,
        container: "#meralikeloader",
        scale: 0.45
      });
      setTimeout(() => {
        this.$vs.loading.close("#meralikeloader > .con-vs-loading");
      }, 3000);
    },
    async likePost(status, postId, likeId, userId, postuid, uuid) {
      if (likeId) {
        let self = this;
        let payload = {
          likeData: {
            id: likeId,
            isActive: !status,
            ownerName: self.token.username
          },
          communityData: {
            communityName: currentCommunityName.communityName
          }
        };
        const { data } = await postsRepository.editLikedPost(payload);

        if (data.ResponseCode == 100) {
          this.groupPost.is_active = !status;
          if (!status) {
            this.groupPost.LIKECOUNT += 1;
            this.insertLikeNotification(postuid, uuid);
          } else {
            this.groupPost.LIKECOUNT -= 1;
          }
        } else {
          self.$vs.notify({
            title: "Error",
            text: "Something went wrong",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      } else {
        let self = this;
        let payload = {
          likeData: {
            postId: postuid,
            groupId: this.$store.state.groupId,
            userId: self.token.uuid,
            isActive: 1,
            ownerName: self.token.username
          },
          communityData: {
            communityName: currentCommunityName.communityName
          }
        };
        const { data } = await postsRepository.likePost(payload);

        if (data.ResponseCode == 100) {
          this.groupPost.is_active = 1;
          this.groupPost.likeId = data.insertId;
          if (!status) {
            this.groupPost.LIKECOUNT += 1;
            this.insertLikeNotification(postuid, uuid);
          } else {
            this.groupPost.LIKECOUNT -= 1;
          }
        } else {
          self.$vs.notify({
            title: "Error",
            text: "Something went wrong",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      }
    },
    async insertLikeNotification(postId, userId) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "liked your post",
            notificationTitle: "Likes",
            notificationEntityType: "Post",
            notificationEntityId: postId,
            notificationReadStatus: 0
          },
          communityData: {
            communityName: currentCommunityName.communityName
          }
        };
        await notificationRepository.addNotification(payload);
      }
    }
  },
  created() {
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(newtoken);
  }
};
</script>
