<template>
  <div>
    <p
      v-show="groupPost.text != null"
      v-html="groupPost.text"
      class="h3-class"
    ></p>
    <p
      v-if="groupPost.imageText"
      v-html="groupPost.imageText"
      class="Grp-post-content"
    ></p>
    <p
      v-if="groupPost.videoText"
      v-html="groupPost.videoText"
      class="Grp-post-content"
    ></p>
    <p
      v-if="groupPost.audioText"
      v-html="groupPost.audioText"
      class="Grp-post-content"
    ></p>
    <p
      v-if="groupPost.blogText"
      v-html="groupPost.blogText"
      class="Grp-post-content"
    ></p>
  </div>
</template>

<script>
export default {
  props: {
    groupPost: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
