<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="">
      <div class="flex px-4 sidebar-topfixpart bg-primary">
        <feather-icon
          icon="ChevronLeftIcon"
          @click.stop="isSidebarActiveLocal = false"
          class="cursor-pointer"
        ></feather-icon>
        <vs-button class="h2-class pd-10 cust-remove-boxsh">MEMBERS</vs-button>
      </div>
      <div>
        <div
          class="panel-body smp_panel_two ulsection-memr-h h3-class mt-16 mb-4 pl-0"
        >
          <MemberSearch
            :searchTerm="searchTerm"
            :dataArray="memberList"
            :keys="searchKey"
            @searchResult="filterUsers"
          >
            <template slot="searchBar">
              <div
                class="mb-4 invite-disc-search mob-view-serh ml-3 mr-3 flex items-cente"
                style="border-radius:0px !important;"
              >
                <vs-input
                  icon-pack="feather"
                  icon="icon-search"
                  icon-after
                  vs-placeholder="Nombre"
                  class="w-full"
                  v-model="searchTerm"
                />
                <!-- <vs-input icon-pack="feather" icon="icon-search" icon-after vs-placeholder="Nombre" class="view-memb-serachbar" v-model="memberSearch" /> -->
              </div>
            </template>
          </MemberSearch>
          <ul class="media-list w-full">
            <li
              class="media cursor-pointer px-2 "
              v-for="member of members"
              :key="member.usersDetailsId"
              @click.stop="setUser(member)"
            >
              <div class="media-left">
                <UserAvatar
                  :image="member.usersDetails_UserImage"
                  :text="member.usersDetails_UserName"
                  @imageNotFound="member.usersDetails_UserImage = null"
                />
              </div>

              <div class="row media-body ml-3 mt-1 w-full">
                <div
                  color
                  v-show="member.group_admin_id"
                  class="user-admn-btn float-rht"
                >
                  <h5 class="h4-class fontWeight-600">
                    Admin
                  </h5>
                </div>
                <span class="user-text-md">
                  {{ member.usersDetails_UserName }}
                </span>

                <p class="textmute-usermes h4-class">
                  {{ member.usersDetails_TagLine }}
                </p>
              </div>

              <div class="smp_social_prof_sett">
                <a class="smp_social_my_btn h1-class"> </a>
              </div>
            </li>
          </ul>
        </div>
        <div class="memb-pagin">
          <vs-pagination
            :total="membersCount"
            v-model="membersPage"
          ></vs-pagination>
        </div>
        <vs-sidebar
          position-right
          parent="body"
          default-index="1"
          color="primary"
          class="sidebarx"
          spacer
          v-model="active_sidebar"
        >
          <div class="groupage_setting_header h2-class">
            <div class="group_page_topbar">
              <feather-icon
                class="md:inline-flex discussion_title_backarrowpparent smp_cursor-pointer"
                icon="ChevronLeftIcon"
                style
                @click="active_sidebar = !active_sidebar"
              />
              <span class="group_Page_setting h2-class">ACTIONS</span>
            </div>
          </div>

          <ul class="holamundo group_page_sidebar" title="Settings">
            <li
              v-if="
                notAdmin &&
                  selectedUser != token.userID &&
                  !isUniverseGroup &&
                  isGroupAdmin
              "
              color="primary"
              type="flat"
              class="w-full add_margin_bottom group_page_text_align cursor-pointer"
              @click="removeUser(selectedUser)"
            >
              <span
                class="padding-10 font-semibold h5-class chat_slider_user_bold"
                >Remove Member</span
              >
            </li>
            <li
              v-if="
                notAdmin &&
                  selectedUser != token.userID &&
                  isGroupAdmin &&
                  !isReader
              "
              color="primary"
              type="flat"
              class="w-full add_margin_bottom group_page_text_align cursor-pointer"
              @click="makeGroupAdmin(selectedUser)"
            >
              <span
                class="padding-10 font-semibold h5-class chat_slider_user_bold"
                >Make Channel Admin</span
              >
            </li>
            <li
              v-if="
                !notAdmin &&
                  selectedUser != token.userID &&
                  isGroupOwner &&
                  !isReader
              "
              color="primary"
              style
              type="flat"
              class="w-full add_margin_bottom group_page_text_align cursor-pointer"
              @click="removeGroupAdmin(selectedUser)"
            >
              <span
                class="padding-10 font-semibold h5-class chat_slider_user_bold"
                >Remove Channel Admin</span
              >
            </li>
            <li
              color="primary"
              type="flat"
              class="w-full add_margin_bottom group_page_text_align smp_cursor-pointer"
              v-if="selectedUser != token.userID"
              @click="showProfile(selectedUser)"
            >
              <span
                class="padding-10 font-semibold h5-class chat_slider_user_bold"
                >View Member</span
              >
            </li>
            <!-- <li
          color="primary"
          type="flat"
          class="w-full add_margin_bottom group_page_text_align smp_cursor-pointer"
          v-if="selectedUser != token.userID"
        >
          <span class="padding-10 font-semibold h5-class chat_slider_user_bold"
            >Message</span
          >
        </li> -->
            <!-- <li
          color="primary"
          type="flat"
          class="w-full add_margin_bottom group_page_text_align smp_cursor-pointer"
          v-if="!isUniverseGroup"
        >
          <span class="padding-10 font-semibold h5-class chat_slider_user_bold"
            >Exit Group</span
          >
        </li> -->
          </ul>
        </vs-sidebar>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const groupsRepository = RepositoryFactory.get("groups");
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import "@/Core/Views/Commoncss/componentCss/allSidebar.css";
import jwt from "jsonwebtoken";
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
import MemberSearch from "@/Core/Views/CommonPlugins/member-search/MemberSearch";
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      default: () => {}
    },
    isGroupAdmin: {
      type: Boolean,
      default: false
    },
    isGroupOwner: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      else {
        this.memberList = this.data;
      }
    },
    $route() {
      this.active_sidebar = false;
      this.isSidebarActiveLocal = false;
      this.$emit("closeSidebar");
    }
  },
  data() {
    return {
      active_sidebar: false,
      memberList: [],
      // isGroupOwner: null,
      // isGroupAdmin: false,
      notAdmin: null,
      selectedUser: null,
      isReader: false,
      isUniverseGroup: false,
      token: {},
      membersSize: 8,
      membersPage: 1,
      searchTerm: "",
      searchResults: [],
      searchKey: ["usersDetails_UserName"]
    };
  },
  inheritAttrs: false,
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    magittUrl() {
      return this.baseUrlMagitt;
    },
    membersCount() {
      let l = 0,
        s = this.membersSize;
      if (this.searchTerm.length > 1) {
        l = this.searchResults.length;
      } else {
        l = this.memberList.length;
      }
      return Math.ceil(l / s);
    },
    members() {
      const start = (this.membersPage - 1) * this.membersSize,
        end = start + this.membersSize;
      if (this.searchTerm.length > 1) {
        return this.searchResults.slice(start, end);
      } else {
        return this.memberList.slice(start, end);
      }
    }
  },
  methods: {
    setUser(user) {
      this.selectedUser = user.usersDetailsId;
      this.isReader = user.usersDetails_Password == null ? true : false;
      this.notAdmin = user.group_admin_id == null ? true : false;

      if (this.token.userID != user.usersDetailsId) {
        this.active_sidebar = !this.active_sidebar;
      }
    },
    async getUsersInGroup() {
      // this.active = true;
      var self = this;
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName
        },

        groupId: self.$store.state.groupId,
        userId: self.token.userID
      };

      const { data } = await groupsRepository.getUsersInGroup(obj);

      if (data.length > 0) {
        this.memberList = data;
        this.phoneList = data.map(x => x.usersDetails_Mobile);
        this.emailList = data.map(x => x.usersDetails_Email);
        // this.emailList = data.filter(x != null);
        this.groupAdminList = data.filter(x => x.group_admin_id != null);

        this.isGroupOwner = data[0].groupOwner;
        if (
          data.findIndex(x => x.usersDetails_UniqueId == this.token.uuid) != -1
        ) {
          if (
            data[
              data.findIndex(x => x.usersDetails_UniqueId == this.token.uuid)
            ].group_admin_id != null
          ) {
            this.isGroupAdmin = true;
          } else {
            this.isGroupAdmin = false;
          }
        }
      } else {
        this.memberList = [];
        this.phoneList = [];
        this.emailList = [];
        // this.emailList = data.filter(x != null);
        this.groupAdminList = [];

        this.isGroupOwner = data[0].groupOwner;
        if (
          data.findIndex(x => x.usersDetails_UniqueId == this.token.uuid) != -1
        ) {
          if (
            data[
              data.findIndex(x => x.usersDetails_UniqueId == this.token.uuid)
            ].group_admin_id != null
          ) {
            this.isGroupAdmin = true;
          } else {
            this.isGroupAdmin = false;
          }
        }
      }
    },
    showProfile(userid) {
      if (this.active_sidebar) {
        this.active_sidebar = false;
        this.isSidebarActiveLocal = false;
      }
      this.$store.state.memberId = userid;
      this.$router.push("/community-user-profile").catch(() => {});
    },
    async makeGroupAdmin(userId) {
      let self = this;
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName
        },
        groupData: {
          groupId: self.$store.state.groupId,
          userId: userId
        }
      };
      const { data } = await groupsRepository.makeGroupAdmin(payload);

      if (data.ResponseCode == 100) {
        this.active_sidebar = false;
        this.memberList[
          this.memberList.findIndex(x => x.usersDetailsId == userId)
        ].group_admin_id = data.adminId;
        if (this.currentGroupPostingType == 0) {
          this.changePostingRights(userId, 1);
        }
      }
    },
    async removeGroupAdmin(userId) {
      let self = this;
      this.$vs.loading();
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName
        },
        groupData: {
          groupId: self.$store.state.groupId,
          userId: userId
        }
      };
      const { data } = await groupsRepository.removeGroupAdmin(payload);

      if (data.affectedRows > 0) {
        this.active_sidebar = false;
        this.$vs.loading.close();
        this.memberList[
          self.memberList.findIndex(x => x.usersDetailsId === userId)
        ].group_admin_id = null;
        if (this.currentGroupPostingType == 0) {
          this.changePostingRights(userId, 0);
        }
      }
    },
    removeUser(userId) {
      this.$swal({
        title: "Are you sure you want to remove a member",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      }).then(result => {
        if (result.value) {
          this.confirmRemoveUser(userId);
        }
      });
    },
    async confirmRemoveUser(userId) {
      var self = this;
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName
        },
        groupData: {
          groupId: self.$store.state.groupId,
          userId: userId
        }
      };

      const { data } = await groupsRepository.leaveGroup(obj);

      if (data) {
        this.active_sidebar = false;
        this.memberList.splice(
          this.memberList.findIndex(x => x.usersDetailsId == userId),
          1
        );
      }
    },
    filterUsers(result) {
      this.searchResults = result;
      if (this.searchResults.length > 0) {
        this.membersPage = 1;
      }
    }
  },
  mounted() {},
  components: {
    UserAvatar,
    MemberSearch
  },

  created() {
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(newtoken);
  }
};
</script>
