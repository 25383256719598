<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="sidebarx"
    v-model="isSidebarActiveLocal"
  >
    <div class="flex px-4 sidebar-topfixpart bg-primary">
      <feather-icon
        icon="ChevronLeftIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
      <vs-button class="h2-class pd-10 cust-remove-boxsh">LEAVE</vs-button>
    </div>
    <div class="Main-Sectionleave mt-16">
      <MemberSearch
        :searchTerm="searchTerm"
        :dataArray="memberList"
        :keys="searchKey"
        @searchResult="filterUsers"
      >
        <template slot="searchBar">
          <div
            class="mb-4 invite-disc-search mob-view-serh ml-3 mr-3 flex items-cente"
            style="border-radius: 0px !important"
          >
            <vs-input
              icon-pack="feather"
              icon="icon-search"
              icon-after
              vs-placeholder="Nombre"
              class="w-full"
              v-model="searchTerm"
            />
            <!-- <vs-input icon-pack="feather" icon="icon-search" icon-after vs-placeholder="Nombre" class="view-memb-serachbar" v-model="memberSearch" /> -->
          </div>
        </template>
      </MemberSearch>
      <ul class="media-list w-full">
        <li
          class="media cursor-pointer px-2"
          v-for="item in members"
          :key="item.usersDetailsId"
        >
          <div class="media-left cust-mg-5">
            <UserAvatar
              :image="item.usersDetails_UserImage"
              :text="item.usersDetails_UserName"
              :size="'35px'"
              @imageNotFound="item.usersDetails_UserImage = null"
            />
          </div>

          <div class="row media-body ml-3 mt-1 w-full">
            <span class="user-text-md">
              {{ item.usersDetails_UserName }}
            </span>

            <p class="textmute-usermes h4-class">
              {{ item.usersDetails_TagLine }}
            </p>
          </div>
          <vs-radio
            v-model="item.isSelected"
            @change="
              select1 = item.usersDetailsId;
              selecteduuid = item.usersDetails_UniquerId;
            "
            :vs-value="true"
          >
            <span class="pd-10"></span>
          </vs-radio>
        </li>
      </ul>
      <!-- <div class="flex pd-10">
            <vs-select
                placeholder="Select Owner"
                class="selectExample w-full "
                v-model="select1"
                autocomplete
                v-if="memberList.length > 0"
            >
                <vs-select-item
                :key="item.usersDetailsId"
                :value="item.usersDetailsId"
                :text="item.usersDetails_UserName"
                v-for="item in OwnerMemberList"
                />
            </vs-select>
        </div> -->
      <div class="memb-pagin">
        <vs-pagination
          :total="membersCount"
          v-model="membersPage"
        ></vs-pagination>
      </div>
      <div class="">
        <vs-button
          color="danger"
          type="filled"
          class="mob-view-membsBtn w-full addPost-sent-invites"
          @click="MakeOwnerAndLeave()"
          :disabled="!!!select1"
          >Make Owner And LEAVE</vs-button
        >
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const groupsRepository = RepositoryFactory.get("groups");
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
import MemberSearch from "@/Core/Views/CommonPlugins/member-search/MemberSearch";
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    currentGroupId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      else {
        this.memberList = this.data;
      }
    },
    $route(to, from) {
      if (to !== from) {
        this.isSidebarActiveLocal = false;
        this.$emit("closeSidebar");
      }
    },
  },
  components: {
    UserAvatar,
    MemberSearch,
  },
  data() {
    return {
      token: {},
      memberList: [],
      membersSize: 8,
      membersPage: 1,
      searchTerm: "",
      searchResults: [],
      searchKey: ["usersDetails_UserName"],
      select1: "",
      selecteduuid: null,
    };
  },
  created() {
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(newtoken);
  },
  mounted() {},
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    membersCount() {
      let l = 0,
        s = this.membersSize;
      if (this.searchTerm.length > 1) {
        l = this.searchResults.length;
      } else {
        l = this.memberList.length;
      }
      return Math.ceil(l / s);
    },
    members() {
      const start = (this.membersPage - 1) * this.membersSize,
        end = start + this.membersSize;
      if (this.searchTerm.length > 1) {
        return this.searchResults.slice(start, end);
      } else {
        return this.memberList.slice(start, end);
      }
    },
  },
  methods: {
    filterUsers(result) {
      this.searchResults = result;
    },
    async MakeOwnerAndLeave() {
      this.$swal({
        title: "Are you sure you want leave this channel ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.ownerLeaveGroup();
        }
      });
    },
    async ownerLeaveGroup() {
      this.$vs.loading();
      let self = this;
      let nextOwnerIsGroupAdmin =
        self.memberList[
          self.memberList.findIndex((x) => x.usersDetailsId == self.select1)
        ].group_admin_id != null;
      let obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          groupId: self.currentGroupId,
          CurrentOwnerUserName: self.token.username,
          CurrentOwnerUserId: self.token.userID,
          nextOwnerUserId: self.select1,
          nextOwnerIsGroupAdmin: nextOwnerIsGroupAdmin,
        },
      };

      const { data } = await groupsRepository.MakeGroupOwnerAndLeave(obj);

      if (data) {
        this.isSidebarActiveLocal = false;
        this.$vs.loading.close();
        this.$emit("ownerLeaveNotification", this.selecteduuid);
        this.$store.state.groupId = null;
        this.$store.state.groupuuid = null;
        this.$router.push("/community-home").catch(() => {});
      } else {
        this.$vs.loading.close();
      }
    },
  },
};
</script>
