<template>
  <div class="post-media-container">
    <!-- <video-player
        ref="aplayer"
        class
        :options="playerOptionsYoutube(groupPost)"
        v-if="
        groupPost.url != null &&
            (groupPost.url.includes('youtube') ||
            groupPost.url.includes('youtu.be'))
        "
        v-once
    />-->
    <div id="app" v-if="groupPost.url != null">
      <link-prevue :url="groupPost.url" v-if="groupPost.url != null">
        <template slot="loading">
          <!-- set your custom loading -->
        </template>
        <template slot-scope="props">
          <div class="card-block pd-10">
            <h5 class="card-title">{{ props.title }}</h5>
            <p class="card-text">{{ props.description }}</p>
            <a
              target="_blank"
              v-bind:href="props.url"
              class="btn btn-primary more-primarycolr"
              >More</a
            >
          </div>
          <img
            class="mediaPost-Image mediaPostblureff-mh cursor-pointer"
            :src="props.img ? props.img : (props.img = magittUrl + defaultPost)"
            :alt="props.title"
          />
        </template>
      </link-prevue>
    </div>
    <img
      class="mediaPost-Image mediaPostblureff-mh cursor-pointer"
      :src="groupPost.image"
      @error="groupPost.image = magittUrl + defaultPost"
      alt="user-upload"
      v-if="groupPost.image != null"
    />
    <img
      class="mediaPost-Image mediaPostblureff-mh cursor-pointer"
      :src="groupPost.featured_image"
      @error="groupPost.featured_image = magittUrl + defaultPost"
      alt="user-upload"
      v-if="groupPost.featured_image != null"
    />
    <div
      class="pd-10 cust-imgtextmix cust-posttextfeed cust-blurbg"
      v-show="groupPost.body != null"
      v-html="groupPost.body"
    ></div>
    <video-player
      ref="player"
      class
      :options="playerOptions(groupPost)"
      v-if="groupPost.video_url != null"
      v-once
    />

    <audio class="ml-3 mb-3" controls v-if="groupPost.audio_url != null">
      <source :src="groupPost.audio_url" />
    </audio>
    <!-- <video-player
        ref="aplayer"
        class
        :options="playerOptionsAudio(groupPost)"
        v-else-if="groupPost.audio_url != null"
        v-once
        @click.stop="viewSinglePost(groupPost)"
    />-->
  </div>
</template>

<script>
import LinkPrevue from "link-prevue";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
require("videojs-youtube");
export default {
  props: {
    groupPost: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    LinkPrevue,
    videoPlayer
  },
  data() {
    return {
      defaultPost: "uploads/defaultImages/default_post.png"
    };
  },
  computed: {
    playerOptions() {
      return media => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ src: media.video_url }],
          poster: media.poster
        };
      };
    },
    playerOptionsAudio() {
      return media => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ src: media.audio_url }],
          poster:
            this.magittUrl + "uploads/defaultImages/default_audio_post_wide.jpg"
        };
      };
    },
    playerOptionsYoutube() {
      return media => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          techOrder: ["youtube"],
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ type: "video/youtube", src: media.url }]
        };
      };
    },
    magittUrl() {
      return this.baseUrlMagitt;
    }
  }
};
</script>
