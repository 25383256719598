var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smp_post_comment smp_socl_mdl_cm groupspage_text"},[_c('div',[_c('UserAvatar',{attrs:{"image":_vm.$store.state.user.userImage,"text":_vm.$store.state.user.username,"userID":_vm.$store.state.user.userID,"size":'35px'},on:{"imageNotFound":function($event){return _vm.$store.dispatch('storeUserImage', null)}}})],1),_c('vs-input',{staticClass:"smp_plce_comment smp_cmnt_border smp_social_page_mg_btt ml-2",attrs:{"placeholder":"Write a commments......","vs-placeholder":"Nombre"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addComment(
        _vm.groupPost.post_UniqueId,
        _vm.groupPost.newComment,
        _vm.groupPost.usersDetails_UniqueId
      )}},model:{value:(_vm.groupPost.newComment),callback:function ($$v) {_vm.$set(_vm.groupPost, "newComment", $$v)},expression:"groupPost.newComment"}}),_c('div',{staticClass:"send_icon_setting send__icon"},[(_vm.groupPost.newComment != '')?_c('a',{},[_c('i',{staticClass:"feather icon-send feather-20 text-primary",attrs:{"icon":"icon-send"},on:{"click":function($event){return _vm.addComment(
            _vm.groupPost.post_UniqueId,
            _vm.groupPost.newComment,
            _vm.groupPost.usersDetails_UniqueId
          )}}})]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }