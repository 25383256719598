<template>
  <div>
    <div class="flex likes-com-clss p-3 justify-between">
      <CommentCount :groupPost="groupPost"></CommentCount>
    </div>
    <post-comment :post="groupPost" />
    <div class="custblur-comment">
      <WriteComment :groupPost="groupPost"></WriteComment>
    </div>
  </div>
</template>

<script>
import WriteComment from "./WriteComment";
import CommentCount from "./CommentCount";
import PostComment from "./PostComment.vue";
export default {
  props: {
    groupPost: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    WriteComment,
    CommentCount,
    PostComment
  }
};
</script>
