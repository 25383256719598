export default {
  methods: {
    getHex(res) {
      const uint = new Uint8Array(res);
      let bytes = [];
      uint.forEach(byte => {
        bytes.push(byte.toString(16));
      });
      const hex = bytes.join("").toUpperCase();

      return this.getMimetype(hex);
    },
    getMimetype(signature) {
      switch (signature) {
        case "89504E47": //.png
          return "image/png";
        case "47494638": //.gif
          return "image/gif";
        case "25504446": //.pdf
          return "application/pdf";
        case "FFD8FFDB":
        case "FFD8FFE0": //.jpg, .jpeg
          return "image/jpeg";
        case "66747970": //.mp4
          return "video/mp4";
        case "3026B275": //.wmv
          return "video/x-ms-wmv";
        case "52494646": //.avi
          return "video/x-msvideo";
        case "1A45DFA3": //.webm, .mkv
          return "video / webm";
        case "FFFBFF":
        case "494433":
          return "audio/mpeg"; //.mp3
        // case "52494646":
        //   return "audio/wav"; //.wav
        default:
          return "Unknown filetype";
      }
    }
  }
};
