<!-- =========================================================================================
    File Name: ChatContact.vue
    Description: Chat contact - single component for chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="chat__contact flex items-center px-2"
    v-bind:class="{ activate: isGroupSelected }"
  >
    <div class="contact__avatar mr-1">
      <vs-avatar
        class="bg-dark"
        size="45px"
        color="rgb(200, 21, 129)"
        :text="avatarname"
        :image="photo"
        @imageNotFound="contact.photoURL = null"
      />
    </div>
    <div
      class="contact__container w-full items-center justify-between overflow-hidden pt-4"
    >
      <div class="contact__info flex flex-col truncate w-full">
        <div class="flex cust-h-20 justify-between">
          <h5 class="h5-class" :class="{ 'text-white': isActiveChatUser }">
            {{ contact.name }}
          </h5>
          <div
            color
            v-if="contact.isPrivateGroup || contact.isPublicGroup"
            class="upublic-custchips font-semibold"
          >
            <vs-button
              class="bg-primary dashboard_font_size_text Grp-join-btn"
              size="small"
              type="filled"
              >Join</vs-button
            >
          </div>
          <div class="flex justify-center items-center">
            <div v-if="contact.isGroupAdmin">
              <vs-button
                radius
                color="primary"
                type="flat"
                icon-pack="feather"
                icon="icon-award"
              ></vs-button>
            </div>
            <div class="mt-2 cursor-pointer lg:block md:block hidden">
              <vs-dropdown vs-trigger-click>
                <a class="flex items-center">
                  <i class="material-icons mr-2">expand_more</i>
                </a>

                <vs-dropdown-menu class="eddit">
                  <vs-dropdown-item @click="handleeditchannel(contact.uniquid)"
                    >Delete</vs-dropdown-item
                  >
                  <vs-dropdown-item @click="handleeditchannels(contact)"
                    >Edit</vs-dropdown-item
                  >
                </vs-dropdown-menu>
              </vs-dropdown>
              <vs-popup
                class="holamundo lg:mt-12"
                title="Edit Channel"
                :active.sync="popupActivo"
              >
                <div class="">
                  <!-- <div class="">
                    <vs-upload
                     
                    />
                  </div> -->
                  <div class="vx-col w-full">
                    <div>
                      <div class="img-uploadBox img-uploadvrop-h mg-left-right">
                        <div class>
                          <div class="relative" v-if="photourl != ''">
                            <img
                              class="setheight w-full"
                              :src="magittUrl + photourl"
                              alt=""
                            />
                            <div
                              class="row top-0 absolute right-0"
                              @click="photourl = ''"
                            >
                              <feather-icon
                                icon="XIcon"
                                svgClasses="h-6 w-6"
                                class=""
                              />
                            </div>
                          </div>
                          <div class="relative" v-if="imagepreview != null">
                            <div class="w-full">
                              <img
                                class="w-full"
                                :src="imagepreview"
                                alt="channel imgage"
                              />
                            </div>
                            <div
                              class="row top-0 absolute right-0"
                              @click="(imagepreview = null), (imagefile = null)"
                            >
                              <feather-icon
                                icon="XIcon"
                                svgClasses="h-6 w-6"
                                class=""
                              />
                            </div>
                          </div>

                          <div
                            class="w-full relative"
                            v-if="imagepreview == null && photourl == ''"
                          >
                            <div class="modify-up-childPd">
                              <div class>
                                <div class="row">
                                  <feather-icon
                                    class="icon feather icon-camera img-upload-centicon"
                                    icon="icon-camera"
                                  >
                                  </feather-icon>
                                </div>
                                <input
                                  @change="uploadPostImages"
                                  class="setinput"
                                  type="file"
                                  accept="image/*"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <vs-button
                          icon-pack="feather"
                          class="modify-edit-icon mt-2"
                          onclick="document.getElementById('edit-group-image').click();"
                          type="transparent"
                          radius
                          icon="icon-edit-2"
                          >Change Picture</vs-button
                        > -->
                      </div>
                      <div>
                        <!-- <input
                      id="edit-group-imagessssss"
                      type="file"
                      accept="image/*"
                      class="hidden"
                      @change="uploadPostImages"
                    /> -->
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 mb-2 px-2">
                    <vs-input
                      label="Channel Name"
                      placeholder="Channel Name"
                      v-model="groupName"
                      class="w-full"
                    />
                  </div>
                  <div class="mt-4 mb-2 px-2">
                    <vs-input
                      label="Channel Description"
                      placeholder="Channel Description"
                      v-model="groupDescription"
                      class="w-full"
                    />
                  </div>
                  <!-- <div class="w-full ml-0 px-2 mt-4 flex"> -->
                  <!-- <div class="grp_typ_text mb-2 mr-3">
                      <span class="spce-grp font-medium">Channel type</span>
                    </div> -->

                  <!-- <div class="ModifyGroup_switch_field">
                      <input
                        type="radio"
                        id="radio-one"
                        value="1"
                        v-model="groupType"
                        checked
                      />
                      <label for="radio-one" class="font-medium">Private</label>

                      <input
                        type="radio"
                        id="radio-three"
                        value="3"
                        v-model="groupType"
                      />
                      <label for="radio-three" class="font-medium"
                        >System</label
                      >
                    </div> -->
                  <!-- </div> -->
                  <!-- <div class="w-full ml-0 px-2 mt-4 flex">
                    <div class="grp_typ_text mb-2 mr-3">
                      <span class="spce-grp font-medium">Posting Rights</span>
                    </div>

                    <div class="ModifyGroup_switch_field">
                      <input
                        type="radio"
                        id="radio-p-one"
                        value="0"
                        v-model="postingRight"
                        checked
                      />
                      <label for="radio-p-one" class="font-medium"
                        >Admin Only</label
                      >
                      <input
                        type="radio"
                        id="radio-p-two"
                        value="1"
                        v-model="postingRight"
                      />
                      <label for="radio-p-two" class="font-medium"
                        >Anyone</label
                      >
                    </div>
                  </div> -->
                  <div class="mt-20" @click="editgroup">
                    <vs-button type="filled" class="w-full border-rem h2-class"
                      >SAVE</vs-button
                    >
                  </div>
                </div>
              </vs-popup>
            </div>
            <div class="lg:hidden md:hidden block">
              <vs-dropdown vs-trigger-click>
                <vs-button
                  radius
                  color="primary"
                  type="flat"
                  icon-pack="feather"
                  icon="icon-more-vertical"
                ></vs-button>

                <vs-dropdown-menu class="sm-w-130px">
                  <vs-dropdown-item> View Members </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="handleeditchannels(contact.uniquid)"
                  >
                    Edit</vs-dropdown-item
                  >
                  <vs-dropdown-item @click="handleeditchannel(contact)">
                    Delete
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </div>
        </div>
        <span
          class="pending-usermes h4-class"
          color="primary"
          v-if="contact.JOINREQUESTCOUNT"
          >{{ contact.JOINREQUESTCOUNT }} Pending Request</span
        >
        <span
          class="pending-usermes h4-class"
          color="warning"
          v-else-if="contact.requestSent != null"
          >Request Sent</span
        >
        <span
          class="textmute-usermes cust-h-20 h4-class"
          v-html="contact.last_message"
          v-else
          >{{ contact.last_message }}</span
        >
      </div>
      <p
        class="chat_text-green chat_user_time"
        v-if="ticontainerForCurrentUser"
      >
        typing...
      </p>
      <div class="chat__contact__meta flex self-start flex-col items-end w-1/6">
        <p class="textmute-user-time chat_user_time">
          {{ contact.group_modified_date }}
        </p>
        <vs-chip class="number" color="primary" v-if="unseenMsg">{{
          unseenMsg
        }}</vs-chip>
      </div>
    </div>
  </div>
</template>

<script>
import "@/Core/Views/Commoncss/componentCss/MembersPage.css";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const groupsRepository = RepositoryFactory.get("groups");
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import jwt from "jsonwebtoken";
import { Cropper } from "vue-advanced-cropper";
export default {
  data() {
    return {
      imagepreview: null,
      imagefile: null,
      group_all_time_group: "",
      grouptypeid: "",
      uniquid: "",
      token: "",
      popupActivo: false,
      groupName: "",
      groupDescription: "",
      photourl: "",
    };
  },
  props: {
    contact: { type: Object, required: true },
    isActiveChatUser: { type: Boolean },
    lastMessaged: { type: String, default: "" },
    showLastMsg: { type: Boolean, default: false },
    unseenMsg: { type: Number, default: 0 },
    ticontainerForCurrentUser: { type: Boolean, default: false },
  },
  components: {
    Cropper,
  },

  methods: {
    uploadPostImages(e) {
      const files = e.target.files[0];
      this.imagepreview = URL.createObjectURL(files);
      const reader = new FileReader();
      reader.onload = () => {
        this.imagefile = reader.result;
      };
      reader.readAsDataURL(files);
    },
    async editgroup() {
      this.$vs.loading();
      let obj = {
        groupData: {
          groupName: this.groupName,
          groupDescription: this.groupDescription,
          groupIcon: this.imagefile,
          status: 1,
          ownerName: this.token.username,
          typeId: this.grouptypeid,
          id: this.uniquid,
          allTimeGroup: this.group_all_time_group,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
          communityID: "b2146af0-15c3-11ea-b55d-bdde97cb4377",
          communitiesType: 1,
          communitiesSubType: 0,
        },
      };
      const { data } = await groupsRepository.editGroup(obj);
      if (data.ResponseCode == 100) {
        this.popupActivo = false;
        this.$vs.loading.close();
        this.$swal({
          icon: "success",
          title: "channel edit successfully",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        this.$vs.loading.close();
      }
    },

    handleeditchannels(data) {
      console.log("datadatadatadatadata", data);
      this.uniquid = data.id;
      this.grouptypeid = data.grouptypeid;
      this.groupName = data.name;
      this.groupDescription = data.last_message;
      this.group_all_time_group = data.group_all_time_group;
      this.popupActivo = true;
      this.photourl = data.photoURL;
    },
    // successUpload() {
    //   this.$vs.notify({
    //     color: "success",
    //     title: "Upload Success",
    //     text: "Lorem ipsum dolor sit amet, consectetur",
    //   });
    // },
    deleteAlert(option) {
      // return this.$swal({
      //   title: `Are you sure you want to delete this channel ?`,
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "Yes",
      // });

      return this.$swal({
        icon: "warning",
        title: "Are you sure you want to delete this channel ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });
    },
    async handleeditchannel(id) {
      console.log("dfdsfsdfsdfdsf", this.token);
      // this.$vs.loading();
      let payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        status: 1,
        groupId: id,
        name: this.token.username,
      };

      let isDelete = await this.deleteAlert("post");

      if (isDelete.isConfirmed) {
        const { data } = await groupsRepository.deleteGroup(payload);
        console.log("datadata", data);

        if (data) {
          this.$swal({
            title: `Channel deleted`,
            icon: "success",
          });
          window.location.reload();
        }
      }
    },

    getTime(dateSent) {
      const date = dateSent ? new Date(dateSent * 1000) : new Date();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${hours > 9 ? hours : "0" + hours}:${
        minutes > 9 ? minutes : "0" + minutes
      }`;
    },
    lastDate(lastDate, lastMessage, updatedDate) {
      const monthes = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const msgLastDate = lastMessage
        ? new Date(lastDate * 1000)
        : new Date(updatedDate);
      const msgYear = msgLastDate.getFullYear();
      const msgMonth = msgLastDate.getMonth();
      const msgDate = msgLastDate.getDate();
      const msgDay = msgLastDate.getDay();
      const msgHours = msgLastDate.getHours();
      const msgMinutes = msgLastDate.getMinutes();
      const LastDate = new Date();
      const curYear = LastDate.getFullYear();
      const curMonth = LastDate.getMonth();
      const curDate = LastDate.getDate();
      const curDay = LastDate.getDay();

      if (curYear > msgYear) {
        return `${monthes[msgMonth]} ${msgDate}, ${msgYear}`;
      } else if (curMonth > msgMonth) {
        return `${monthes[msgMonth]} ${msgDate}`;
      } else if (curDate > msgDate + 6) {
        return `${monthes[msgMonth]} ${msgDate}`;
      } else if (curDay > msgDay) {
        return `${days[msgDay]}`;
      } else {
        return `${msgHours > 9 ? msgHours : "0" + msgHours}:${
          msgMinutes > 9 ? msgMinutes : "0" + msgMinutes
        }`;
      }
    },
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
    currentGroup() {
      return this.$store.state.Group.groupId;
    },
    selectedGroup() {
      return this.contact.id;
    },
    isGroupSelected() {
      return this.currentGroup === this.selectedGroup;
    },
    avatarname() {
      if (this.contact.name.length > 1) {
        return this.contact.name.trim()[0].toUpperCase();
      }
    },
    photo() {
      if (this.contact.photoURL != null) {
        return this.baseUrlMagitt + this.contact.photoURL;
      }
    },
  },
  mounted() {
    console.log(this.contact.name, "jhgjhg");
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(localStorage.getItem("token"));

    let token = jwt.decode(newtoken);
  },
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
.border-point5 {
  border: 0.5px solid #303030;
}
.setinput {
  height: 100%;
  position: absolute;
  /* position: absolute; */
  /* display: none; */
  top: 0;
  top: 321px;
  background: transparent;
  cursor: pointer;
  /* visibility: hidden; */
  /* display: none; */
  top: 0;
  width: 100%;
  opacity: 0;
  left: 0;
  border: 1px solid;
}
.setheight {
  height: 400px;
}
</style>
