<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div v-show="showCreatePoll">
      <div class="flex px-4 sidebar-topfixpart bg-primary">
        <feather-icon
          icon="ChevronLeftIcon"
          @click.stop="isSidebarActiveLocal = false"
          class="cursor-pointer"
        ></feather-icon>
        <vs-button class="h2-class pd-10 cust-remove-boxsh">ADD POLL</vs-button>
      </div>
      <div class="pd-10">
        <div class="vx-row mb-4 addPol-topsec">
          <div class="vx-col w-full">
            <vs-input
              class="w-full input-field Addpoll_input_field"
              placeholder="Type a question?"
              v-model="pollquestion"
              style="margin-bottom: 10px"
            />
          </div>
        </div>
      </div>
      <div
        class="vx-row mb-2 mx-2"
        v-for="(answer, index) in answers"
        :key="index"
      >
        <div class="vx-col w-full addpoll-opt" style="margin-top: -20px">
          <vx-input-group class="mb-base">
            <vs-input
              class="w-full input_field_bottom Addpoll_input_field"
              @focus="createNewInput(index)"
              :placeholder="'Option ' + (index + 1)"
              v-model="answers[index]"
            />
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button color="primary" @click.stop="deleteInput(index)"
                  >Delete</vs-button
                >
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
      <!-- </div> -->
      <div class="bottom-fix-btn">
        <vs-button
          class="bg-primary w-full border-rem h2-class"
          @click="showCreatePoll = false"
          :disabled="enableCreateButton"
          type="filled"
          >CREATE</vs-button
        >
      </div>
      <!-- </div> -->
    </div>

    <div v-show="!showCreatePoll">
      <div class="flex px-4 add_post text-light bg-primary view_member_topbar">
        <feather-icon
          icon="ChevronLeftIcon"
          @click="showCreatePoll = true"
          class="cursor-pointer h2-class"
        ></feather-icon>
        <vs-button class="h2-class pd-10 cust-remove-boxsh">ADD POST</vs-button>
      </div>
      <!-- </div> -->
      <div>
        <div class="panel box_sd_parent">
          <div class="panel-body panel_panel_two">
            <label class="text_colr_p ml-4 h2-class mt-4">CHANNELS</label>
            <ul class>
              <li
                :class="{ checked_color: group.isSelected }"
                class="friend-suggestion flex items-center cursor-pointer mb-2"
                v-for="(group, index) of groupsWithPostingRights"
                :key="index"
                @click.stop="
                  group.isSelected = !group.isSelected;
                  addGroupInPost(group);
                "
              >
                <div class="media-left p-2">
                  <img
                    v-if="group.group_icon != null"
                    :src="magittUrl + group.group_icon"
                    class="imguserwidth-35"
                    alt
                  />
                  <img
                    v-if="group.group_icon == null"
                    :src="magittUrl + 'uploads/defaultImages/default_group.png'"
                    class="imguserwidth-35"
                    alt
                  />
                </div>
                <div class="ml-2 w-full">
                  <p class="user-text-md">{{ group.group_name }}</p>
                </div>
                <!-- radio btn vue-->
                <!--<div class="addpost_sidebar ml-auto mr-6 cursor-pointer">
                    <vs-radio  vs-value="primary"></vs-radio>           
                </div>-->
                <!-- 
              <label class="container">
                <input
                  type="radio"
                  value="true"
                  v-model="group.isSelected"
                  @change="addGroupInPost(group)"
                />
                <span class="checkmark"></span>
                </label>-->

                <!-- <vs-radio v-model="group.isSelected" vs-value="1">
                  <span class="pd-10"></span>
                </vs-radio> -->

                <label class="container container_groups_radio">
                  <input
                    type="checkbox"
                    v-model="group.isSelected"
                    @change="
                      group.isSelected = !group.isSelected;
                      addGroupInPost(group);
                    "
                    checked="checked"
                  />
                  <!-- <span class="checkmark"></span> -->
                </label>

                <div></div>

                <!-- <vs-checkbox
                  class="ml-auto mb-8 cursor-pointer"
                  v-model="group.isSelected"
                ></vs-checkbox>-->
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bottom-fix-btn">
        <vs-button
          class="bg-primary w-full border-rem h2-class"
          @click="CreatePollPost"
          :disabled="enablePostButton"
          type="filled"
          >POST</vs-button
        >
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
const dashboardRepository = RepositoryFactory.get("dashboard");
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import jwt from "jsonwebtoken";
const postsRepository = RepositoryFactory.get("posts");
const notificationRepository = RepositoryFactory.get("notifications");
import successPostMixin from "@/Core/mixins/successPostMixin";
// import func from '../../../../vue-temp/vue-editor-bridge';

export default {
  mixins: [successPostMixin],
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { category, id, img, name, order_status, price } = JSON.parse(
          JSON.stringify(this.data)
        );
        this.dataId = id;
        this.dataCategory = category;
        this.dataImg = img;
        this.dataName = name;
        this.dataOrder_status = order_status;
        this.dataPrice = price;
        this.initValues();
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  data() {
    return {
      isStoryTextPost: 0,
      groupsToPost: [],
      groupUUIDsToPost: [],
      userGroups: [],
      pollquestion: "",
      answers: ["", ""],
      showCreatePoll: true,
      dataId: null,
      dataName: "",
      dataCategory: null,
      dataImg: null,
      dataOrder_status: "pending",
      dataPrice: 0,
      groupsWithPostingRights: [],
      category_choices: [
        { text: "Audio", value: "audio" },
        { text: "Computers", value: "computers" },
        { text: "Fitness", value: "fitness" },
        { text: "Appliance", value: "appliance" },
      ],

      order_status_choices: [
        { text: "Pending", value: "pending" },
        { text: "Canceled", value: "canceled" },
        { text: "Delivered", value: "delivered" },
        { text: "On Hold", value: "on_hold" },
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      memberList: [],
      phoneList: [],
      emailList: [],
      groupNames: [],
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataName &&
        this.dataCategory &&
        this.dataPrice > 0
      );
    },
    magittUrl() {
      return this.baseUrlMagitt;
    },
    enableCreateButton() {
      if (
        this.pollquestion == "" ||
        this.answers[0] == "" ||
        this.answers[1] == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    enablePostButton() {
      return !this.groupsToPost.length;
    },
  },
  methods: {
    async CreatePollPost() {
      this.$vs.loading();
      var self = this;

      let answ = self.answers.filter(function (el) {
        return el !== "";
      });

      var obj = {
        postData: {
          postMasterId: 2,
          userId: self.token.userID,
          isStory: self.isStoryTextPost,
          ownerName: self.token.username,
          type: "Poll",
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        newPostData: {
          Poll: {
            UserId: self.token.userID,
            Question: self.pollquestion,
            Answers: answ.join(),
          },
        },
        groupData: self.groupsToPost,
      };

      const { data } = await postsRepository.addPollPost(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        // self.$parent.$parent.isSidebarActive = false;
        this.generateIndividualPostLink(data);
        this.insertNewPostNotification(data.post_UniqueId, this.memberList);
        self
          .successPost(
            data.post_UniqueId,
            self.pollquestion,
            self.groupsToPost,
            self.groupUUIDsToPost,
            self.groupNames,
            self.newPostLink
          )
          .then(() => {
            this.$emit("closeAddPostSidebar");
          });
        self.emptyGroupData();
      }
    },
    emptyGroupData() {
      this.answers = ["", ""];
      this.pollquestion = "";
      this.groupsToPost = [];
      this.groupUUIDsToPost = [];
      this.groupsWithPostingRights.forEach((element) => {
        element.isSelected = false;
      });
      // this.showPostTypeSection();
    },
    generateIndividualPostLink(data) {
      // let rawString = {
      //   postId: data.post_UniqueId,
      //   community: currentCommunityName.communityName,
      //   communityColor: localStorage.getItem("communityColor"),
      //   communityId: localStorage.getItem("communityID")
      // };
      // rawString = JSON.stringify(rawString);
      // this.newPostLink =
      //   window.document.location.origin + "/posts?pid=" + btoa(rawString);
      let postLink = `${window.document.location.origin}/posts?pid=${data.post_UniqueId}`;
      let payload = {
        full: postLink,
      };
      this.$store.dispatch("shortURL/addShortUrl", payload).then(() => {
        this.newPostLink = `${window.document.location.origin}/posts/${this.$store.state.shortURL.url.short}`;
      });
    },

    addGroupInPost(group) {
      if (group.isSelected) {
        this.groupsToPost.push(group.groupId);
        this.groupUUIDsToPost.push(group.group_UniqueId);
        this.$store.state.groupId = group.groupId;
        this.groupsWithPostingRights[
          this.groupsWithPostingRights.findIndex(
            (x) => x.groupId == group.groupId
          )
        ].isSelected = true;
        // this.getUsersInGroup(group.groupId);
        this.groupNames.push(group.group_name);
      } else {
        this.groupsToPost.splice(
          this.groupsToPost.findIndex((x) => x.groupId == group.groupId),
          1
        );
        this.groupUUIDsToPost.splice(
          this.groupUUIDsToPost.findIndex((x) => x === group.group_UniqueId),
          1
        );
        this.groupNames.splice(
          this.groupNames.findIndex((x) => x == group.group_name),
          1
        );
        this.groupsWithPostingRights[
          this.groupsWithPostingRights.findIndex(
            (x) => x.groupId == group.groupId
          )
        ].isSelected = false;
      }
      // if (this.groupsToPost.length == 0) {
      //   if (group.isSelected) {
      //     this.groupsToPost.push(group.groupId);
      //     console.log(this.groupsToPost);
      //   } else {
      //     this.groupsToPost.splice(
      //       this.groupsToPost.findIndex(x => x.groupId === group.groupId),
      //       1
      //     );
      //     console.log(this.groupsToPost);
      //   }
      // }
    },
    async getGroupsOfUserWithPostingRights() {
      // this.active = true;

      var self = this;
      var obj = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        groupData: {
          userId: self.token.userID,
        },
      };

      const { data } = await postsRepository.getGroupsOfUserWithPostingRights(
        obj
      );

      // self.groupsWithPostingRights = [];
      data.forEach((element) => {
        element.isSelected = false;
        self.groupsWithPostingRights.push(element);
      });
    },
    async getGroupsForDashboard() {
      let obj = {};
      if (
        this.$store.state.memberId != this.token.userID &&
        this.$store.state.memberId != undefined &&
        this.$store.state.memberId != null
      ) {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          userData: {
            userId: this.$store.state.memberId,
          },
        };
      } else {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          userData: {
            userId: this.token.userID,
          },
        };
      }

      const { data } = await dashboardRepository.getGroupsForDashboard(obj);

      this.userGroups = data;
    },
    // async getUsersInGroup(groupId) {
    //   // this.active = true;
    //   var self = this;
    //   console.log(this.token);
    //   let newmemberList = [];
    //   let newphoneList = [];
    //   let newemailList = [];
    //   let obj = {
    //     communityData: {
    //       communityName: currentCommunityName.communityName
    //     },
    //     groupId: groupId,
    //     userId: self.token.userID
    //   };

    //   const { data } = await groupsRepository.getUsersInGroup(obj);

    //   if (data.length > 0) {
    //     // this.memberList = data;
    //     // if (
    //     //   data.findIndex(x => x.usersDetails_UniqueId == this.token.uuid) != -1
    //     // ) {
    //     //   this.memberList.splice(
    //     //     data.findIndex(x => x.usersDetails_UniqueId == this.token.uuid),
    //     //     1
    //     //   );
    //     // }
    //     if (this.memberList.length > 0) {
    //       newmemberList = [...data.map(x => x.usersDetails_UniqueId)];
    //     } else {
    //       newmemberList = [
    //         ...this.memberList,
    //         ...data.map(x => x.usersDetails_UniqueId)
    //       ];
    //     }

    //     if (this.phoneList.length > 0) {
    //       newphoneList = [...data.map(x => x.usersDetails_Mobile)];
    //     } else {
    //       newphoneList = [
    //         ...this.phoneList,
    //         ...data.map(x => x.usersDetails_Mobile)
    //       ];
    //     }

    //     if (this.emailList.length > 0) {
    //       newemailList = [
    //         ...data.map(x => x.usersDetails_Email).filter(x => x != null)
    //       ];
    //     } else {
    //       newemailList = [
    //         ...this.emailList,
    //         ...data.map(x => x.usersDetails_Email).filter(x => x != null)
    //       ];
    //       // this.emailList = data.filter(x != null);
    //     }

    //     this.memberList = [...new Set(newmemberList)];
    //     this.phoneList = [...new Set(newphoneList)];
    //     this.emailList = [...new Set(newemailList)];
    //   }
    // },
    async insertNewPostNotification(groupId, userId) {
      const memberArray = userId;
      let payload = {
        notificationData: {
          initiaterId: this.token.uuid,
          initiaterName: this.token.username,
          initiaterImage: this.token.userImage,
          recepientId: memberArray,
          recepientType: 1,
          notificationMessage: "created a post",
          notificationTitle: "Post",
          notificationEntityType: "Post",
          notificationEntityId: groupId,
          notificationReadStatus: 0,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      await notificationRepository.addNotification(payload);
    },
    initValues() {
      if (this.data.id) return;
      this.dataId = null;
      this.dataName = "";
      this.dataCategory = null;
      this.dataOrder_status = "pending";
      this.dataPrice = 0;
      this.dataImg = null;
    },
    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const obj = {
            id: this.dataId,
            name: this.dataName,
            img: this.dataImg,
            category: this.dataCategory,
            order_status: this.dataOrder_status,
            price: this.dataPrice,
          };

          if (this.dataId !== null && this.dataId >= 0) {
            this.$store.dispatch("dataList/updateItem", obj);
          } else {
            delete obj.id;
            obj.popularity = 0;
            this.$store.dispatch("dataList/addItem", obj);
          }

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    createNewInput(index) {
      if (this.answers.length - 1 == index) {
        this.answers.push("");
      }
    },
    deleteInput(index) {
      if (index > 1 && this.answers.length > 1) {
        this.answers.splice(index, 1);
      }
    },
    // async successPost(text) {
    //   if (this.$acl.check("admin")) {
    //     this.$swal({
    //       icon: "success",
    //       title: "Yay! Your post is now Live.",
    //       html: `
    //       <p>How would you like to promote</p>
    //       <br>
    //         <div class="form-check">
    //             <input class="form-check-input" type="checkbox" value="" id="checkbox1">
    //             <label class="form-check-label" for="checkbox1">
    //                 SMS
    //             </label>
    //             <input class="form-check-input" type="checkbox" value="" id="checkbox2">
    //             <label class="form-check-label" for="checkbox2">
    //                 Email
    //             </label>
    //             <input class="form-check-input" type="checkbox" value="" id="checkbox3">
    //             <label class="form-check-label" for="checkbox3">
    //                 Whatsapp
    //             </label>
    //         </div>
    //       `,
    //       focusConfirm: false,
    //       confirmButtonText: "Promote",
    //       cancelButtonText: "See Post",
    //       showConfirmButton: true,
    //       showCancelButton: true,
    //       allowOutsideClick: false,
    //       preConfirm: () => {
    //         console.log(
    //           "Is checkbox1 checked:" +
    //             document.getElementById("checkbox1").checked
    //         );
    //         console.log(
    //           "Is checkbox2 checked:" +
    //             document.getElementById("checkbox2").checked
    //         );
    //         console.log(
    //           "Is checkbox3 checked:" +
    //             document.getElementById("checkbox3").checked
    //         );
    //       }
    //     }).then(result => {
    //       console.log(result);
    //       if (result.value) {
    //         console.log(
    //           "Is checkbox2 checked:" +
    //             document.getElementById("checkbox2").checked
    //         );
    //         console.log(
    //           "Is checkbox3 checked:" +
    //             document.getElementById("checkbox3").checked
    //         );
    //         console.log(
    //           "Is checkbox1 checked:" +
    //             document.getElementById("checkbox1").checked
    //         );
    //         if (document.getElementById("checkbox1").checked) {
    //           console.log("sms");
    //           this.sendSMS(`${text} ${this.newPostLink}`);
    //         }
    //         if (document.getElementById("checkbox2").checked) {
    //           console.log("email");
    //           this.sendEmail(text, this.newPostLink);
    //         }
    //         if (document.getElementById("checkbox3").checked) {
    //           console.log("whatsapp");
    //         }
    //       } else {
    //         this.viewGroup();
    //       }
    //     });
    //   } else {
    //     this.$swal({
    //       icon: "success",
    //       title: "Yay! Your post is now Live.",
    //       focusConfirm: false,
    //       confirmButtonText: "See Post",
    //       showConfirmButton: true,
    //       showCancelButton: false,
    //       allowOutsideClick: true
    //     }).then(result => {
    //       console.log(result);
    //       if (result.value) {
    //         this.viewGroup();
    //       }
    //     });
    //   }
    // },
    viewGroup() {
      if (this.$route.path == "/community-channels") {
        this.$store.dispatch(
          "storeComponentKey",
          this.$store.state.componentKey++
        );
        this.$router
          .replace({
            name: "page-GroupsPage",
            params: { groupId: this.$store.state.groupId },
            query: { reload: true },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: "page-GroupsPage",
            params: { groupId: this.$store.state.groupId },
          })
          .catch(() => {});
      }
    },
  },
  components: {
    VuePerfectScrollbar,
  },
  mounted() {
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
    this.getGroupsOfUserWithPostingRights();
    // this.getGroupsForDashboard()
  },
};
</script>

<style scoped>
.imgRadius-md {
  width: 50px;
  height: 50px;
}

[class*="imgRadius"] {
  border-radius: 50%;
  object-fit: cover;
}
</style>
