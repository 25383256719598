<template>
  <div>
    <BlogPostSlider @selectedimage="uploadFeatureImage" />

    <div class="grid grid-cols-3 gap-2 mt-4">
      <!-- img post -->
      <div class="cursor-pointer" @click="$refs.imagepostupload.click()">
        <div class="rounded w-full bg-light p-1">
          <div class="h-24">
            <div class="flex self-center justify-center">
              <img :src="filename ? uploadedimage : uploadimage" class="h-16" />
            </div>
            <p class="text-center fsize12 font-medium text-primary">
              {{ imagefilename ? imagefilename : "Image Post" }}
            </p>
          </div>
        </div>
      </div>

      <!-- video post -->
      <div class="cursor-pointer" @click="$refs.vid.click()">
        <div class="rounded w-full bg-light p-1">
          <div class="h-24">
            <div class="flex self-center justify-center">
              <img :src="filename ? uploadedimage : uploadimage" class="h-16" />
            </div>
            <p class="text-center fsize12 font-medium text-primary">
              {{ videofilename ? videofilename : "Video Post" }}
            </p>
          </div>
        </div>
      </div>

      <!-- pdf post -->
      <div class="cursor-pointer" @click="$refs.fileInput.click()">
        <div class="rounded w-full bg-light p-1">
          <div class="h-24">
            <div class="flex self-center justify-center">
              <img :src="filename ? uploadedimage : uploadimage" class="h-16" />
            </div>
            <p class="text-center fsize12 font-medium text-primary">
              {{ filename ? filename : "Upload PDF" }}
            </p>
          </div>
        </div>
      </div>

      <input
        type="file"
        ref="imagepostupload"
        class="hidden"
        accept="image/*"
        @change="uploadPostImage"
      />
      <input
        type="file"
        ref="vid"
        class="hidden"
        accept="video/*"
        @change="uploadPostVideo"
      />
      <input
        type="file"
        ref="fileInput"
        class="hidden"
        accept="application/pdf"
        @change="handleClick"
      />
    </div>
  </div>
</template>

<script>
import BlogPostSlider from "./BlogPostSlider";
export default {
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    file: {
      default: null,
    },
  },
  components: {
    BlogPostSlider,
  },
  data() {
    return {
      uploadimage: require("@/Core/assets/images/home/blogs/pdf.png"),
      uploadedimage: require("@/Core/assets/images/pages/post/PDF_file_icon.svg"),
      filename: null,
      imagefilename: null,
      videofilename: null,

      imagePost: false,
      videoPost: false,
      postTypeSection: true,
      newPostImage: null,
      newPostVideo: null,
    };
  },
  watch: {
    file() {
      if (this.file == null) {
        this.filename = null;
      }
    },
  },
  methods: {
    unsupportedFormatAlert() {
      this.$swal({
        icon: "error",
        title: "Unsupported File Format",
      });
    },
    largeFileSizeAlert() {
      this.$swal({
        icon: "error",
        title: "File Size too large",
      });
    },
    setPostType(
      textStatus,
      imageStatus,
      videoStatus,
      audioStatus,
      blogStatus,
      voiceblastStatus
    ) {
      this.textPost = textStatus;
      this.imagePost = imageStatus;
      this.videoPost = videoStatus;
      this.audioPost = audioStatus;
      this.blogPost = blogStatus;
      this.voiceblastPost = voiceblastStatus;
    },
    showPostTypeSection() {
      this.showChannel = false;
      this.postTypeSection = true;
      this.showTextEditor = false;
      this.textPost = true;
      this.imagePost = false;
      this.videoPost = false;
      this.audioPost = false;
      this.audioPost = false;
      this.blogPost = false;
      this.newPostImage = null;
      this.newPostVideo = null;
      this.newPostVideoFile = null;
      this.newPostAudio = null;
      this.newPostAudioFile = null;
      this.blog = null;
      this.documentFile = null;
    },
    uploadPostImage(ev) {
      const supportedTypes = ["image/jpeg", "image/png"];
      const fileSize = 25 * 1024 * 1024;
      if (
        ev.target.files.length &&
        !supportedTypes.includes(ev.target.files[0].type)
      ) {
        this.unsupportedFormatAlert();
        return;
      } else if (ev.target.files.length && ev.target.files[0].size > fileSize) {
        this.largeFileSizeAlert();
        return;
      } else if (ev.target.files.length > 0) {
        const file = ev.target.files[0];
        if (
          !supportedTypes.includes(ev.target.files[0].type) ||
          ev.target.files[0].size > fileSize
        )
          return;
        this.imagePost = true;
        this.postTypeSection = false;
        this.setPostType(false, true, false, false, false, false);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$emit("imagepost", e.target.result);
          // this.$emit("load", (this.newPostImage = e.target.result));
          this.$emit("load", (this.imagefilename = file.name));
        };
        reader.readAsDataURL(file);
      } else {
        this.showPostTypeSection();
      }
      console.log(this.newPostImage);
    },
    uploadPostVideo(ev) {
      const supportedTypes = [
        "video/mp4",
        "video/x-ms-wmv",
        "video/x-msvideo",
        "video/webm",
        "video/x-matroska",
      ];
      const fileSize = 75 * 1024 * 1024;
      if (
        ev.target.files.length &&
        !supportedTypes.includes(ev.target.files[0].type)
      ) {
        this.unsupportedFormatAlert();
        return;
      } else if (ev.target.files.length && ev.target.files[0].size > fileSize) {
        this.largeFileSizeAlert();
        return;
      } else if (ev.target.files.length > 0) {
        const file = ev.target.files[0];
        if (
          !supportedTypes.includes(ev.target.files[0].type) ||
          ev.target.files[0].size > fileSize
        )
          return;
        this.videoPost = true;
        this.postTypeSection = false;
        this.setPostType(false, false, true, false, false, false);
        this.newPostVideoFile = this.$refs.vid.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$emit("videopost", e.target.result);

          // this.$emit("load", (this.newPostVideo = e.target.result));
          this.$emit("load", (this.videofilename = file.name));
        };
        reader.readAsDataURL(file);
      } else {
        this.showPostTypeSection();
      }
    },
    async addImagePost() {
      this.$vs.loading();
      if (this.newPostImage) {
        this.crop();
      }
      var self = this;
      let obj = {
        postData: {
          postMasterId: 3,
          userId: self.token.userID,
          isStory: self.isStoryTextPost,
          ownerName: self.token.username,
          type: "Image",
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        newPostData: {
          image: self.newPostImage,
          text: self.textarea,
        },
        groupData: self.groupsToPost,
      };

      const { data } = await postsRepository.addPost(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        this.insertNewPostNotification(data.post_UniqueId, this.memberList);
        this.isSidebarActiveLocal = false;
        this.postAddedAlert();
      } else {
        this.$vs.loading.close();
      }
    },

    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0];
      const checkIsPDF = this.isPDF(rawFile);
      const checkIsFileSize = this.isFileSize(rawFile);
      if (checkIsPDF && checkIsFileSize) {
        this.setData(rawFile);
      } else {
        //Handle Wrong File Type
        this.resetData(!checkIsPDF, !checkIsFileSize);
      }
    },
    resetData(isPDFError, isFileSizeError) {
      let title = "";
      isPDFError ? (title += "<br>You can only upload PDF") : "";
      isFileSizeError ? (title += "<br>File Size too large") : "";
      this.$swal({
        icon: "error",
        title: title,
      });
    },
    setData(rawFile) {
      this.$vs.loading();
      if (!rawFile) return;
      this.uploadFile(rawFile);
    },
    uploadFile(file) {
      this.$refs["fileInput"].value = null; // fix can't select the same excel
      this.uploadDocument(file);
    },
    uploadDocument(rawFile) {
      this.filename = rawFile.name;
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          //Logic Here
          this.onSuccess && this.onSuccess(rawFile);
          resolve();
          this.$vs.loading.close();
        };
        reader.readAsDataURL(rawFile);
      });
    },
    isPDF(file) {
      return /\.(pdf)$/.test(file.name);
    },
    isFileSize(file) {
      const fileSize = 10 * 1024 * 1024;
      return file.size < fileSize;
    },
    uploadFeatureImage(image) {
      this.$emit("uploadFeatureImage", image);

      // console.log(image);
    },
  },
};
</script>
