<template>
  <div class="flex items-center likes-com-clss p-1">
    <!-- <feather-icon
    class="mr-2 primary-icon"
    icon="MessageSquareIcon"
    svgClasses="h-5 w-5"
    ></feather-icon>-->
    <span v-if="groupPost.comment_count == null || groupPost.comment_count == 0"
      >No Comments</span
    >
    <span v-else-if="groupPost.comment_count == 1"
      >{{ groupPost.comment_count }} Comment</span
    >
    <span v-else>{{ groupPost.comment_count }} Comments</span>
    <span></span>
  </div>
</template>

<script>
export default {
  props: {
    groupPost: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
